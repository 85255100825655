import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { InitialAlertsState } from "./interfaces";

const initialState: InitialAlertsState = {
  priceAlerts: null,
  engagementAlerts: null,
  priceAlertsLoading: false,
  engagementAlertsLoading: false,
  error: null,
};

export const fetchEngagementAlerts = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("engagement alerts", async (_, thunkAPI) => {
  try {
    const url = `/alerts/coin-engagement-alert-list/`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPriceAlerts = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("price alerts", async (_, thunkAPI) => {
  try {
    const url = `/alerts/coin-price-alert-list/`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEngagementAlerts.pending, (state) => {
        state.engagementAlertsLoading = true;
        state.error = null;
      })
      .addCase(fetchEngagementAlerts.fulfilled, (state, action) => {
        state.engagementAlertsLoading = false;
        state.engagementAlerts = action.payload;
      })
      .addCase(fetchEngagementAlerts.rejected, (state, action) => {
        state.engagementAlertsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPriceAlerts.pending, (state) => {
        state.priceAlertsLoading = true;
        state.error = null;
      })
      .addCase(fetchPriceAlerts.fulfilled, (state, action) => {
        state.priceAlertsLoading = false;
        state.priceAlerts = action.payload;
      })
      .addCase(fetchPriceAlerts.rejected, (state, action) => {
        state.priceAlertsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default alertsSlice.reducer;
