import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography, useTheme } from "@mui/material";
import SubPagesLayout from "../../layout/subPagesLayout";
import CardTitle from "../../components/common/title/CardTitle";
import DefaultModal from "../../components/common/modal";
import { deactivateAccount } from "../../api/auth";
import { removeAuthTokens } from "../../services/auth";
import styles from "./accountManagement.module.scss";

const AccountManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const deactivateAccountHandler = async () => {
    setLoading(true);
    const result = await deactivateAccount();

    if (result.status === 200) {
      toast.success("Account successfully deactivated");
      removeAuthTokens();

      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  };

  return (
    <SubPagesLayout title="Coinfident | Account Management">
      <Box mt={3}>
        <CardTitle isCenter>Account Management</CardTitle>
        <Box className={styles.accountDangerSection}>
          <Typography variant="h6" color={theme.palette.text.primary}>
            Deactivate Account
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary}>
            By deactivating your account, you're choosing to temporarily disable
            your Coinfident account. <br />
            Your account will not be deleted, and your data will be securely
            stored. <br />
            We will send you an email to reactivate your account whenever you
            decide to return.
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(!open)}
            sx={{ color: theme.palette.common.white }}
          >
            Deactivate Account
          </Button>
          {open && (
            <DefaultModal
              open={open}
              setOpen={setOpen}
              title="Deactivate Account"
              content={
                <Box id="modal-description" sx={{ mt: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 100 }}
                    color={theme.palette.text.primary}
                  >
                    Are you sure you want to deactivate your account?
                  </Typography>
                  <Box className="modalActions">
                    <LoadingButton
                      variant="contained"
                      color="error"
                      size="small"
                      loading={loading}
                      loadingPosition="center"
                      onClick={deactivateAccountHandler}
                      sx={{ color: theme.palette.common.white }}
                    >
                      Deactivate Account
                    </LoadingButton>

                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        //@ts-ignore
                        background: theme.palette.grey.light,
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              }
            />
          )}
        </Box>
      </Box>
    </SubPagesLayout>
  );
};

export default AccountManagement;
