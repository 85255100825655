import axios from "axios";
import http from "../services/http";
import { CreateWalletDataType, WalletDataType } from "./interfaces";
const BLOCKDAEMON = process.env.REACT_APP_BLOCKDAEMON_API_KEY;
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchWalletBalance = (wallet_data: WalletDataType) => {
  const url = `/v1/${wallet_data.protocol}/${wallet_data.network}/account/${wallet_data.wallet_address}`;

  const config = {
    baseURL: "https://svc.blockdaemon.com/universal",
    headers: {
      accept: "application/json",
      "X-API-Key": BLOCKDAEMON,
    },
  };

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        errorMessage: err.response.data.detail,
      };
    });
};

export const fetchWalletTransactions = (wallet_data: WalletDataType) => {
  const url = `/v1/${wallet_data.protocol}/${wallet_data.network}/account/${wallet_data.wallet_address}/txs?order=desc`;

  const config = {
    baseURL: "https://svc.blockdaemon.com/universal",
    headers: {
      accept: "application/json",
      "X-API-Key": BLOCKDAEMON,
    },
  };

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const addNewWalletHandler = async (
  wallet_data: CreateWalletDataType
) => {
  const url = `${BASE_URL}/wallets/wallet-create/`;
  const request = await http
    .post(url, wallet_data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const removeWalletHandler = async (id: number) => {
  const url = `${BASE_URL}/wallets/wallet/${id}`;
  const request = await http
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const walletStockUpdateHandler = async (
  id: number,
  assets: [],
  dollar_balance: number
) => {
  const wallet_stocks = {
    wallet: id,
    dollar_balance,
    coin_amounts: Object.fromEntries(
      assets.map((asset: any) => [asset.coin_id.toString(), asset.balance])
    ),
  };

  const url = `${BASE_URL}/wallets/wallet-stock-update/`;
  const request = await http
    .post(url, wallet_stocks)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};
