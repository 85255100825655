import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { CryptoNewsResponseState } from "./interfaces";

export const fetchCryptoTweetCount = createAsyncThunk<
  any,
  number,
  { rejectValue: any }
>("crypto News Count", async (coinId, thunkAPI) => {
  try {
    const url = `/crypto/get-tweet-count/?coin_id=${coinId}`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptoNewsResponseState = {
  cryptoNewsCount: null,
  cryptoNewsCountLoading: true,
  error: null,
};

export const cryptoNewsSlice = createSlice({
  name: "crypto News",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoTweetCount.pending, (state) => {
        state.cryptoNewsCountLoading = true;
        state.error = null;
      })
      .addCase(fetchCryptoTweetCount.fulfilled, (state, action) => {
        state.cryptoNewsCountLoading = false;
        state.cryptoNewsCount = action.payload;
      })
      .addCase(fetchCryptoTweetCount.rejected, (state, action) => {
        state.cryptoNewsCountLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default cryptoNewsSlice.reducer;
