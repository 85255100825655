import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FcGoogle } from "react-icons/fc";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import AuthTitle from "../../../components/common/title/AuthTitle";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import AuthImageDark from "../../../assets/images/auth-image-dark.png";
import AuthImageLight from "../../../assets/images/auth-image-light.png";
import CoinfidentImage from "../../../assets/images/icons/coinfident.png";
import { loginWithEmail, loginWithGoogle } from "../../../api/auth";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import { setUserData } from "../../../features/auth/accountSlice";
import { LoginState } from "../interfaces";
import styles from "../auth.module.scss";
const { Helmet } = require("react-helmet");

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const hasToken = localStorage.getItem("access-token");
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<LoginState>({
    email: "",
    password: "",
  });

  const getSubmitHandler = () => async (values: LoginState, formik: any) => {
    setLoading(true);
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    const response = await loginWithEmail(values);
    if (response) {
      setLoading(false);
      if (response.status === 200) {
        if (response?.data?.access) {
          dispatch(setUserData(response.data));
          navigate(redirect ? `/${redirect}` : "/");
        }
      } else {
        toast.error("Invalid login data");
        setLoading(false);
        formik.resetForm();
      }
    }
  };

  useEffect(() => {
    if (hasToken) {
      navigate("/");
    }
  }, []);

  // @ts-ignore
  const googleResponseMessage = async (res) => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    const response = await loginWithGoogle(res);

    if (response.status === 200) {
      dispatch(setUserData(response.data));
      navigate(redirect ? `/${redirect}` : "/");
    } else if (response.status === 400) {
      const error_messsage = response.response.data?.non_field_errors?.at(0);
      if (
        error_messsage ===
        "User is already registered with this e-mail address."
      )
        toast.error(
          "You are already registered with this e-mail address. Please use email and password to authenticate."
        );
    } else {
      toast.error("Invalid login data");
    }
  };

  //@ts-ignore
  const googleErrorMessage = (error) => {
    toast.error("Invalid login data");
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: googleResponseMessage,
    onError: googleErrorMessage,
  });

  return (
    <Box className={styles.loginWrapper}>
      <Helmet>
        <title>Coinfident | Login</title>
      </Helmet>
      <Logo isAuth />
      <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item md={6} xs={12}>
          <Fade triggerOnce>
            <AuthTitle margin="45px 0 80px">Login</AuthTitle>
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              validationSchema={useCreateSchema(["email", "password"])}
              enableReinitialize={true}
              onSubmit={getSubmitHandler()}
            >
              {(formik) => (
                <Form id="login_form">
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={7}>
                      <Input
                        name="email"
                        formik={formik}
                        label="Email"
                        hasInlineLabel={true}
                        hasInnerLabel={false}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Input
                        name="password"
                        formik={formik}
                        type="password"
                        label="Password"
                        hasInlineLabel={true}
                        hasInnerLabel={false}
                      />
                      <Box sx={{ margin: ".2rem 0 1.5rem" }}>
                        <Link
                          to="/reset-password"
                          style={{
                            fontSize: ".8rem",
                            fontWeight: 100,
                            //@ts-ignore
                            color: theme.palette.common.greenText,
                          }}
                        >
                          Forget Password?
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <PrimaryButton
                        text="Login"
                        disabled={!formik.isValid}
                        loading={loading}
                        isFullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          className={styles.line}
                          sx={{ backgroundColor: theme.palette.text.primary }}
                        />
                        <Typography mx={1} color={theme.palette.text.primary}>
                          OR
                        </Typography>
                        <Box
                          className={styles.line}
                          sx={{ backgroundColor: theme.palette.text.primary }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <PrimaryButton
                        text="Login With Google"
                        disabled={false}
                        loading={false}
                        isFullWidth
                        startIcon={<FcGoogle />}
                        clickHandler={() => handleGoogleLogin()}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Box className={styles.linkWrapper}>
                        <Typography
                          variant="body2"
                          align="left"
                          sx={{ color: theme.palette.text.primary }}
                        >
                          Don’t have an account yet?
                        </Typography>
                        <Link
                          to="/signup"
                          style={{ color: theme.palette.primary.main }}
                        >
                          Sign Up
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Fade>
        </Grid>
        {!downLg && (
          <Grid item md={6} xs={12} sx={{ height: "100%" }}>
            <Fade triggerOnce>
              <Box
                className={styles.authImage}
                sx={{
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? `inset 10px 3px 45px rgba(255, 255, 255, 0.3)`
                      : `inset 10px 3px 45px rgba(0, 0, 0, 0.3)`,
                  background: `url(${
                    theme.palette.mode === "dark"
                      ? AuthImageDark
                      : AuthImageLight
                  })`,
                }}
              >
                <img src={CoinfidentImage} className={styles.auth_icon} />
              </Box>
            </Fade>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Login;
