import { useState } from "react";
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { LuInfo } from "react-icons/lu";
import { CardTitleProps } from "../interfaces";

const CardTitle: React.FC<CardTitleProps> = ({
  children,
  isCenter,
  small,
  hasInfo,
  infoTitle,
  hasMargin,
  hasTooltip,
  tooltipInfo,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      enterTouchDelay={0}
      {...props}
      classes={{ popper: className }}
      placement="top"
      arrow
      onClick={(e) => e.stopPropagation()}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color:
        //@ts-ignore
        theme.palette.common.backdrop,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: hasInfo && isCenter ? "center" : hasInfo ? "space-between" : "center",
        alignItems: "center",
        marginBottom: hasMargin ? ".9rem" : 0,
      }}
    >
      <Typography
        component="h1"
        sx={{
          //@ts-ignore
          color: theme.palette.common.whiteToGreen,
          fontWeight: 300,
          fontSize: small ? "15px" : "17px",
          lineHeight: 1,
          marginRight: hasInfo ? ".2rem" : 0,
          display: "flex",
          alignItems: "center",
        }}
        align={isCenter ? "center" : "left"}
      >
        {children}
      </Typography>
      {hasInfo && infoTitle ? (
        <Box sx={{ position: "static", zIndex: 10 }}>
          <IconButton aria-label="Popup" size="small" onClick={() => setOpen(!open)}>
            <LuInfo
              size={20}
              color={
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
              }
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      {hasTooltip && tooltipInfo ? (
        <TableTooltip title={tooltipInfo}>
          <IconButton size="small">
            <LuInfo
              size={15}
              color={
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
              }
            />
          </IconButton>
        </TableTooltip>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CardTitle;
