import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { BiChevronRight } from "react-icons/bi";
import { LuInfo } from "react-icons/lu";
import CardHeaderButton from "../buttons/CardHeaderButton";
import CryptoList from "../../crypto/cryptoList";
import { RootState } from "../../../features/store";
import { AnalyticsTitleProps } from "../interfaces";

import CoinmarketcapLogo from "../../../assets/images/icons/coinmarketcap.png";

const AnalyticsTitle: React.FC<AnalyticsTitleProps> = ({
  isCoinMarketCap,
  title,
  hasTradeBtn,
  hasEngagementAlert,
  hasPriceAlert,
  hasCryptoList,
  hasViewMoreBtn,
  viewMoreLink,
  coinsSelectorHandler,
  hasInfo,
  tooltipInfo,
  icon,
  isLarge = false,
  info,
  minHeight,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const cryptoState: any = useSelector((state: RootState) => state.crypto);

  const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      enterTouchDelay={0}
      {...props}
      classes={{ popper: className }}
      placement="top"
      arrow
      onClick={(e) => e.stopPropagation()}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color:
        //@ts-ignore
        theme.palette.common.backdrop,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  const coinNewsTabs = [
    {
      title: "Influencers",
      name: "influencers",
    },
    {
      title: "News Outlets",
      name: "news",
    },
    {
      title: "Curated",
      name: "curated",
    },
  ];

  const topNewsTabs = [
    {
      title: "Social",
      name: "social",
    },
    {
      title: "News Outlets",
      name: "news",
    },
    {
      title: "Curated",
      name: "curated",
    },
  ];

  const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color:
        theme.palette.mode === "light"
          ? theme.palette.common.white
          : theme.palette.common.black,
      background:
        theme.palette.mode === "light"
          ? theme.palette.common.black
          : theme.palette.common.white,
    },
  }));

  return (
    <Box
      p={1}
      sx={{
        background: (theme: any) => theme.palette.common.darkPrimary,
        width: "100%",
        minHeight: minHeight ? minHeight : "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {isCoinMarketCap && (
        <TooltipComponent
          title="Powered By Coinmarketcap"
          style={{ position: "absolute", right: "7px", top: "16px" }}
        >
          <Box
            sx={{
              background: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              padding: ".1rem",
              boxSizing: "border-box",
              objectFit: "contain",
              width: "28px",
              height: "28px",
            }}
          >
            <img src={CoinmarketcapLogo} style={{ width: "100%" }} />
          </Box>
        </TooltipComponent>
      )}
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box>
          <Typography
            component="h1"
            variant="caption"
            sx={{
              flex: 1,
              fontWeight: 500,
              fontSize: isLarge ? "15px" : "14px",
            }}
          >
            {title}
          </Typography>
          {info && (
            <Typography
              component="h2"
              variant="caption"
              sx={{
                fontWeight: 100,
                fontSize: isLarge ? "12.5px" : "11px",
              }}
            >
              {info}
            </Typography>
          )}
        </Box>

        {hasInfo && tooltipInfo ? (
          <TableTooltip title={tooltipInfo}>
            <IconButton size="small">
              <LuInfo
                size={13}
                color={
                  theme.palette.mode === "light"
                    ? theme.palette.primary.main
                    : theme.palette.grey[500]
                }
              />
            </IconButton>
          </TableTooltip>
        ) : (
          ""
        )}
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center">
        {hasViewMoreBtn && (
          <Button
            size="small"
            variant="text"
            sx={{
              color: theme.palette.common.white,
              fontWeight: 100,
              fontSize: "11px",
            }}
            onClick={() => {
              viewMoreLink && navigate(viewMoreLink);
            }}
          >
            View More
            <BiChevronRight size={15} />
          </Button>
        )}

        {hasEngagementAlert || hasPriceAlert ? (
          <>
            {hasPriceAlert && (
              <CardHeaderButton
                title="Set Alert"
                name="Set Alert"
                type="priceAlert"
              />
            )}
            {hasEngagementAlert && (
              <CardHeaderButton
                title="Set Alert"
                name="Set Alert"
                type="engagementAlert"
              />
            )}
          </>
        ) : null}

        {hasCryptoList && (
          <CryptoList coinsSelectorHandler={coinsSelectorHandler} />
        )}
      </Stack>
      {/* {title === "News" && (
        <>
          {coinNewsTabs.map((tabItem: { title: string; name: string }) => (
            <CardHeaderButton
              tabTitle="News"
              title={tabItem.title}
              name={tabItem.name}
              key={tabItem.name}
              isActive={
                tabItem.name === cryptoState.analyticsSelectedNewsTab
                  ? true
                  : false
              }
            />
          ))}
        </>
      )} */}
      {icon && icon}
    </Box>
  );
};

export default AnalyticsTitle;
