import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { AssetsListDonutChartProps } from "./interfaces";
import styles from "./charts.module.scss";

const AssetsListDonutChart: React.FC<AssetsListDonutChartProps> = ({
  data,
  name,
}) => {
  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });
  const theme = useTheme();

  function convertDataToTableData() {
    const tableData = [];

    for (const key in data) {
      if (key === "dollar_balance") {
        tableData.push({ x: "Dollar", y: data[key] });
      } else {
        //@ts-ignore
        tableData.push({ x: key, y: data[key]?.total });
      }
    }

    return tableData;
  }

  const table_data = convertDataToTableData();

  useEffect(() => {
    setState({
      series: table_data.map((dataItem: any) => dataItem.y),
      options: {
        chart: {
          type: "donut",
          fontFamily: "Poppins",
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        labels: table_data.map((dataItem: any) => dataItem.x),
        dataLabels: {
          style: {
            fontSize: "11px",
            fontWeight: 100,
          },
        },
        tooltip: {
          //@ts-ignore
          custom: function ({ seriesIndex, w }) {
            return `<div class='${
              styles.linechart_tooltip
            }' style="background: ${
              //@ts-ignore
              theme.palette.common.solidCard
            }">
              <h6 style="color: ${theme.palette.common.white} "> ${name} </h6>
              <div>
                <h4 style="color: ${theme.palette.common.white} ">${
              w.globals.labels[seriesIndex]
            }:</h4>
                <h5 style="color: ${
                  theme.palette.common.white
                } ">${w.globals.seriesPercent[seriesIndex][0].toFixed(0)}%</h5>
              </div>
              </div>`;
          },
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: 100,
          markers: {
            width: 6,
            height: 6,
          },
          labels: {
            useSeriesColors: true,
          },
        },
        stroke: {
          width: 0,
        },
      },
    });
  }, [data]);

  return (
    <Box id="assetsChart" sx={{ display: "flex", justifyContent: "center" }}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        height="300px"
        width="300px"
      />
    </Box>
  );
};

export default AssetsListDonutChart;
