import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from "@mui/material";
import { FaCoins } from "react-icons/fa";
import { PiHouseFill } from "react-icons/pi";
import { IoIosChatboxes } from "react-icons/io";
import { HiMiniUserGroup } from "react-icons/hi2";
import styles from "./responsiveMenu.module.scss";

export default function ResponsiveMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [value, setValue] = useState(
    location.pathname === "/" || location.pathname === "/analytics"
      ? 0
      : location.pathname === "/coins"
      ? 1
      : location.pathname === "/influencers"
      ? 2
      : location.pathname === "/agents"
      ? 3
      : 4
  );

  return (
    <Box
      className={`${styles.footerWrapper} glass-dark-background`}
      //@ts-ignore
      sx={{ background: theme.palette.common.footer }}
    >
      <BottomNavigation
        showLabels={false}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        className={styles.bottomNavigation}
        sx={{
          "& .Mui-selected": {
            background: (theme: any) => theme.palette.common.selectedFooterItem,
            color: (theme: any) => theme.palette.common.whiteToGreen,
          },
          "& .MuiBottomNavigationAction-label": {
            margin: "0 0 3px",
            transform: "translateY(7px)",
            transition: "all .3s",
            fontSize: ".8rem",
            position: "relative",
            background: "transparent",
          },
          "& .Mui-selected .MuiBottomNavigationAction-label, & .Mui-selected svg":
            {
              color: (theme: any) => theme.palette.primary.main,
            },
        }}
      >
        <BottomNavigationAction
          icon={<PiHouseFill size={28} />}
          showLabel
          label="SPS"
          onClick={() => navigate("/")}
          className={styles.bottomNavigationAction}
          sx={{ color: theme.palette.text.primary }}
        />
        <BottomNavigationAction
          icon={<FaCoins size={28} />}
          showLabel
          label="Coins"
          onClick={() => navigate("/coins")}
          className={styles.bottomNavigationAction}
          sx={{ color: theme.palette.text.primary }}
        />
        <BottomNavigationAction
          icon={<HiMiniUserGroup size={28} />}
          showLabel
          label="Influencers"
          onClick={() => navigate("/influencers")}
          className={styles.bottomNavigationAction}
          sx={{ color: theme.palette.text.primary }}
        />
        <BottomNavigationAction
          icon={<IoIosChatboxes size={28} />}
          showLabel
          label="Agents"
          onClick={() => navigate("/agents")}
          className={styles.bottomNavigationAction}
          sx={{ color: theme.palette.text.primary }}
        />
      </BottomNavigation>
    </Box>
  );
}
