import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import chroma from "chroma-js";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import BubbleChart from "../../components/charts/BubbleChart";
import AnalyticsTitle from "../../components/common/title/AnalyticsTitle";
import Graph from "../../components/graph/RTGraph";
import { RootState } from "../../features/store";
import {
  fetchInfluencersCoinGraph,
  fetchInfluencersSpectrum,
} from "../../features/influencers/influencersSlice";
import { getUserProfile } from "../../utils/userPhoto";
import useAuth from "../../utils/useAuth";
import graphBanner from "../../assets/images/graph.png";
import Loading from "../../components/common/loading";
import InfluencersPerformanceTable from "../../components/influencers/influencersPerformanceTable";

const InfluencersVisual = () => {
  const theme = useTheme();
  const isAuthValid = useAuth();
  const dispatch = useDispatch();
  const [isShowGraph, setShowGraph] = useState<boolean>(false);

  const startColor = "#fa637a";
  const endColor = "#2fdb7d";
  const steps = 10;

  const colorsArr = chroma
    .scale([startColor, endColor])
    .mode("lab")
    .colors(steps);

  const influencersSlice = useSelector((state: RootState) => state.influencers);
  const influencersList: any = useSelector(
    (state: RootState) => state.influencers
  );

  const scatterDataSchema = useMemo(() => {
    const schema = influencersSlice.influencersSpectrum?.map((item: any) => ({
      name: item.screen_name,
      data: [
        [
          Math.log(item.followers_count),
          item.ave_sig,
          { ...item, image: getUserProfile(item.screen_name) },
          item.followers_count,
        ],
      ],
      color: colorsArr[Math.floor(Number(item.ave_sig) * 10)],
    }));

    return { schema };
  }, [influencersSlice.influencersSpectrum]);

  useEffect(() => {
    if (isAuthValid) {
      if (isEmpty(influencersList.influencersSpectrum)) {
        //@ts-ignore
        dispatch(fetchInfluencersSpectrum());
      }

      if (isEmpty(influencersList.influencersCoinGraph)) {
        //@ts-ignore
        dispatch(fetchInfluencersCoinGraph());
      }
    }
  }, [isAuthValid]);

  return (
    <DashboardLayout title="Coinfident | Influencers Visual">
      <Container maxWidth="xl" sx={{ padding: 0 }}>
        <Typography
          color={theme.palette.text.primary}
          align="center"
          mt={5}
          variant="h5"
          component="h2"
        >
          Influencers
        </Typography>
        <Grid container spacing={2} mt={4}>
          <Grid item xs={12} md={6}>
            <Box
              className="analytics-card"
              sx={{ background: theme.palette.primary.light }}
            >
              <AnalyticsTitle
                title="Influencers Optimism"
                hasInfo
                isLarge
                tooltipInfo="This plot visualizes the relationship between influencers' buy/not-buy signals (X-Axis) and their number of followers (Y-Axis), with each point representing an individual influencer. It offers insights into the impact of these signals on the crypto market, based on the size of their community on X."
              />
              {influencersSlice.influencersSpectrumLoading ? (
                <Box
                  sx={{
                    height: "600px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loading />
                </Box>
              ) : (
                <BubbleChart
                  data={scatterDataSchema.schema.slice(0, 49)}
                  height="600px"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className="analytics-card"
              sx={{ background: theme.palette.primary.light }}
            >
              <AnalyticsTitle
                title="Influencers Coins Mention"
                hasInfo
                isLarge
                tooltipInfo="This graph shows which coins are (frequently) mentioned together in influencers' tweets. In this network graph, nodes represent coins, and edges indicate their co-mentions. The more frequent the co-mentions, the larger the nodes."
              />
              {isShowGraph ? (
                <Graph data={influencersSlice.influencersCoinGraph} />
              ) : (
                <Box
                  height={616}
                  position="relative"
                  sx={{
                    backgroundImage: `url('${graphBanner}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                >
                  <Button
                    onClick={() => setShowGraph(true)}
                    variant="contained"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50% , -50%)",
                    }}
                  >
                    Start
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="analytics-card"
              sx={{ background: theme.palette.primary.light }}
            >
              <AnalyticsTitle
                title="Influencers Performance"
                isLarge
                hasCryptoList
              />
              <InfluencersPerformanceTable data={[]} pinnedList={[]} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default InfluencersVisual;
