import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FaListUl, FaTh, FaSort } from "react-icons/fa";
import { InfluencersTabSelectorProps } from "../interfaces";

const InfluencersTabSelector: React.FC<InfluencersTabSelectorProps> = ({
  type,
  setType,
}) => {
  const handleChangeType = (
    e: React.MouseEvent<HTMLElement>,
    type: "tile" | "table" | "sort"
  ): void => setType(type);

  return (
    <ToggleButtonGroup value={type} exclusive onChange={handleChangeType}>
      <ToggleButton value="sort">
        <FaSort size={16} />
      </ToggleButton>
      <ToggleButton value="tile">
        <FaTh size={16} />
      </ToggleButton>
      <ToggleButton value="table">
        <FaListUl size={16} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default InfluencersTabSelector;
