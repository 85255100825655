import { Box, Typography } from "@mui/material";
import TransactionCard from "../../transactions";
import { TransactionType } from "../interfaces";

const Transactions = () => {
  const transactions: TransactionType[] = [
    // {
    //   id: 1,
    //   title: "App Subscription",
    //   date: "2023/11/28",
    //   time: "12:39",
    //   price: 10000,
    //   details: "Test",
    // },
  ];

  return (
    <>
      <Box mt={3}>
        {transactions.length > 0 ? (
          transactions.map((transaction: TransactionType) => (
            <TransactionCard data={transaction} key={transaction.id} />
          ))
        ) : (
          <Typography variant="h6" align="center" mt={5}>
            No transaction found
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Transactions;
