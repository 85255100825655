import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { InfluencersTimeFrameProps } from "../interfaces";

const InfluencersTimeFrame: React.FC<InfluencersTimeFrameProps> = ({
  timePeriod,
  setTimePeriod,
}) => {
  const handleChangeType = (
    e: React.MouseEvent<HTMLElement>,
    period: 1 | 3 | 7 | 3
  ): void => setTimePeriod(period);

  return (
    <ToggleButtonGroup value={timePeriod} exclusive onChange={handleChangeType}>
      <ToggleButton size="small" value={1}>
        24H
      </ToggleButton>
      <ToggleButton size="small" value={3}>
        3D
      </ToggleButton>
      <ToggleButton size="small" value={7}>
        1W
      </ToggleButton>
      <ToggleButton size="small" value={30}>
        1M
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default InfluencersTimeFrame;
