import { useRef } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { Drawer, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import eth_icon from "../../../assets/images/icons/eth_icon.svg";
import Stars from "../../common/svg/stars";
import { RootState } from "../../../features/store";
import styles from "./assetDrawer.module.scss";

const AssetDrawer = () => {
  const theme = useTheme();
  const drawerRef = useRef<HTMLDivElement>(null);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const open = useSelector((state: RootState) => state.assets.openDrawer);

  return (
    <Drawer
      ref={drawerRef}
      className={styles.drawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: 250,
          position: "absolute",
          left: 0,
          bottom: 0,
          padding: "2rem 1rem",
          borderRadius: "0 8px 0 0",
          border: "none",
          overflow: "hidden",
          background: (theme: any) =>
            downLg
              ? theme.palette.common.solidCard
              : theme.palette.mode === "dark"
              ? theme.palette.grey.main
              : theme.palette.primary.light,
        },
        ...(!open && { transform: "translateX(-240px)", visibility: "hidden" }),
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Fade className={styles.icon}>
        <img
          className={styles.eth_icon}
          src={eth_icon}
          alt="ETH-icon"
          width={70}
        />
      </Fade>
      <Fade className={styles.icon}>
        <Stars width={160} height={160} />
      </Fade>
      <Typography
        component="h4"
        variant="body1"
        mb={3}
        style={{
          color: theme.palette.text.primary,
          borderColor: theme.palette.grey[500],
        }}
      >
        Assets
      </Typography>
      {/* <Button
        variant="outlined"
        fullWidth
        sx={{ justifyContent: "flex-start" }}
      >
        Portfolio
      </Button>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          justifyContent: "flex-start",
          margin: ".8rem 0",
        }}
      >
        Risk
      </Button> */}
    </Drawer>
  );
};

export default AssetDrawer;
