import axios from "axios";

export const fetchCoinOHLCData = (crypto: string) => {
  const options = { method: "GET", headers: { accept: "application/json" } };
  const url = `https://api.coingecko.com/api/v3/coins/${crypto}/ohlc?vs_currency=usd&days=30`;
  return axios
    .get(url, options)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
