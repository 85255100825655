import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import AssetsListDonutChart from "../../../charts/AssetsListDonutChart";
import { AssetsListChartProps } from "../../interfaces";
import AssetsAnalysisSkeleton from "../../skeleton/AssetsAnalysisSkeleton";

const AssetsListChart: React.FC<AssetsListChartProps> = ({
  assetsManagementState,
}) => {
  const theme = useTheme();

  return (
    <Box
      mt={2}
      sx={{
        borderRadius: "8px",
        p: 2,
        background: (theme: any) =>
          theme.palette.mode === "dark"
            ? theme.palette.common.darkPrimary
            : theme.palette.primary.light,
      }}
    >
      {assetsManagementState.walletsLoading ? (
        <AssetsAnalysisSkeleton />
      ) : (
        <Box>
          <Typography
            align="center"
            variant="body1"
            mt={1}
            mb={3}
            color={theme.palette.text.primary}
          >
            Assets Analysis
          </Typography>
          {assetsManagementState.networthStatistics && (
            <AssetsListDonutChart
              name="Asset Percentage"
              data={
                assetsManagementState.networthStatistics.networth_statistics
              }
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AssetsListChart;
