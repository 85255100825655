import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkAuth } from "../services/auth";
import {
  setIsAuthLoading,
  setIsAuthenticated,
} from "../features/auth/accountSlice";

const useAuth = () => {
  const dispatch = useDispatch();
  const [isAuthValid, setIsAuthValid] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        checkAuth().then((response) => {
          dispatch(setIsAuthLoading(false));
          setIsAuthValid(true);
          if (response?.status === 200) {
            dispatch(setIsAuthenticated(true));
          } else {
            dispatch(setIsAuthenticated(false));
          }
        });
      } catch (error) {
        setIsAuthValid(false);
      }
    };

    fetchData();
  }, []);

  return isAuthValid;
};

export default useAuth;
