import { Box, Typography, useTheme } from "@mui/material";
import { TfiLock } from "react-icons/tfi";
import styles from "./comingSoon.module.scss";

const ComingSoon = () => {
  const theme = useTheme();

  return (
    <Box
      className={styles.comingSoon}
      sx={{
        background:
          //@ts-ignore
          theme.palette.common.solidCard,
      }}
    >
      <TfiLock
        size={45}
        style={{
          color: theme.palette.primary.main,
        }}
      />
      <Typography
        variant="h4"
        sx={{
          color: theme.palette.primary.main,
        }}
      >
        Coming soon
      </Typography>
    </Box>
  );
};

export default ComingSoon;
