import { Box, Typography, useTheme } from "@mui/material";
import { TfiLock } from "react-icons/tfi";

const InlineComingSoon = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TfiLock
        size={40}
        style={{
          color: theme.palette.primary.main,
          marginBottom: ".5rem",
        }}
      />
      <Typography color={theme.palette.primary.main}>Coming Soon</Typography>
    </Box>
  );
};

export default InlineComingSoon;
