import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Container,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import PriceAlert from "../../components/common/alertModals/priceAlert";
import EngagementAlert from "../../components/common/alertModals/engagementAlert";
import Loading from "../../components/common/loading";
import LoginAlertModal from "../../components/common/loginAlertModal";
import { RootState } from "../../features/store";
import {
  fetchEngagementAlerts,
  fetchPriceAlerts,
} from "../../features/alerts/alertsSlice";
import {
  removeEngagementAlertHandler,
  removePriceAlertHandler,
} from "../../api/alerts";
import useAuth from "../../utils/useAuth";

const tableHeader = {
  price: ["Asset", "Target price", "Created date", "Management"],
  engagement: ["Asset", "Target engagement", "Created date", "Management"],
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: (theme: any) => theme.palette.common.solidCard,
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const Alerts = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isAuthValid = useAuth();
  const account: any = useSelector((state: RootState) => state.account);
  const alertsState: any = useSelector((state: RootState) => state.alerts);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [removeItem, setRemoveItem] = useState({
    id: 0,
    type: "",
  });

  const [loading, setLoading] = useState(false);
  const [openLoginAlertModal, setLoginAlertModal] = useState(false);
  const [openRemoveAlertModal, setOpenRemovePriceAlertModal] = useState(false);
  const [openPriceAlertModal, setOpenPriceAlertModal] = useState(false);
  const [openEngagementAlertModal, setOpenEngagementAlertModal] =
    useState(false);

  const removeAlertFunction = () => {
    setLoading(false);
    toast.success("Alert successfully removed");
    setRemoveItem({
      id: 0,
      type: "",
    });
    setOpenPriceAlertModal(false);
    setOpenEngagementAlertModal(false);
  };

  const removeAlert = () => {
    setLoading(true);

    if (removeItem) {
      if (removeItem.type == "price") {
        removePriceAlertHandler(removeItem.id).then((res) => {
          removeAlertFunction();
          //@ts-ignore
          dispatch(fetchPriceAlerts());
        });
      } else if (removeItem.type == "engagement") {
        removeEngagementAlertHandler(removeItem.id).then((res) => {
          removeAlertFunction();
          //@ts-ignore
          dispatch(fetchEngagementAlerts());
        });
      }
    }
  };

  const removeAlertModal = () => {
    return (
      <Modal
        open={openRemoveAlertModal}
        onClose={setOpenRemovePriceAlertModal}
        aria-labelledby="remove-modal-title"
        aria-describedby="remove-modal-description"
      >
        <Box sx={style}>
          <Typography id="remove-modal-title" variant="h6" component="h2">
            Remove alert
          </Typography>
          <Typography id="remove-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to remove this alert?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setOpenRemovePriceAlertModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ ml: 1 }}
              size="small"
              disabled={loading}
              onClick={removeAlert}
            >
              {loading ? "Removing..." : "Remove"}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    if (!account.loading) {
      if (!account.isAuthenticated) {
        setLoginAlertModal(true);
      }
    }
  }, [account.loading]);

  return (
    <DashboardLayout title="Coinfident | Alerts">
      <Container maxWidth="xl">
        <Box mb={25}>
          <Typography
            variant="h5"
            align="center"
            mb={4}
            color={theme.palette.text.primary}
          >
            Alerts
          </Typography>
          <Typography
            variant="body1"
            align="left"
            mb={1}
            pb={1}
            color={theme.palette.text.primary}
            sx={{ borderBottom: `1px solid ${theme.palette.grey[700]}` }}
          >
            Prices Alerts:
          </Typography>
          {alertsState?.priceAlertsLoading ? (
            <Loading />
          ) : (
            alertsState?.priceAlerts?.count > 0 && (
              <Box>
                <Box
                  className="default-card-style"
                  sx={{
                    background: theme.palette.background.paper,
                    overflowX: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {tableHeader.price.map((headerItem: any, idx: number) => (
                      <Typography
                        sx={{
                          flex: 1,
                          whiteSpace: "nowrap",
                          minWidth: "140px",
                        }}
                        variant="body2"
                        key={headerItem}
                        align={idx == 0 ? "left" : "center"}
                      >
                        {headerItem}
                      </Typography>
                    ))}
                  </Box>
                  {alertsState.priceAlerts.results?.map((priceAlert: any) => (
                    <Box key={priceAlert.id} mt={2}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            flex: 1,
                            whiteSpace: "nowrap",
                            minWidth: "140px",
                          }}
                        >
                          {
                            cryptosState.availableCoins?.find(
                              (crypto: any) => crypto.id == priceAlert.coin
                            )?.name
                          }
                        </Typography>
                        <Typography
                          align="center"
                          sx={{
                            flex: 1,
                            whiteSpace: "nowrap",
                            minWidth: "140px",
                          }}
                        >
                          ${priceAlert.target_price}
                        </Typography>
                        <Typography
                          align="center"
                          sx={{
                            flex: 1,
                            whiteSpace: "nowrap",
                            minWidth: "140px",
                          }}
                        >
                          {moment(priceAlert.created_at).format("YYYY/MM/DD")}
                        </Typography>
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "140px",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{ margin: "auto" }}
                            onClick={() => {
                              setOpenRemovePriceAlertModal(true);
                              setRemoveItem({
                                id: priceAlert.id,
                                type: "price",
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          )}
          <Button
            variant="contained"
            onClick={() => setOpenPriceAlertModal(true)}
            sx={{
              mt: 2,
              "&:hover": {
                background: theme.palette.secondary.main,
              },
            }}
          >
            Add new price alert
          </Button>
          <Typography
            variant="body1"
            align="left"
            mt={5}
            mb={1}
            pb={1}
            color={theme.palette.text.primary}
            sx={{ borderBottom: `1px solid ${theme.palette.grey[700]}` }}
          >
            Engagement Alerts:
          </Typography>
          {alertsState?.engagementAlertsLoading ? (
            <Loading />
          ) : (
            alertsState?.engagementAlerts?.count > 0 && (
              <Box>
                <Box
                  className="default-card-style"
                  sx={{
                    background: theme.palette.background.paper,
                    overflowX: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {tableHeader.engagement.map(
                      (headerItem: any, idx: number) => (
                        <Typography
                          sx={{
                            flex: 1,
                            whiteSpace: "nowrap",
                            minWidth: "140px",
                          }}
                          variant="body2"
                          key={headerItem}
                          align={idx == 0 ? "left" : "center"}
                        >
                          {headerItem}
                        </Typography>
                      )
                    )}
                  </Box>
                  {alertsState.engagementAlerts.results?.map(
                    (engagementAlert: any) => (
                      <Box key={engagementAlert.id} mt={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              flex: 1,
                              whiteSpace: "nowrap",
                              minWidth: "140px",
                            }}
                          >
                            {
                              cryptosState.availableCoins?.find(
                                (crypto: any) =>
                                  crypto.id == engagementAlert.coin
                              )?.name
                            }
                          </Typography>
                          <Typography
                            align="center"
                            sx={{
                              flex: 1,
                              whiteSpace: "nowrap",
                              minWidth: "140px",
                            }}
                          >
                            {
                              engagementAlert.target_engagement_percentage_change
                            }
                            %
                          </Typography>
                          <Typography
                            align="center"
                            sx={{
                              flex: 1,
                              whiteSpace: "nowrap",
                              minWidth: "140px",
                            }}
                          >
                            {moment(engagementAlert.created_at).format(
                              "YYYY/MM/DD"
                            )}
                          </Typography>
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              minWidth: "140px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              sx={{ margin: "auto" }}
                              onClick={() => {
                                setOpenRemovePriceAlertModal(true);
                                setRemoveItem({
                                  id: engagementAlert.id,
                                  type: "engagement",
                                });
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            )
          )}
          <Button
            sx={{
              mt: 2,
              "&:hover": {
                background: theme.palette.secondary.main,
              },
            }}
            variant="contained"
            onClick={() => setOpenEngagementAlertModal(true)}
          >
            Add new engagement alert
          </Button>
          {removeItem.id > 0 && removeAlertModal()}
          {openEngagementAlertModal && (
            <EngagementAlert
              handleClose={() => setOpenEngagementAlertModal(false)}
            />
          )}
          {openPriceAlertModal && (
            <PriceAlert handleClose={() => setOpenPriceAlertModal(false)} />
          )}
          {openLoginAlertModal && (
            <LoginAlertModal
              isLock
              open={openLoginAlertModal}
              setOpen={setLoginAlertModal}
            />
          )}{" "}
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Alerts;
