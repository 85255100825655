import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import {
  Box,
  Chip,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stars from "../../common/svg/stars";
import { AgentsDrawerProps } from "../interfaces";
import { RootState } from "../../../features/store";
import {
  closeAgentDrawer,
  setAgentComponentsData,
  updateAgentList,
} from "../../../features/agent/agentSlice";
import { convertAgentName } from "../../../utils/functions";
import { agentsList } from "../../../data/agentsList";
import DefaultTooltip from "../../common/tooltip";
import styles from "../agents.module.scss";

const AgentDrawer: React.FC<AgentsDrawerProps> = ({
  selectedAgent,
  setSelectedAgent,
  history,
  scrollToItem,
  info_ref,
}) => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const open = useSelector((state: RootState) => state.agent.openDrawer);
  const agentState: any = useSelector((state: RootState) => state.agent);

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const displayHistoryItemHandler = (id: number, user_message: string) => {
    if (Number(params.id) !== id) {
      dispatch(closeAgentDrawer());
      dispatch(updateAgentList([{ question: user_message, answer: null }]));
      navigate(`/agents/${id}`, { replace: true });
    }
  };

  useEffect(() => {
    if (params.id && agentState.agentList.length === 0) {
      navigate(`/agents`, { replace: true });
    }
  }, [params.id]);

  useEffect(() => {
    if (downLg) {
      const rootWrapper: any = document.getElementById("root_wrapper");
      if (open) {
        rootWrapper.style.overflowY = "hidden";
      } else {
        rootWrapper.style.overflowY = "unset";
      }
    }
  }, [open]);

  const clearDataHandler = (agentTitle: string) => {
    scrollToItem(info_ref.current);

    setSelectedAgent(agentTitle);
    dispatch(
      setAgentComponentsData({
        signal: null,
        top_influencers: null,
        tweets: null,
      })
    );

    dispatch(updateAgentList([]));
  };

  function separateDataByDate(dataArray: any) {
    if (dataArray.results.length > 0) {
      const today = new Date();
      const sevenDaysAgo = new Date();
      const thirtyDaysAgo = new Date();

      sevenDaysAgo.setDate(today.getDate() - 7);
      thirtyDaysAgo.setDate(today.getDate() - 30);

      const todayData: any = [];
      const last7DaysData: any = [];
      const last30DaysData: any = [];

      dataArray.results.forEach((item: any) => {
        const createdAt = new Date(item.created_at);

        if (createdAt.toDateString() === today.toDateString()) {
          todayData.push(item);
        } else if (createdAt >= sevenDaysAgo && createdAt < today) {
          last7DaysData.push(item);
        } else if (createdAt >= thirtyDaysAgo && createdAt < sevenDaysAgo) {
          last30DaysData.push(item);
        }
      });

      return {
        today: todayData,
        previous7Days: last7DaysData,
        previous30Days: last30DaysData,
      };
    }
  }

  const HistoryItemComponent = (historyItem: any) => {
    return (
      <Box
        onClick={() =>
          displayHistoryItemHandler(
            historyItem.data.id,
            historyItem.data.user_message
          )
        }
        sx={{
          cursor: "pointer",
          padding: ".8rem .5rem",
          display: "flex",
          alignItems: "center",
          transition: "all .3s",
          background: (theme: any) =>
            Number(params.id) === historyItem.data.id
              ? theme.palette.common.agentBg
              : "transparent",
          borderBottom: `1px solid ${theme.palette.grey[500]}`,
          "&:hover": {
            background: (theme: any) => theme.palette.common.agentBg,
          },
        }}
      >
        <DefaultTooltip
          title={
            agentsList.find(
              (agent: any) =>
                convertAgentName(agent.agent_name) ===
                historyItem.data.agent_type
            )?.title || ""
          }
          placement="top"
        >
          <img
            width={24}
            height={24}
            src={
              agentsList.find(
                (agent: any) =>
                  convertAgentName(agent.agent_name) ===
                  historyItem.data.agent_type
              )?.icon
            }
          />
        </DefaultTooltip>

        <Typography
          title={historyItem.data.user_message}
          variant="body2"
          color={theme.palette.text.primary}
          sx={{
            ml: 1,
            fontWeight: 100,
            fontSize: "0.75rem",
            whiteSpace: "nowrap",
          }}
        >
          {historyItem.data.user_message?.slice(0, 25)}
          {historyItem.data.user_message?.length > 25 && "..."}
        </Typography>
      </Box>
    );
  };

  return (
    <Drawer
      ref={drawerRef}
      className={styles.drawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: 250,
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 3000,
          padding: "1.5rem 0",
          border: "none",
          overflow: "hidden",
          overflowY: "auto",
          background: (theme: any) =>
            downLg
              ? theme.palette.common.solidCard
              : theme.palette.mode === "dark"
              ? theme.palette.common.agentModalBg
              : theme.palette.primary.light,
        },
        ...(!open &&
          downLg && {
            transform: "translateX(-240px)",
            visibility: "hidden",
          }),
      }}
      variant="persistent"
      anchor="left"
      open={downLg ? open : true}
    >
      <Fade className={styles.icon}>
        <Stars width={160} height={160} />
      </Fade>

      <Fade triggerOnce>
        <Box>
          <Typography
            component="h4"
            variant="body1"
            color={theme.palette.text.primary}
            style={{
              padding: "0 1rem 0.8rem",
              marginBottom: "10px",
              color: theme.palette.text.primary,
              borderColor: theme.palette.grey[500],
            }}
          >
            Agents
          </Typography>

          <Box>
            {agentsList.map((agent) => (
              <Box
                key={agent.title}
                onClick={() => {
                  clearDataHandler(agent.title);
                  navigate("/agents");
                }}
                sx={{
                  cursor: "pointer",
                  padding: ".3rem 1rem .3rem .5rem",
                  transition: "all .3s",
                  background: (theme: any) =>
                    params.id
                      ? "transparent"
                      : selectedAgent === agent.title
                      ? theme.palette.common.agentBg
                      : "transparent",
                  "&:hover": {
                    background: (theme: any) => theme.palette.common.agentBg,
                  },
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={agent.icon}
                      alt={agent.title}
                      width={32}
                      height={32}
                    />
                    <Typography
                      variant="caption"
                      color={theme.palette.text.primary}
                      sx={{ ml: 0.5, fontWeight: 500 }}
                    >
                      {agent.title}
                    </Typography>
                  </Box>
                  {agent.type !== "Free" && (
                    <Chip
                      size="small"
                      label={agent.type}
                      sx={{
                        fontWeight: 100,
                        fontSize: ".6rem",
                        borderRadius: "4px",
                        color: theme.palette.common.black,
                        background: theme.palette.secondary.main,
                        padding: ".17rem .25rem",
                        height: "auto",

                        "& span": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Fade>

      {history?.count > 0 && (
        <Fade triggerOnce>
          <Typography
            component="h4"
            variant="body1"
            style={{
              padding: "0 1rem 0.5rem",
              margin: "25px 0 0",
              color: theme.palette.text.primary,
              borderColor: theme.palette.grey[500],
            }}
          >
            Chat History
          </Typography>
          <Box className={`customScrollbar ${scrollbarClassName}`}>
            {separateDataByDate(history)?.today.length > 0 && (
              <>
                <Typography
                  variant="body2"
                  pl={1}
                  mt={2}
                  sx={{ fontWeight: 100, fontSize: ".8rem" }}
                  color={(theme: any) => theme.palette.text.primary}
                >
                  Today
                </Typography>
                {separateDataByDate(history)?.today?.map((historyItem: any) => (
                  <HistoryItemComponent
                    key={historyItem.id}
                    data={historyItem}
                  />
                ))}
              </>
            )}

            {separateDataByDate(history)?.previous7Days.length > 0 && (
              <>
                <Typography
                  variant="body2"
                  pl={1}
                  mt={2}
                  sx={{ fontWeight: 100, fontSize: ".8rem" }}
                  color={(theme: any) => theme.palette.text.primary}
                >
                  Last 7 Days
                </Typography>
                {separateDataByDate(history)?.previous7Days?.map(
                  (historyItem: any) => (
                    <HistoryItemComponent
                      key={historyItem.id}
                      data={historyItem}
                    />
                  )
                )}
              </>
            )}

            {separateDataByDate(history)?.previous30Days.length > 0 && (
              <>
                <Typography
                  variant="body2"
                  pl={1}
                  mt={2}
                  sx={{ fontWeight: 100, fontSize: ".8rem" }}
                  color={(theme: any) => theme.palette.text.primary}
                >
                  Last 30 Days
                </Typography>
                {separateDataByDate(history)?.previous30Days?.map(
                  (historyItem: any) => (
                    <HistoryItemComponent
                      key={historyItem.id}
                      data={historyItem}
                    />
                  )
                )}
              </>
            )}
          </Box>
        </Fade>
      )}
    </Drawer>
  );
};

export default AgentDrawer;
