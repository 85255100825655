import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { TbChartCandle, TbChartLine } from "react-icons/tb";
import { CryptoPageType } from "../interfaces";
import { convertData } from "../../../utils/functions";
import Loading from "../../../components/common/loading";
import CryptoHeader from "../../../components/cryptoPage/header";
import CryptoSignal from "../../../components/cryptoPage/signal-sentiment";
import CryptoInfluencers from "../../../components/cryptoPage/influencers";
import CryptoRssNews from "../../../components/cryptoPage/rssNews";
import CryptoStatistics from "../../../components/cryptoPage/statistics";
import CryptoTechnicalSignals from "../../../components/cryptoPage/technicalSignals";
import CryptoChart from "../../../components/cryptoPage/chart";
import FinancialChart from "../../../components/crypto/financialChart";
import CryptoExchanges from "../../../components/cryptoPage/exchanges";
import Performance from "../../../components/crypto/performance";
import SocialMediaActivityChart from "../../../components/charts/SocialMediaActivityChart";
import CardTitle from "../../../components/common/title/CardTitle";
import styles from "../cryptoPage.module.scss";

const tabs = [
  "SPS",
  "Graph",
  "Influencers",
  "Bulletin",
  "Technical",
  "Market Data",
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const theme = useTheme();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`crypto-tabpanel-${index}`}
      aria-labelledby={`crypto-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

const MobileView = ({
  cryptosState,
  cryptoState,
  assetAvailability,
  cryptoChartData,
  cryptoFilter,
  setCryptoFilter,
  cryptoIndicatorsState,
  technichalMethod,
  setTechnichalMethod,
  showTechnichalMethod,
  setShowTechnichalMethod,
  loadingHistoricalChart,
  setRssPage,
  influencers,
  newsList,
  cryptoNewsState,
  technical_signals,
}: {
  cryptosState: any;
  cryptoState: any;
  assetAvailability: any;
  cryptoChartData: any;
  cryptoFilter: any;
  setCryptoFilter: any;
  cryptoIndicatorsState: any;
  technichalMethod: any;
  setTechnichalMethod: any;
  showTechnichalMethod: any;
  setShowTechnichalMethod: any;
  loadingHistoricalChart: any;
  setRssPage: any;
  influencers: any;
  newsList: any;
  cryptoNewsState: any;
  technical_signals: any;
}) => {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [chartType, setChartType] = useState("financial");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleAlignment = (newAlignment: string) => {
    setChartType(newAlignment);
  };

  const technichalMethodHandler = (method: string) => {
    if (cryptoFilter.type !== "1H" && cryptoFilter.type !== "1D") {
      setTechnichalMethod(method);
    } else {
      toast.error("This indicator is not available for this time frame");
    }
  };

  const cryptoFilterChangeHandler = (type: string, interval: string) => {
    setCryptoFilter({
      type: type,
      interval: interval,
    });
  };

  const SectionInfo = (info: string) => {
    return (
      <Typography variant="body1" mb={1} color={theme.palette.grey[500]}>
        {info}
      </Typography>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CryptoHeader
        cryptosState={cryptosState}
        data={cryptoState.coin}
        coinId={
          location.state
            ? location.state.coin_id
            : cryptosState.availableCoins?.find(
                (item: CryptoPageType) =>
                  item.name.toLowerCase() === params.crypto
              )
            ? cryptosState.availableCoins?.find(
                (item: CryptoPageType) =>
                  item.name.toLowerCase() === params.crypto
              )?.id
            : 3
        }
      />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="crypto page tabs"
          variant="scrollable"
          scrollButtons={false}
        >
          {tabs.map((tabItem) => (
            <Tab label={tabItem} key={tabItem} />
          ))}
        </Tabs>
      </Box>

      <CustomTabPanel value={activeTab} index={0}>
        {cryptoNewsState.cryptoNewsCountLoading ? (
          <Loading />
        ) : (
          cryptoNewsState.cryptoNewsCount && (
            <>
              <CardTitle isCenter>Social Activity</CardTitle>
              <SocialMediaActivityChart
                name="News count"
                data={cryptoNewsState.cryptoNewsCount}
              />
            </>
          )
        )}

        <CryptoSignal
          isAvailable={cryptosState.availableCoins?.find(
            (item: CryptoPageType) => item.name.toLowerCase() === params.crypto
          )}
          coinId={
            location.state
              ? location.state.coin_id
              : cryptosState.availableCoins?.find(
                  (item: CryptoPageType) =>
                    item.name.toLowerCase() === params.crypto
                )
              ? cryptosState.availableCoins?.find(
                  (item: CryptoPageType) =>
                    item.name.toLowerCase() === params.crypto
                )?.id
              : 3
          }
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <Box
          my={1}
          py={10}
          sx={{
            background: (theme: any) => theme.palette.common.darkPrimary,
            borderRadius: "10px",
          }}
        >
          <Typography align="center">Coming Soon</Typography>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={2}>
        {SectionInfo(
          "Profiles of influencers whose tweets about this specific cryptocurrency garnered the highest engagement"
        )}
        <CryptoInfluencers influencers={influencers} />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={3}>
        {SectionInfo(
          "Websites that publish cryptocurrency news and offer articles specifically about the coin are all accessible in one place"
        )}
        <CryptoRssNews
          setRssPage={setRssPage}
          //@ts-ignore
          newsList={newsList}
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={4}>
        <Box mb={5}>
          <CardTitle isCenter>Performance</CardTitle>
          <Box mt={2}>
            <Performance data={cryptoState} size="small" />
          </Box>
        </Box>

        <CardTitle isCenter>Technical Signals</CardTitle>

        {!technical_signals ? (
          <Typography
            align="center"
            mt={5}
            className="default-card-style"
            sx={{
              background: (theme: any) => theme.palette.background.paper,
              mt: 2,
            }}
          >
            <strong>N/A</strong> (Coming Soon)
          </Typography>
        ) : (
          <CryptoTechnicalSignals data={convertData(technical_signals)} />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={5}>
        {cryptoState.coin ? (
          <Box>
            <Box>
              <ToggleButtonGroup
                value={chartType}
                exclusive
                aria-label="chart type"
                size="small"
                sx={{
                  background:
                    //@ts-ignore
                    theme.palette.grey.main,
                  borderRadius: "10px 10px 0 0",
                  minWidth: "auto !important",
                  overflow: "hidden",
                }}
              >
                <ToggleButton
                  value="financial"
                  aria-label="financial"
                  onClick={() => handleAlignment("financial")}
                  sx={{
                    padding: "7px 15px",
                    borderRadius: 0,
                  }}
                >
                  <TbChartCandle size={22} />
                </ToggleButton>
                <ToggleButton
                  value="crypto"
                  aria-label="crypto"
                  onClick={() => handleAlignment("crypto")}
                  sx={{
                    padding: "7px 15px",
                    borderRadius: 0,
                  }}
                >
                  <TbChartLine size={22} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              p={1}
              sx={{
                background: theme.palette.background.paper,
                borderRadius: "0 8px 8px 8px",
              }}
            >
              {chartType === "crypto" && assetAvailability ? (
                <CryptoChart
                  name="Price"
                  data={cryptoChartData}
                  cryptoFilter={cryptoFilter}
                  cryptoIndicators={cryptoIndicatorsState}
                  technichalMethod={technichalMethod}
                  showTechnichalMethod={showTechnichalMethod}
                  technichalMethodHandler={technichalMethodHandler}
                  cryptoFilterChangeHandler={cryptoFilterChangeHandler}
                  loadingHistoricalChart={loadingHistoricalChart}
                  isAvailable={cryptosState.availableCoins.find(
                    (item: CryptoPageType) => item.id === params.crypto
                  )}
                />
              ) : (
                <FinancialChart symbol={cryptoState.coin.data.symbol} />
              )}
            </Box>
          </Box>
        ) : (
          <Loading />
        )}

        <Box my={5}>
          <CryptoStatistics data={cryptoState.coin} />
        </Box>

        <Box mt={8}>
          <Box mb={2}>
            <CardTitle isCenter>Exchanges</CardTitle>
          </Box>
          {SectionInfo(
            "A comprehensive list of all markets where this particular cryptocurrency is actively traded"
          )}
          <CryptoExchanges data={cryptoState} />
        </Box>
      </CustomTabPanel>
    </Box>
  );
};

export default MobileView;
