import {
  Box,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { LuInfo } from "react-icons/lu";
import { TableTitleProps } from "../interfaces";

const TableTitle: React.FC<TableTitleProps> = ({
  children,
  isCenter,
  hasInfo,
  info,
  infoTitle,
  hasMargin,
  setItemInfo,
}) => {
  const theme = useTheme();

  const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      enterTouchDelay={0}
      {...props}
      classes={{ popper: className }}
      placement="top"
      arrow
      onClick={(e) => e.stopPropagation()}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color:
        //@ts-ignore
        theme.palette.common.backdrop,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:
          hasInfo && isCenter ? "center" : hasInfo ? "space-between" : "center",
        alignItems: "center",
        marginBottom: hasMargin ? ".9rem" : 0,
        "& .MuiTooltip-tooltip": {
          background:
            //@ts-ignore
            theme.palette.common.solidCard,
        },
      }}
    >
      <Typography
        component="h1"
        sx={{
          //@ts-ignore
          color: theme.palette.common.whiteToGreen,
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: 1,
          marginRight: hasInfo ? ".2rem" : 0,
        }}
        textAlign="center"
        align={isCenter ? "center" : "left"}
      >
        {typeof children === "string"
          ? children.split("\n").map((i, index, arr) =>
              index + 1 !== arr.length ? (
                <div key={i}>
                  <span>{i}</span>
                  <br />
                </div>
              ) : (
                <span key={i}>{i}</span>
              )
            )
          : children}
      </Typography>
      {hasInfo && infoTitle ? (
        <TableTooltip title={info}>
          <IconButton size="small">
            <LuInfo
              size={13}
              color={
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
              }
            />
          </IconButton>
        </TableTooltip>
      ) : (
        ""
      )}
    </Box>
  );
};

export default TableTitle;
