import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Stars from "../../components/common/svg/stars";
import Loading from "../../components/common/loading";
import ContentContainer from "../../components/common/contentContainer";
import AssetDrawer from "../../components/assetsManagement/assetDrawer";
import AssetsAnalysis from "../../components/assetsManagement/assetsAnalysis";
import AssetsManagementDemo from "../../components/assetsManagement/assetsManagementDemo";
import LoginAlertModal from "../../components/common/loginAlertModal";
import walletAssets from "./../../assets/images/icons/walletAssets.png";
import cashAssets from "./../../assets/images/icons/cashAssets.png";
import cryptoAssets from "./../../assets/images/icons/cryptoAssets.png";
import ethIcon from "./../../assets/images/icons/eth_icon.svg";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { closeAssetsDrawer } from "../../features/assets/assetsSlice";
import {
  fetchWalletNetworthStatistics,
  fetchWalletsList,
} from "../../features/assetsManagement/assetsManagementSlice";
import useAuth from "../../utils/useAuth";
import { AssetTypeCardProps } from "./interfaces";
import styles from "./assetsManagement.module.scss";

const AssetsManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const isAuthValid = useAuth();
  const account: any = useSelector((state: RootState) => state.account);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const assetsState: any = useSelector((state: RootState) => state.assets);
  const assetsManagementState: any = useSelector((state: RootState) => state.assetsManagement);
  const open: any = useSelector((state: RootState) => state.assets.openDrawer);
  const hasAsset = assetsManagementState.wallets?.length > 0;

  const [portfolioLoading, setPortfolioLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletsData, setWalletsData] = useState<any>([]);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);
  const handleOpen = () => setOpenTemplate(true);
  const handleClose = () => setOpenTemplate(false);

  const removeAssetHandler = (item: any) => {};

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    if (account.isAuthenticated) {
      if (!assetsManagementState.wallets) {
        dispatch(fetchWalletsList());
      }
    }
  }, [dispatch, account.isAuthenticated, assetsManagementState.wallets]);

  useEffect(() => {
    if (account.isAuthenticated) {
      if (
        cryptosState.availableCoins &&
        assetsManagementState.wallets?.length > 0 &&
        !assetsManagementState.networthStatisticsLoading
      ) {
        const wallets = {
          wallets: assetsManagementState?.wallets.map((wallet: any) => wallet.id),
        };

        dispatch(fetchWalletNetworthStatistics(wallets)).then((response) => {
          setLoading(false);
        });
      }
    }
  }, [
    account.isAuthenticated,
    cryptosState.availableCoins,
    assetsManagementState.wallets,
    dispatch,
  ]);

  useEffect(() => {
    if (!account.loading && !account.isAuthenticated) {
      setLoginAlertModal(true);
    }
  }, [account.loading]);

  useEffect(() => {
    if (
      !assetsManagementState.networthStatisticsLoading &&
      assetsManagementState.networthStatistics
    ) {
      const assetsCount = Object.keys(
        assetsManagementState.networthStatistics?.networth_statistics
      ).filter((key: string) => key != "dollar_balance").length;
      const walletsCount = Array.from(
        new Set(
          assetsManagementState.wallets
            .filter((wall: any) => wall.category != "Cash")
            .map((wall: any) => wall.protocol)
        )
      ).length;

      if (walletsCount > 0) {
        if (assetsCount < walletsCount) {
          setPortfolioLoading(true);

          var interval = setInterval(() => {
            const wallets = {
              wallets: assetsManagementState?.wallets.map((wallet: any) => wallet.id),
            };

            dispatch(fetchWalletNetworthStatistics(wallets));
          }, 30000);
        } else {
          setPortfolioLoading(false);
        }
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [assetsManagementState.networthStatisticsLoading, assetsManagementState.networthStatistics]);

  const assetTypeCard = ({ link, title, subtitle, icon }: AssetTypeCardProps) => {
    return (
      <Box
        className={`default-card-style ${styles.assets_item}`}
        sx={{
          background: (theme: any) =>
            theme.palette.mode === "dark"
              ? theme.palette.common.darkPrimary
              : theme.palette.primary.light,
        }}
        // onClick={() => navigate(`/asset-tracking/${link}`)}
        onClick={() =>
          !account.isAuthenticated ? setLoginAlertModal(true) : navigate(`/asset-tracking/${link}`)
        }
      >
        <Typography variant="h2" color={theme.palette.text.primary}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          mt={1.3}
          sx={{
            maxWidth: 380,
            color: theme.palette.text.primary,
          }}
        >
          {subtitle}
        </Typography>
        <Fade triggerOnce>
          <img src={icon} alt={title} />
        </Fade>
      </Box>
    );
  };

  return (
    <DashboardLayout
      title="Coinfident Asset Tracking Tool"
      description="Monitor all your assets in one place. Take control of your assets and optimize your crypto portfolio with Coinfident’s asset tracking tool."
    >
      <Container maxWidth="lg" onClick={() => open && dispatch(closeAssetsDrawer())}>
        <Box className={styles.assets_management}>
          <Fade triggerOnce>
            <img src={ethIcon} alt="icon" className={styles.eth_logo} />
          </Fade>
          <Fade className={styles.stars}>
            <Stars width={320} height={320} />
          </Fade>
          <AssetDrawer />
          <ContentContainer open={open} type="assets">
            <Typography
              mt={3}
              mb={hasAsset ? 0 : 2}
              variant="h1"
              align={hasAsset ? "left" : "center"}
              sx={{ color: theme.palette.text.primary }}
            >
              {hasAsset ? "Add Asset" : "Asset Tracking"}
            </Typography>

            {hasAsset ? (
              ""
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{
                  maxWidth: 800,
                  margin: "0 auto 2rem",
                  fontWeight: 100,
                  color: theme.palette.text.primary,
                }}
              >
                You can easily manage your assets by adding them manually or connecting your
                wallets. Our analytics portfolio feature allows you to track your balance and gain
                insights into your investments. Stay on top of your finances with our user-friendly
                platform.
              </Typography>
            )}

            {downLg ? (
              <Box
                mt={5}
                className={`default-card-style ${styles.assets_item}`}
                sx={{
                  background: (theme: any) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.common.darkPrimary
                      : theme.palette.primary.light,
                }}
              >
                <Typography
                  color={theme.palette.text.primary}
                  sx={{ lineHeight: 1.7, fontSize: "1rem !important" }}
                >
                  Coinfident's Asset Tracking is currently accessible exclusively on{" "}
                  <strong>Desktop</strong> devices.
                </Typography>
              </Box>
            ) : (
              <Grid
                container
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12}>
                  <Fade triggerOnce>
                    <Box className={styles.assets_items}>
                      {assetTypeCard({
                        link: "add-crypto",
                        title: "Crypto Coins",
                        subtitle: "Easily input your cryptocurrencies",
                        icon: cryptoAssets,
                      })}

                      {assetTypeCard({
                        link: "add-cash",
                        title: "Enter Manually",
                        subtitle: " Input your cash manually",
                        icon: cashAssets,
                      })}

                      {assetTypeCard({
                        link: "connect-wallet",
                        title: "Connect Wallet",
                        subtitle: "Link your digital wallets to monitor your crypto investments",
                        icon: walletAssets,
                      })}
                    </Box>
                  </Fade>
                </Grid>

                {portfolioLoading ||
                (assetsManagementState.loading > 0 &&
                  assetsManagementState.networthStatisticsLoading) ||
                loading ? (
                  <Box mt={10}>
                    <Loading />
                    <Typography align="center" color={theme.palette.text.primary}>
                      Generating Portfolio
                    </Typography>
                    <Typography align="center" color={theme.palette.text.primary}>
                      This may take few minutes
                    </Typography>
                  </Box>
                ) : hasAsset ? (
                  <Grid item xs={12}>
                    <Fade triggerOnce>
                      <Typography
                        mt={10}
                        mb={2}
                        variant="h1"
                        align="center"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        Asset Tracking
                      </Typography>
                      <AssetsAnalysis
                        assets={assetsState}
                        walletsData={walletsData}
                        removeAssetHandler={removeAssetHandler}
                      />
                    </Fade>
                  </Grid>
                ) : (
                  <Box>
                    <Typography
                      mt={10}
                      variant="h6"
                      align="center"
                      color={theme.palette.text.primary}
                    >
                      See a demo of our asset tracking
                    </Typography>
                    <Button
                      onClick={handleOpen}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1 }}
                    >
                      VIEW DEMO
                    </Button>
                    <AssetsManagementDemo openTemplate={openTemplate} handleClose={handleClose} />
                  </Box>
                )}
              </Grid>
            )}
          </ContentContainer>
        </Box>
      </Container>
      {openLoginAlertModal && (
        <LoginAlertModal isLock open={openLoginAlertModal} setOpen={setLoginAlertModal} />
      )}
    </DashboardLayout>
  );
};

export default AssetsManagement;
