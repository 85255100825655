import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { AllMoversResponseState } from "./interfaces";

export const fetchAllMovers = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("movers", async (_, thunkAPI) => {
  try {
    const url = "/crypto/get-top-movers/";
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: AllMoversResponseState = {
  data: null,
  loading: true,
  error: null,
};

export const moversSlice = createSlice({
  name: "movers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMovers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllMovers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllMovers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default moversSlice.reducer;
