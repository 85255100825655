import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { BiSolidHot, BiSort } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import ChangeFeed from "./changeFeed";
import FilterNews from "./filterNews";
import { NewsHeaderProps } from "../interfaces";
import styles from "./newsHeader.module.scss";

const NewsHeader: React.FC<NewsHeaderProps> = ({
  newsList,
  newsType,
  setNewsType,
  filterChangeHandler,
}) => {
  const theme = useTheme();

  const [twitterFilters, setTwitterFilters] = useState({
    ordering: "-total",
    selectedCoins: [],
  });
  const [rssFilters, setRssFilters] = useState({
    search: "",
    selectedCoins: [],
  });

  return (
    <>
      <Box
        mt={-3.5}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <FilterNews
          newsList={newsList}
          newsType={newsType}
          filterChangeHandler={filterChangeHandler}
          twitterFilters={twitterFilters}
          setTwitterFilters={setTwitterFilters}
          rssFilters={rssFilters}
          setRssFilters={setRssFilters}
        />
        <ChangeFeed setNewsType={setNewsType} />
      </Box>

      {newsType === "Twitter" && (
        <Box className={styles.sortFilters}>
          <BiSort size={20} color={theme.palette.text.primary} />
          {twitterFilters.ordering === "-total" ? (
            <Typography variant="body2" color={theme.palette.primary.main}>
              <BiSolidHot size={21} color={theme.palette.primary.main} /> Top
              Tweets
            </Typography>
          ) : (
            <Typography variant="body2" color={theme.palette.primary.main}>
              <AiOutlineClockCircle
                size={21}
                color={theme.palette.primary.main}
              />
              Latest Tweets
            </Typography>
          )}
        </Box>
      )}

      {newsType === "Twitter" &&
      twitterFilters.selectedCoins &&
      twitterFilters.selectedCoins.length > 0 ? (
        <Box
          className={styles.activeFilters}
          style={{
            borderColor:
              theme.palette.mode === "light"
                ? //@ts-ignore
                  theme.palette.grey[500]
                : //@ts-ignore
                  theme.palette.grey[800],
          }}
        >
          <Typography
            variant="body2"
            component="h3"
            color={theme.palette.text.primary}
          >
            Selected Coins:
          </Typography>
          {twitterFilters.selectedCoins.map((item: string) => (
            <Typography
              key={item}
              variant="caption"
              style={{
                background:
                  theme.palette.mode === "light"
                    ? //@ts-ignore
                      theme.palette.grey[500]
                    : //@ts-ignore
                      theme.palette.grey[800],
              }}
            >
              {item.toUpperCase()}
            </Typography>
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default NewsHeader;
