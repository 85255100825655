import {
  Box,
  IconButton,
  Modal,
  Typography,
  Backdrop,
  useTheme,
  Fade,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { AssetModalProps } from "../interfaces";
import styles from "../assetsManagement.module.scss";

const AssetModal: React.FC<AssetModalProps> = ({
  open,
  setOpen,
  title,
  children,
  action,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          className={styles.assets_modal}
          sx={{
            background: (theme: any) =>
              theme.palette.mode === "dark"
                ? theme.palette.common.darkPrimary
                : theme.palette.primary.light,
          }}
        >
          <Box className={styles.assets_modal_header}>
            <Typography variant="body1" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <IconButton onClick={() => setOpen(!open)}>
              <MdClose />
            </IconButton>
          </Box>
          {children}
          <Box mt={4} display="flex" justifyContent="flex-end">
            {action}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AssetModal;
