import { useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SecondaryButtonProps } from "../interfaces";
import styles from "./buttons.module.scss";

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  text,
  clickHandler,
  loading,
  disabled
}) => {
  const theme = useTheme();

  return (
    <LoadingButton
      variant="contained"
      size="medium"
      loading={loading}
      loadingPosition="center"
      className={styles.secondary_button}
      disabled={disabled}
      sx={{
        //@ts-ignore
        backgroundColor: theme.palette.grey.dark,
        color: "#fff",
      }}
      onClick={clickHandler}
    >
      {text}
    </LoadingButton>
  );
};

export default SecondaryButton;
