import { Box, Skeleton } from '@mui/material'
import React from 'react'

const AssetsAnalysisSkeleton: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" my={5}>
        <Skeleton variant="circular" width={250} height={250} />
    </Box>
  )
}

export default AssetsAnalysisSkeleton