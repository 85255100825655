import { Box } from "@mui/material";
import Gmail from "../../../assets/images/icons/gmail.png";
import Telegram from "../../../assets/images/icons/telegram.png";
import Facebook from "../../../assets/images/icons/facebook.png";
import TwitterX from "../../../assets/images/icons/twitter-X.png";
import { ShareProps } from "../interfaces";
import styles from "./share.module.scss";

const Share: React.FC<ShareProps> = ({
  facebookUrl,
  telegramUrl,
  gmailUrl,
  twitterUrl,
}) => {
  return (
    <Box className={styles.share}>
      <a href={`https://twitter.com/share?url=${twitterUrl}`} target="_blank">
        <img src={TwitterX} alt="Twitter-X" />
      </a>
      <a
        href={`https://telegram.me/share/url?url=${telegramUrl}&text=Please check the following link form Coinfident`}
        target="_blank"
      >
        <img src={Telegram} alt="Telegram" />
      </a>
      <a
        href={`mailto:?subject=Shared form Coinfident&body=Hello,%0D%0A%0D%0APlease check the following link form Coinfident:%0D%0A%0D%0A${gmailUrl}%0D%0A%0D%0A`}
      >
        <img src={Gmail} alt="Gmail" />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${facebookUrl}`}
        target="_blank"
      >
        <img src={Facebook} alt="Facebook" />
      </a>
    </Box>
  );
};

export default Share;
