import http from "../services/http";
import { EngagementAlertProps, PriceAlertProps } from "./interfaces";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createPriceAlertHandler = async (data: PriceAlertProps) => {
  const url = `${BASE_URL}/alerts/coin-price-alert-create/`;
  const request = await http
    .post(url, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const removePriceAlertHandler = async (id: number) => {
  const url = `${BASE_URL}/alerts/coin-price-alert/${id}`;
  const request = await http
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const createEngagementAlertHandler = async (
  data: EngagementAlertProps
) => {
  const url = `${BASE_URL}/alerts/coin-engagement-alert-create/`;
  const request = await http
    .post(url, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const removeEngagementAlertHandler = async (id: number) => {
  const url = `${BASE_URL}/alerts/coin-engagement-alert/${id}`;
  const request = await http
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};
