import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { Grid } from "@mui/material";
import AssetsList from "./assetsList";
import AssetsListChart from "./assetsListChart";
import NetworthHeader from "./networthHeader";
import NetworthChart from "./networthChart";
import { RootState } from "../../../features/store";
import { AssetsAnalysisProps } from "../interfaces";
import { currencies } from "../../../data/currencies";
import {
  fetchWalletBalance,
  walletStockUpdateHandler,
} from "../../../api/assets";
import { wallets } from "../../../data/wallets";
import cashAssets from "../../../assets/images/icons/cashAssets.png";
import walletAssets from "../../../assets/images/icons/walletAssets.png";

const AssetsAnalysis: React.FC<AssetsAnalysisProps> = ({
  assets,
  walletsData,
}) => {
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [walletsAssetsList, setWalletsAssetsList] = useState<any>([]);
  const [cashAssetsList, setCashAssetsList] = useState<any>([]);

  function calculateTotal(data: any) {
    if (data) {
      const totalValues = Object.values(data).reduce(
        (acc: any, item: any) => {
          if (item.hasOwnProperty("total")) {
            acc.total += item.total;
          }
          return acc;
        },
        { total: data.dollar_balance }
      );

      //@ts-ignore
      return totalValues.total;
    }
  }

  const fetchWalletBalanceData = async (walletData: any) => {
    for (const wallet of walletData.filter(
      (wall: any) => wall.category !== "Cash"
    )) {
      const wallet_data = {
        protocol: wallet.protocol,
        network: "mainnet",
        wallet_address: wallet.address,
      };

      const balanceResponse = await fetchWalletBalance(wallet_data);

      balanceResponse.map((walletDataItem: any) => {
        walletStockUpdateHandler(
          walletData[0].id,
          balanceResponse.map((asset: any) => ({
            coin_id: cryptosState.coinsStatistics?.find(
              (crypto: any) => crypto.label === asset.currency.symbol
            )?.coin_id,
            symbol: asset.currency.symbol,
            balance:
              Number(asset.confirmed_balance) /
              Math.pow(10, asset.currency.decimals ?? 0),
          })),
          0
        );
      });

      setWalletsAssetsList([
        ...walletsAssetsList,
        {
          type: "ConnectedWallet",
          image: wallets.find((wall: any) => wall.name == wallet.name)
            ?.imageUrl,
          logo: walletAssets,
          symbol: wallet.name,
          value:
            (
              (Number(balanceResponse[0].confirmed_balance) /
                Math.pow(10, balanceResponse[0].currency.decimals ?? 0)) *
              Number(
                cryptosState.coinsStatistics?.find(
                  (crypto: any) =>
                    crypto.label == balanceResponse[0].currency.symbol
                )?.latest_price
              )
            ).toFixed(3) ?? 0,
          asset: wallet,
        },
      ]);
    }
  };

  useEffect(() => {
    if (cryptosState.coinsStatistics) {
      if (assetsManagementState.networthStatistics) {
        const dollar_wallet = assetsManagementState.wallets.find(
          (wallet: any) => wallet.name == "Dollar_cash"
        );
        const wallets_list = assetsManagementState.wallets.filter(
          (wallet: any) => wallet.name != "Dollar_cash"
        );
        const dollar_wallet_data = cashAssetsList.find(
          (wall: any) => wall.type == "ManualCash"
        );

        if (wallets_list.length > 0) {
          fetchWalletBalanceData(wallets_list);
        }

        if (dollar_wallet != undefined) {
          if (dollar_wallet_data != undefined) {
            dollar_wallet_data.value =
              assetsManagementState.networthStatistics.networth_statistics.dollar_balance;
          } else {
            setCashAssetsList([
              ...cashAssetsList,
              {
                type: "ManualCash",
                image: currencies.find(
                  (cur: any) => cur.CountryName == "United States"
                )?.Flag,
                logo: cashAssets,
                symbol: "Dollar ($)",
                value:
                  assetsManagementState.networthStatistics.networth_statistics
                    .dollar_balance,
                asset: dollar_wallet,
              },
            ]);
          }
        } else {
          setCashAssetsList([]);
        }
      }
    }
  }, [assetsManagementState.networthStatistics, cryptosState.coinsStatistics]);

  return (
    <>
      {assetsManagementState.networthStatistics && (
        <>
          <NetworthHeader
            netWorth={calculateTotal(
              assetsManagementState.networthStatistics?.networth_statistics
            )}
            assets={assetsManagementState.networthStatistics}
          />
          <NetworthChart assets={assetsManagementState.networthStatistics} />
          <Fade triggerOnce>
            <Grid container columnGap={1}>
              <Grid item xs={12} md={6.91}>
                <AssetsList
                  wallets={walletsAssetsList}
                  cash={cashAssetsList}
                  assetsManagementState={assetsManagementState}
                  totalAssetsValue={calculateTotal(
                    assetsManagementState.networthStatistics
                      ?.networth_statistics
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <AssetsListChart
                  assetsManagementState={assetsManagementState}
                />
              </Grid>
            </Grid>
          </Fade>
        </>
      )}
    </>
  );
};

export default AssetsAnalysis;
