import { Link, useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LiaLinkedin, LiaTelegram } from "react-icons/lia";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandDiscord } from "react-icons/tb";
import { IoLogoInstagram } from "react-icons/io5";
import Stars from "../common/svg/stars";
import FooterSvg from "./footerSvg";
import coinfidentLogo from "../../assets/images/logo/coinfident-logo.png";
import smsLogo from "../../assets/images/logo/SMS.png";
import styles from "./footer.module.scss";

const Footer = () => {
  const theme = useTheme();
  const location = useLocation();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      py={5}
      mt={5}
      className={styles.footer_wrapper}
      ml={downLg ? 0 : location.pathname.includes("/agents") ? "225px" : 0}
    >
      <Box
        sx={{
          maxWidth: "2000px",
          mx: "auto",
        }}
      >
        <Fade triggerOnce>
          <Box className={styles.stars}>
            <Stars width={250} height={250} />
          </Box>
        </Fade>

        <Grid container className={styles.footerWrapper}>
          <Grid item xs={12} md={3.5} className={styles.footerInfo}>
            <img src={coinfidentLogo} alt="coinfident Logo" />
            <Box className={styles.footerContact}>
              <Typography variant="body2" color={theme.palette.text.primary}>
                Enifex (Switzerland) SA Bahnhofstrasse 16, 6300, Zug,
                Switzerland
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                +41792640867 | support@Coinfident.ai
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                Registered in the Commercial Register of Zug with company
                registration number CHE-235.052.228.
              </Typography>
            </Box>
            <a href="https://www.swissmadesoftware.org/" target="_blank">
              <img
                className={styles.smsLogo}
                src={smsLogo}
                alt="swissmadesoftware Logo"
              />
            </a>
          </Grid>

          <Grid item xs={12} md={2.5} className={styles.footerSocialMedia}>
            <Typography
              component="h3"
              color={theme.palette.text.primary}
              className={styles.footerTitle}
            >
              Social Media
            </Typography>
            <Box>
              <a
                target="_blank"
                href="https://twitter.com/coinfident_ai"
                style={{
                  margin: "0 14px",
                }}
              >
                <FaXTwitter color={theme.palette.text.primary} size={25} />
                <Typography
                  variant="body1"
                  sx={{ display: "inline" }}
                  color={theme.palette.text.primary}
                >
                  X (Twitter)
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/coinfident/"
              >
                <LiaLinkedin
                  size={35}
                  color={theme.palette.text.primary}
                  style={{ marginLeft: "-5px", marginRight: "-5px" }}
                />
                <Typography
                  variant="body1"
                  color={theme.palette.text.primary}
                  sx={{ display: "inline" }}
                >
                  Linkedin
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/coinfident_ai/"
              >
                <IoLogoInstagram
                  color={theme.palette.text.primary}
                  size={30}
                  style={{ marginLeft: "-3px", marginRight: "-2px" }}
                />
                <Typography
                  variant="body1"
                  color={theme.palette.text.primary}
                  sx={{ display: "inline" }}
                >
                  Instagram
                </Typography>
              </a>
              <a target="_blank" href="https://t.me/coinfident_ai">
                <LiaTelegram
                  color={theme.palette.text.primary}
                  size={30}
                  style={{ marginLeft: "-4px", marginRight: "-2px" }}
                />
                <Typography
                  variant="body1"
                  color={theme.palette.text.primary}
                  sx={{ display: "inline" }}
                >
                  Telegram
                </Typography>
              </a>
              <a target="_blank" href="https://discord.gg/7VHgv3XmNp">
                <TbBrandDiscord
                  color={theme.palette.text.primary}
                  size={30}
                  style={{ marginLeft: "-3px", marginRight: "-2px" }}
                />
                <Typography
                  variant="body1"
                  color={theme.palette.text.primary}
                  sx={{ display: "inline" }}
                >
                  Discord
                </Typography>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.5} className={styles.footerLinks}>
            <Typography
              component="h3"
              color={theme.palette.text.primary}
              className={styles.footerTitle}
            >
              Links
            </Typography>
            <Grid container className={styles.footerPagesLinks}>
              {downLg && (
                <Grid item xs={6}>
                  <Link to="/features">Other Features</Link>
                </Grid>
              )}
              <Grid item xs={6}>
                <Link to="/subscription">Pricing</Link>
              </Grid>
              <Grid item xs={6}>
                <a href="https://coinfident.ai/blog" target="_blank">
                  Blog
                </a>
              </Grid>
              <Grid item xs={6}>
                <a href="https://coinfident.ai/about-us" target="_blank">
                  About Us
                </a>
              </Grid>
              <Grid item xs={6}>
                <a href="https://coinfident.ai/privacy-policy" target="_blank">
                  Privacy
                </a>
              </Grid>
              <Grid item xs={6}>
                <a
                  href="https://coinfident.ai/terms-and-conditions"
                  target="_blank"
                >
                  Terms
                </a>
              </Grid>
              <Grid item xs={6}>
                <Link to="/security">Security</Link>
              </Grid>

              {downLg && (
                <Grid item xs={6}>
                  <Link to="/influencers">Influencers</Link>
                </Grid>
              )}
              <Grid item xs={6}>
                <Link to="/FAQ">FAQs</Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3.5} className={styles.footerDisclaimer}>
            <Typography
              component="h3"
              color={theme.palette.text.primary}
              className={styles.footerTitle}
            >
              Disclaimer
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary}>
              All the information on this website or other official channels is
              published for information purposes only and is only intended for
              institutional investors and sophisticated individual investors.
              Any services provided in the future will be subject to the terms
              of the legal agreements relating thereto. Nothing on this site
              should be interpreted as the advisement on investment, tax, legal
              or other advice by Coinfident or any other person. Coinfident is a
              software retail company specialized in crypto data analysis and
              asset tracking. We provide crypto social prediction and insights
              for institutional clients and professional traders with
              revolutionary products.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <FooterSvg />
    </Box>
  );
};

export default Footer;
