import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { Toaster } from "react-hot-toast";
import { lightTheme } from "./utils/theme/light";
import { darkTheme } from "./utils/theme/dark";
import { RootState } from "./features/store";
import { RoutesWrapper } from "./services/RoutesWrapper";
import { mainRoutes } from "./routers/Routers";
import AppLayout from "./layout/appLayout";
import "swiper/css";
import "swiper/css/autoplay";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
interface Route {
  pathName: string;
  element: JSX.Element;
}

const clientID =
  "473253075325-n1sphdjstpmtj9lcn8eu33dqbb7mt58n.apps.googleusercontent.com";

function App() {
  const themeMode = useSelector((state: RootState) => state.app.theme);

  return (
    <GoogleOAuthProvider clientId={clientID}>
      <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
        <BrowserRouter>
          <AppLayout>
            <RoutesWrapper>
              <Routes>
                {(mainRoutes as Route[]).map((route, index) => (
                  <Route
                    path={route.pathName}
                    element={route.element}
                    key={index}
                  />
                ))}
              </Routes>
              <Toaster
                reverseOrder={false}
                position="bottom-center"
                containerStyle={{ fontFamily: "Poppins" }}
              />
            </RoutesWrapper>
          </AppLayout>
        </BrowserRouter>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
