import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  calculateGuageValue,
  convertData,
  getCoinImage,
} from "../../../utils/functions";
import StatisticsGuage from "../../guage/StatisticsGuage";
import { SignalsProps, CoinType } from "../interfaces";
import styles from "../analytics.module.scss";

const SignalComponent: React.FC<SignalsProps> = ({ data, type, name }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const calculateTechnicalGuageValue = (guageData: any) => {
    if (guageData && guageData !== undefined) {
      let sell = 0;
      let buy = 0;
      let neutral = 0;

      for (let i = 0; i < guageData.length; i++) {
        const action = guageData[i].action;
        if (action === "Sell") {
          sell++;
        } else if (action === "Buy") {
          buy++;
        } else if (action === "Neutral") {
          neutral++;
        }
      }
      const total = Math.round((buy / (sell + buy + neutral)) * 100);
      return {
        sell,
        buy,
        neutral,
        total,
      };
    }
  };

  let coinWithSignals =
    data &&
    (name == "social"
      ? data
          .filter(
            (coin: CoinType) =>
              Object.values(coin.signal).reduce((acc, curr) => acc + curr, 0) >
              10
          )
          .map((coinWithSignal: any) => ({
            coin_id: coinWithSignal.coin_id,
            logo: coinWithSignal.logo,
            label: coinWithSignal.label,
            title: coinWithSignal.title,
            full_id: coinWithSignal.full_id,
            signalValue: calculateGuageValue(
              coinWithSignal.signal[0] ?? 0,
              coinWithSignal.signal[1] ?? 0,
              coinWithSignal.signal.null ?? 0
            ),
            signalLastValue: calculateGuageValue(
              coinWithSignal.last_signal[0] ?? 0,
              coinWithSignal.last_signal[1] ?? 0,
              coinWithSignal.last_signal.null ?? 0
            ),
          }))
          .sort((a: any, b: any) => a.signalValue - b.signalValue)
      : data
          .filter((coin: CoinType) => coin.technical_signals !== undefined)
          .map((coinWithSignal: any) => ({
            coin_id: coinWithSignal.coin_id,
            logo: coinWithSignal.logo,
            label: coinWithSignal.label,
            title: coinWithSignal.title,
            full_id: coinWithSignal.full_id,
            signalValue:
              (Number(
                calculateTechnicalGuageValue(
                  convertData(coinWithSignal.technical_signals)?.oscillators
                )?.total ?? 0
              ) +
                Number(
                  calculateTechnicalGuageValue(
                    convertData(coinWithSignal.technical_signals)
                      ?.moving_averages
                  )?.total ?? 0
                )) /
              2,
            signalLastValue:
              (Number(
                calculateTechnicalGuageValue(
                  convertData(coinWithSignal.technical_signals)?.oscillators
                )?.total ?? 0
              ) +
                Number(
                  calculateTechnicalGuageValue(
                    convertData(coinWithSignal.technical_signals)
                      ?.moving_averages
                  )?.total ?? 0
                )) /
              2,
          }))
          .sort((a: any, b: any) => a.signalValue - b.signalValue));

  return (
    <Box className={styles.signalWrapper}>
      <TableContainer>
        <Table aria-label="signal table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ padding: "0 0 5px" }}>
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  Assets
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ padding: "0 0 5px" }}>
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {name === "social" ? "SPS" : "Signal"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {type === "buy_signal"
              ? coinWithSignals
                  ?.reverse()
                  .slice(0, 5)
                  .map((row: any) => (
                    <TableRow
                      key={row.coin_id}
                      sx={{ cursor: "pointer", "td, th": { border: 0 } }}
                      onClick={() => {
                        navigate(`/prices/${row.full_id}/`, {
                          state: {
                            coin_id: row.coin_id,
                          },
                        });
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Box
                          className={styles.coinInfoWrapper}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: downLg ? "center" : "flex-start",
                          }}
                        >
                          <img
                            src={getCoinImage(row.coin_id)}
                            alt={row.logo}
                            className={styles.coinLogo}
                          />
                          <Box>
                            <Typography
                              variant="caption"
                              component="h2"
                              sx={{ whiteSpace: "nowrap" }}
                              color={theme.palette.text.primary}
                            >
                              {row.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="h3"
                              sx={{ whiteSpace: "nowrap" }}
                              color={theme.palette.text.primary}
                            >
                              {row.title &&
                                row.title[0]?.toUpperCase() +
                                  row.title.slice(1, 5)}
                              {row.title?.length > 5 ? "..." : ""}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <StatisticsGuage
                          value={row.signalValue}
                          lastValue={row.signalLastValue}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              : type === "sell_signal"
              ? coinWithSignals?.slice(0, 5).map((row: any) => (
                  <TableRow
                    key={row.coin_id}
                    sx={{ cursor: "pointer", "td, th": { border: 0 } }}
                    onClick={() => {
                      navigate(`/prices/${row.full_id}/`, {
                        state: {
                          coin_id: row.coin_id,
                        },
                      });
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Box
                        className={styles.coinInfoWrapper}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: downLg ? "center" : "flex-start",
                        }}
                      >
                        <img
                          src={getCoinImage(row.coin_id)}
                          alt={row.logo}
                          className={styles.coinLogo}
                        />
                        <Box>
                          <Typography
                            variant="caption"
                            component="h2"
                            sx={{ whiteSpace: "nowrap" }}
                            color={theme.palette.text.primary}
                          >
                            {row.label}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="h3"
                            sx={{ whiteSpace: "nowrap" }}
                            color={theme.palette.text.primary}
                          >
                            {row.title &&
                              row.title[0]?.toUpperCase() +
                                row.title.slice(1, 5)}
                            {row.title?.length > 5 ? "..." : ""}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <StatisticsGuage
                        value={row.signalValue}
                        lastValue={row.signalLastValue}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SignalComponent;
