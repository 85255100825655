import { Box, Button, ButtonGroup, useTheme } from "@mui/material";
import styles from "../crypto.module.scss";

const CryptoTabsSelector = () => {
  const theme = useTheme();

  const tabs = [
    "Chart",
    "Performance",
    "Technical Signals",
    "News Websites",
    "Influencers",
    "Exchanges",
  ];

  const tabSelectorHandler = (tab: string) => {
    const element = document.getElementById(tab);
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[700]}`,
      }}
      className={styles.cryptoTabs}
    >
      <ButtonGroup variant="text" aria-label="Crypto Tabs">
        {tabs.map((tab: string) => (
          <Button
            key={tab}
            sx={{
              padding: "4px 18px",
              color: theme.palette.text.primary,
              border: "none !important",
              whiteSpace: "nowrap",
            }}
            onClick={() => tabSelectorHandler(tab)}
          >
            {tab}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default CryptoTabsSelector;
