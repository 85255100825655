import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { BiChevronDown } from "react-icons/bi";
import Loading from "../../../common/loading";
import { WalletProps } from "../../interfaces";
import { wallets } from "../../../../data/wallets";
import {
  fetchWalletBalance,
  fetchWalletTransactions,
  removeWalletHandler,
} from "../../../../api/assets";
import { scrollToTop } from "../../../../utils/functions";
import { convertTransaction } from "../../../../utils/convertTransaction";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../features/store";
import {
  fetchWalletNetworthStatistics,
  fetchWalletsList,
} from "../../../../features/assetsManagement/assetsManagementSlice";
import styles from "../connectWallet.module.scss";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: (theme: any) => theme.palette.common.solidCard,
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const WalletCard: React.FC<WalletProps> = ({ wallet }) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState<any>(null);
  const [transactionsCount, setTransactionsCount] = useState<number>(2);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );

  useEffect(() => {
    setLoading(true);
    const wallet_data = {
      network: "mainnet",
      protocol: wallet.protocol,
      wallet_address: wallet.address,
    };

    fetchWalletBalance(wallet_data).then((balanceResponse) => {
      fetchWalletTransactions(wallet_data).then((transactionsResponse) => {
        setLoading(false);

        if (!balanceResponse.error) {
          setWalletData({
            ...walletData,
            assets: balanceResponse.map((asset: any) => ({
              symbol: asset.currency.symbol,
              balance:
                Number(asset.confirmed_balance) /
                Math.pow(10, asset.currency.decimals ?? 0),
            })),
            transactions: transactionsResponse.data,
          });
        }
      });
    });
  }, []);

  const disconnectWalletHandler = (id: number) => {
    setLoading(true);
    removeWalletHandler(id).then((response) => {
      if (response.status == 200) {
        const root = document.getElementById("root_wrapper");

        setLoading(false);
        dispatch(fetchWalletsList()).then((response) => {
          toast.success("Wallet successfully disconnected");
          scrollToTop();
        });
      }
    });
  };

  const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.text.primary,
      background: theme.palette.background.default,
    },
  }));

  const tansactionCard = (transaction: any) => {
    return (
      <Box className={styles.walletTransactionCard}>
        <Typography
          variant="body2"
          className={styles.walletTransactionCard_value}
          sx={{
            color:
              transaction.type === "Receive"
                ? theme.palette.success.main
                : theme.palette.error.main,
          }}
        >
          {transaction.value} {transaction.asset.slice(0, 20)}
        </Typography>
        <Box sx={{ flex: 2 }}>
          <Typography variant="body2">From:</Typography>
          <TooltipComponent title={transaction.from}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 100,
              }}
            >
              {transaction.from.slice(0, 20) +
                "..." +
                transaction.from.slice(-20)}
            </Typography>
          </TooltipComponent>
        </Box>
        <Box sx={{ flex: 2 }}>
          <Typography variant="body2">To:</Typography>
          <TooltipComponent title={transaction.to}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 100,
              }}
            >
              {transaction.to.slice(0, 20) + "..." + transaction.to.slice(-20)}
            </Typography>
          </TooltipComponent>
        </Box>
        <Typography
          variant="body2"
          className={styles.walletTransactionCard_date}
        >
          {transaction.date}
        </Typography>
      </Box>
    );
  };

  const walletImage = (walletItem: any) => {
    const image = wallets.find(
      (wallet: any) => wallet.name === walletItem.name
    )?.imageUrl;
    return image;
  };

  return (
    <Box
      mt={3}
      sx={{ background: theme.palette.background.paper }}
      className={`default-card-style ${styles.walletCard}`}
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <>
          <Box className={styles.walletCardHeader}>
            <Box>
              <Typography component="h3" variant="h6">
                <img
                  src={walletImage(wallet)}
                  width={43}
                  style={{ borderRadius: "10px" }}
                />
                {wallet.name}
              </Typography>
              <Typography variant="body2">{wallet.address}</Typography>
            </Box>
            {walletData &&
              walletData.assets.map((asset: any, idx: number) => (
                <Box
                  sx={{ background: theme.palette.background.paper }}
                  key={idx}
                >
                  <Typography variant="body1">{asset.symbol}</Typography>
                  <Typography variant="h6">{asset.balance}</Typography>
                </Box>
              ))}
          </Box>

          {walletData?.transactions.length > 0 && (
            <Accordion
              defaultExpanded
              sx={{
                background: "transparent",
                boxShadow: "none",
                width: "100%",
                mt: 2,
                "&:before": {
                  background: "transparent",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <BiChevronDown
                    size={30}
                    style={{ color: theme.palette.common.white }}
                  />
                }
                aria-controls="Transactions-content"
                id="Transactions-header"
                sx={{
                  padding: 0,
                  margin: 0,
                  borderBottom: "1px solid #555",
                  color: theme.palette.common.white,
                }}
              >
                Transactions:
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "1rem 0",
                }}
              >
                {convertTransaction(walletData.transactions, wallet.address)
                  .slice(0, transactionsCount)
                  .map((transaction: any, idx: number) => (
                    <React.Fragment key={idx}>
                      {tansactionCard(transaction)}
                    </React.Fragment>
                  ))}
                {walletData?.transactions.length > transactionsCount ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    onClick={() =>
                      setTransactionsCount(walletData?.transactions.length)
                    }
                  >
                    <Button
                      sx={{
                        color: (theme: any) =>
                          theme.palette.common.greenToWhite,
                      }}
                    >
                      View More Transactions
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    onClick={() => setTransactionsCount(2)}
                  >
                    <Button
                      sx={{
                        color: (theme: any) =>
                          theme.palette.common.greenToWhite,
                      }}
                    >
                      View Less
                    </Button>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Typography
              variant="caption"
              color={(theme: any) => theme.palette.common.mute}
            >
              Connected at: {moment(wallet.created_at).format("YYYY/MM/DD")}
            </Typography>

            <Button variant="outlined" color="error" onClick={handleOpen}>
              Disconnect
            </Button>

            {open && (
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="body1"
                    component="h2"
                  >
                    By disconnecting this wallet, all the data of your assets
                    will be deleted without the possibility of returning the
                    history
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ ml: 1 }}
                      onClick={() => disconnectWalletHandler(wallet.id)}
                    >
                      Disconnect
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default WalletCard;
