import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import SubPagesLayout from "../../layout/subPagesLayout";
import CardTitle from "../../components/common/title/CardTitle";
import { FaqType, SectionType } from "./interfaces";

const FAQ = () => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const faqs = [
    {
      name: "General",
      data: [
        {
          id: 1,
          title: "What is Coinfident?",
          content: `Coinfident is a cryptocurrency social listening dashboard that provides real-time insights into the crypto market.<br />It offers a comprehensive view of market sentiment, news, influencer opinions, and live cryptocurrency prices to help you make informed decisions.`,
        },
        {
          id: 2,
          title: "How can Coinfident help me in the crypto market?",
          content:
            "Coinfident simplifies the complexities of the crypto market by offering easy-to-understand sentiment analysis.<br />It helps you track trends, discover potential opportunities, and stay updated with the latest news and price changes, making it an essential tool for crypto enthusiasts, investors, and traders.",
        },
        {
          id: 3,
          title: "Is Coinfident's information reliable?",
          content:
            "Yes, Coinfident aggregates data from reputable sources and utilizes advanced sentiment analysis algorithms to provide you with reliable and up-to-date information.<br />However, always remember that cryptocurrency investments carry risks, so it's crucial to conduct your research.",
        },
        {
          id: 4,
          title: "How often is the app updated?",
          content:
            "Coinfident continuously updates its data to provide real-time information.<br />You can expect regular updates throughout the day to ensure you have the latest insights into the crypto market.",
        },
        {
          id: 5,
          title: "Is Coinfident available on multiple platforms?",
          content:
            "Currently, Coinfident is available as a mobile app for both iOS and Android devices.<br />You can download it from the respective app stores for a seamless user experience.",
        },
        {
          id: 6,
          title: "Do I need to create an account to use Coinfident?",
          content:
            "Yes, You need to create an account in order to access Coinfident's features.<br />Creating an account allows you to personalize your experience, save your preferences, and access additional features.",
        },
        {
          id: 7,
          title: "Is Coinfident's data secure?",
          content:
            "Yes, we take the security of your data seriously.<br />Coinfident uses industry-standard security measures to protect your information and ensures that your personal data remains confidential.",
        },
        {
          id: 8,
          title:
            "How can I contact Coinfident support if I have questions or encounter issues?",
          content:
            "You can reach our support team by emailing [support@Coinfident.ai].<br />We're here to assist you with any questions, concerns, or technical issues you may encounter while using the app.",
        },
        {
          id: 9,
          title: "Is Coinfident free to use?",
          content:
            "Coinfident offers both free and premium versions.<br />The free version provides access to basic features, while the premium version unlocks additional advanced features and customization options.",
        },
        {
          id: 10,
          title: "Can I trust Coinfident for investment advice?",
          content:
            "Coinfident provides valuable information and insights, but it's essential to remember that it does not offer financial or investment advice.<br />Always do your research and consider consulting a financial advisor before making investment decisions in the cryptocurrency market.",
        },
      ],
    },
    {
      name: "Agents",
      data: [
        {
          id: 11,
          title: "What is the difference between Agents and LLMs?",
          content:
            "Unlike a standard LLM, which simply processes questions based on its pre-trained structure and general information, the Coinfident agent enhances its capabilities by connecting to a coinfident database. This allows the agent to fetch and utilize data relevant to the user's inquiries, facilitating more tailored and actionable responses.",
        },
      ],
    },
  ];

  return (
    <SubPagesLayout title="Coinfident | FAQ">
      <Box mb={10}>
        <Box sx={{ margin: "1rem auto 3rem" }}>
          <CardTitle isCenter>FAQ</CardTitle>
        </Box>
        {faqs.map((section: SectionType) => (
          <Box key={section.name} mb={5}>
            <Typography variant="h4" mb={1}>
              {section.name}
            </Typography>
            {section.data.map((faq: FaqType) => (
              <Accordion
                key={faq.id}
                expanded={expanded === `panel${faq.id}`}
                onChange={handleChange(`panel${faq.id}`)}
                sx={{
                  marginBottom: ".6rem",
                  borderRadius: "5px",
                  boxShadow: "0 0 8px rgba(0,0,0,.2)",

                  "&:before": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<MdKeyboardArrowDown />}
                  aria-controls={`panel${faq.id}-content`}
                  id={`panel${faq.id}-header`}
                  sx={{ border: "none" }}
                >
                  <Typography
                    variant="body2"
                    component="h2"
                    color={theme.palette.text.primary}
                  >
                    {faq.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="caption"
                    component="p"
                    sx={{ fontWeight: 100, maxWidth: "95%" }}
                    color={theme.palette.text.primary}
                    dangerouslySetInnerHTML={{
                      __html: faq.content,
                    }}
                  ></Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ))}
      </Box>
    </SubPagesLayout>
  );
};

export default FAQ;
