import { useMemo } from "react";
import GaugeComponent from "react-gauge-component";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";
import styles from "./guage.module.scss";

const StatisticsGuage: React.FC<{
  value: number;
  lastValue: number;
  hasBackground?: boolean;
  isEmpty?: boolean;
}> = ({ value, lastValue, hasBackground, isEmpty }) => {
  const theme = useTheme();

  const startAngle = useMemo(
    () =>
      lastValue < value
        ? (lastValue / 100) * (90 - -90) + -90
        : (value / 100) * (90 - -90) + -90,
    [lastValue, value]
  );

  const endAngle = useMemo(
    () =>
      lastValue < value
        ? (value / 100) * (90 - -90) + -90
        : (lastValue / 100) * (90 - -90) + -90,
    [lastValue, value]
  );

  const changePrice = useMemo(
    () => Math.abs(Math.round(lastValue - value)),
    [lastValue, value]
  );

  return (
    <Box className={styles.statisticsGaugeWrapper}>
      <Box sx={{ position: "relative", mb: 0.5 }}>
        <GaugeComponent
          style={{
            width: 80,
            height: 35,
            position: "relative",
            zIndex: 1,
          }}
          type="semicircle"
          value={value ?? 0}
          arc={{
            colorArray: isEmpty
              ? [theme.palette.grey["700"]]
              : ["#FA637A", "#CDD931", "#259659"],
            padding: 0,
            width: 0.18,
            gradient: true,
          }}
          pointer={{
            animate: false,
            length: isEmpty ? 0 : 0.6,
            width: isEmpty ? 0 : 10,
            color: hasBackground
              ? theme.palette.common.white
              : theme.palette.text.primary,
          }}
          labels={{
            valueLabel: {
              hide: true,
            },
            tickLabels: {
              hideMinMax: true,
            },
          }}
        />

        <Gauge
          margin={{ left: 0, bottom: 0, right: 0, top: 0 }}
          cx={30.5}
          cy={45}
          sx={{
            position: "absolute",
            bottom: -7,
            left: 9,
            [`& .${gaugeClasses.valueArc}`]: {
              fill: lastValue < value ? "#25965990" : "#FA637A90",
            },
          }}
          width={61}
          height={45}
          valueMin={0}
          valueMax={100}
          value={100}
          startAngle={isNaN(startAngle) ? 0 : startAngle}
          endAngle={isNaN(endAngle) ? 0 : endAngle}
          outerRadius={26}
          innerRadius={0}
          text={""}
        />
      </Box>
      <Box className={styles.statisticsValues}>
        <Typography
          align="center"
          sx={{
            fontSize: "10px",
            color: hasBackground
              ? theme.palette.common.white
              : theme.palette.text.primary,
          }}
        >
          {isEmpty ? "No data" : `${value}%`}
        </Typography>
        {isNaN(changePrice) ? (
          ""
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            position="absolute"
            top={0}
            left={value < lastValue ? 0 : "unset"}
            right={lastValue <= value ? 0 : "unset"}
            sx={{
              color: value < lastValue ? "#FA637A" : "#259659",
            }}
          >
            {value < lastValue && (
              <MdOutlineArrowLeft
                style={{ width: "unset", height: "unset", marginTop: "unset" }}
                size={15}
                color="#FA637A"
              />
            )}
            <Typography
              component="span"
              sx={{
                color: value < lastValue ? "#FA637A" : "#259659",
                fontSize: "9px",
              }}
            >
              {isFinite(changePrice) ? changePrice : ""}%
            </Typography>
            {lastValue < value && (
              <MdOutlineArrowRight
                style={{ width: "unset", height: "unset", marginTop: "unset" }}
                size={15}
                color="#259659"
              />
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default StatisticsGuage;
