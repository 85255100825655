import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import toast from "react-hot-toast";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { agentsList } from "../../data/agentsList";
import { RootState } from "../../features/store";
import DashboardLayout from "../../layout/dashboardLayout";
import AgentDrawer from "../../components/agents/agentDrawer";
import AgentHeader from "../../components/agents/agentHeader";
import AgentSuggestions from "../../components/agents/agentSuggestions";
import AgentInput from "../../components/agents/agentInput";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import AgentResponse from "../../components/agents/agentResponse";
import AgentResponsiveList from "../../components/agents/agentResponsiveList";
import { convertAgentName, scrollToTop } from "../../utils/functions";
import useAuth from "../../utils/useAuth";
import {
  fetchAgentHistory,
  fetchAgentResponse,
  fetchSingleAgentHistory,
  setAgentComponentsData,
  setIsModalResponseComplete,
  setIsResponseComplete,
  updateAgentList,
} from "../../features/agent/agentSlice";
import { fetchWalletBalance } from "../../api/assets";
import styles from "./agents.module.scss";

const server_error = `Something went wrong. Please try again later, and if the issue persists, feel free to reach out to our support team.`;
const error = `You've reached the maximum allowed requests for today. Wait for the limit to reset or upgrade your plan to avoid hitting the limit again.`;

const Agents = () => {
  const theme = useTheme();
  const params = useParams();
  const isAuthValid = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const info_ref = useRef<HTMLDivElement>(null);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [file, setFile] = useState(null);
  const [network, setNetwork] = useState("");
  const [query, setQuery] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("Influencers Explorer");

  const agentState: any = useSelector((state: RootState) => state.agent);
  const userState: any = useSelector((state: RootState) => state.account);

  const selectedAgentItem = agentsList.find(
    (agent) => agent.agent_name === selectedAgent
  );

  const scrollToItem = (ref: any) => {
    if (downLg) {
      setTimeout(() => {
        ref?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 100);
    } else {
      ref?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  const submitQueryHandler = (network: any, query: any) => {
    setQuery(query);
    setNetwork(network);
    dispatch(setIsResponseComplete(false));

    if (query !== "") {
      scrollToTop();
      dispatch(
        updateAgentList([
          {
            question: query,
            answer: null,
          },
        ])
      );
    }
  };

  const fetchResponseHandler = async () => {
    const last_question =
      agentState.agentList.length > 0 &&
      agentState.agentList[agentState.agentList.length - 1];

    const request_data = {
      query: last_question?.question,
      agent_type: convertAgentName(selectedAgent),
    };

    if (network) {
      if (query !== "" || network !== "") {
        const wallet_data = {
          network: "mainnet",
          protocol: network,
          wallet_address: query,
        };

        try {
          //@ts-ignore
          const res = await dispatch(fetchAgentResponse(request_data));

          if (res.payload.id) {
            const response = await fetchWalletBalance(wallet_data);

            let additionalMessage;
            if (response.error) {
              additionalMessage = `The address is not valid on this network and`;
            } else {
              additionalMessage = `The address is valid on the selected network and currently contains ${
                Number(response[0]?.confirmed_balance) /
                Math.pow(10, response[0]?.currency?.decimals ?? 0)
              } ${response[0]?.currency?.symbol}.`;
            }

            const bot_response = {
              ...res.payload,
              response_data: `${additionalMessage} ${res.payload.response_data}`,
            };

            return { ...bot_response };
          } else {
            toast.error(
              "You've reached the maximum allowed requests for today."
            );
            return error;
          }
        } catch (error) {
          return {
            response_data:
              "An error occurred while fetching the wallet balance.",
          };
        }
      }
    } else {
      try {
        //@ts-ignore
        const res = await dispatch(fetchAgentResponse(request_data));

        if (res.payload.id) {
          return { ...res.payload };
        } else if (res?.payload.status === 429) {
          toast.error("You've reached the maximum allowed requests for today.");
          return error;
        } else {
          return server_error;
        }
      } catch (error) {
        return {
          response_data: "An error occurred while fetching the agent response.",
        };
      }
    }
  };

  useEffect(() => {
    if (isAuthValid) {
      //@ts-ignore
      dispatch(fetchAgentHistory());
    }
  }, [isAuthValid]);

  useEffect(() => {
    if (agentState.agentList.length > 0) {
      if (params.id) {
        //@ts-ignore
        fetchSingleAgentHistory(params.id).then((response) => {
          dispatch(
            updateAgentList([
              { question: response.user_message, answer: response },
            ])
          );
        });
      } else {
        setTimeout(async () => {
          const lastNullIndex = agentState.agentList
            .map((item: any) => item.answer)
            .lastIndexOf(null);

          const updatedChatList = await Promise.all(
            agentState.agentList.map(async (item: any, index: number) => {
              if (index === lastNullIndex) {
                const answer = await fetchResponseHandler();

                return {
                  ...item,
                  answer: answer,
                };
              }
              return item;
            })
          );

          dispatch(setIsModalResponseComplete(false));
          dispatch(setIsResponseComplete(false));
          dispatch(updateAgentList(updatedChatList));
        }, 3);
      }
    }
  }, [agentState.agentList.length, params.id]);

  useEffect(() => {
    return () => {
      setSelectedAgent("Influencers Explorer");
      dispatch(
        setAgentComponentsData({
          signal: null,
          top_influencers: null,
          tweets: null,
        })
      );

      dispatch(updateAgentList([]));
    };
  }, []);

  return (
    <DashboardLayout title="Coinfident | Agents" description="Agents">
      <AgentDrawer
        selectedAgent={selectedAgent}
        setSelectedAgent={setSelectedAgent}
        history={agentState.agentHistory}
        scrollToItem={scrollToItem}
        info_ref={info_ref}
      />

      <Container maxWidth="xl" className={styles.agent_wrapper}>
        {agentState.agentList.length > 0 ? (
          <Box className={styles.agent_response_wrapper}>
            <AgentResponse
              isHistory={params.id ? true : false}
              agentType={selectedAgent}
            />
          </Box>
        ) : (
          <>
            <AgentHeader />
            {downLg && (
              <AgentResponsiveList
                selectedAgent={selectedAgent}
                setSelectedAgent={setSelectedAgent}
              />
            )}

            {selectedAgent && (
              <Box ref={info_ref} sx={{ pt: 1, transition: "all .4s" }}>
                <Box
                  className={styles.agentInfo}
                  sx={{
                    background: (theme: any) => theme.palette.common.agentBg,
                  }}
                >
                  <Fade key={selectedAgent} triggerOnce>
                    <>
                      <Box className={styles.agentInfoHeader}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={selectedAgentItem?.icon}
                            width={55}
                            height={55}
                          />
                          <Typography
                            variant="h6"
                            component="h4"
                            color={theme.palette.text.primary}
                          >
                            {selectedAgentItem?.title}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={styles.agentInfoContent}>
                        <Box flex={3}>
                          <Box
                            color={theme.palette.text.primary}
                            className={styles.agentInfoText}
                            pr={18}
                            mb={2}
                            dangerouslySetInnerHTML={{
                              __html: `${selectedAgentItem?.description}`,
                            }}
                          ></Box>
                        </Box>
                        {!downLg && (
                          <img
                            style={{ flex: 1.5, width: 150 }}
                            src={selectedAgentItem?.image}
                          />
                        )}
                      </Box>

                      {userState?.data?.user?.email ? (
                        selectedAgentItem?.type === "Free" && (
                          <>
                            <Box mt={5}>
                              <AgentSuggestions
                                selectedAgentItem={selectedAgentItem}
                                navigatePageHandler={submitQueryHandler}
                              />
                            </Box>

                            <Box mt={2}>
                              <AgentInput
                                file={file}
                                setFile={setFile}
                                selectedAgentItem={selectedAgentItem}
                                submitQueryHandler={submitQueryHandler}
                              />
                            </Box>
                          </>
                        )
                      ) : (
                        <Box
                          mt={8}
                          mb={3}
                          display="flex"
                          justifyContent="center"
                        >
                          <PrimaryButton
                            text="LOGIN TO USE OUR AGENTS"
                            size="large"
                            linkUrl={`/login${`?redirect=${location.pathname.slice(
                              1
                            )}`}`}
                            isFullWidth={downLg ? true : false}
                          />
                        </Box>
                      )}
                    </>
                  </Fade>
                </Box>
              </Box>
            )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default Agents;
