import { useNavigate } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import { BiChevronRight } from "react-icons/bi";
import { ViewMoreButtonProps } from "../interfaces";
import styles from "./buttons.module.scss";

const ViewMoreButton: React.FC<ViewMoreButtonProps> = ({ link }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      className={styles.view_more}
      sx={{
        background: (theme: any) => theme.palette.common.darkPrimary,
      }}
    >
      <Button
        size="small"
        variant="text"
        fullWidth
        sx={{
          color: theme.palette.common.white,
        }}
        onClick={() => {
          link && navigate(link);
        }}
      >
        View More
        <BiChevronRight size={15} />
      </Button>
    </Box>
  );
};

export default ViewMoreButton;
