import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import toast from "react-hot-toast";
import { Slide, Fade } from "react-awesome-reveal";
import { MdOutlineChevronRight, MdOutlineContentCopy } from "react-icons/md";
import { BiDislike, BiLike } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { PiSpeedometerLight } from "react-icons/pi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbChartLine, TbMessage2 } from "react-icons/tb";
import AgentComponent from "./agentComponent";
import ChatInput from "../chatInput";
import TypeWriter from "../typeWriter";
import { ComponentTabProps } from "../interfaces";
import useAuth from "../../../utils/useAuth";
import { RootState } from "../../../features/store";
import {
  setIsModalResponseComplete,
  setIsResponseComplete,
  updateAgentList,
  updateModalAgentList,
} from "../../../features/agent/agentSlice";
import { fetchCoinsStatistics } from "../../../features/crypto/cryptosSlice";
import AgentLoading from "../../../assets/images/shapes/agentLoading.gif";
import SkeletonLoading from "../../common/SkeletonLoading";
import AgentCustomChart from "./agentCustomChart";
import styles from "../agents.module.scss";
const { CopyToClipboard } = require("react-copy-to-clipboard");

const AgentResponse = ({
  isModal,
  isHistory,
  agentType,
  question_id,
}: {
  isModal?: boolean;
  isHistory?: boolean;
  agentType?: string;
  question_id?: string | number;
}) => {
  const theme = useTheme();
  const actions_ref = useRef();
  const response_ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthValid = useAuth();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const agentState: any = useSelector((state: RootState) => state.agent);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const state = isModal ? agentState.modalAgentList : agentState.agentList;

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isDisliked, setIsDisliked] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: downLg ? 330 : 450,
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    bgcolor: theme.palette.background.default,
    border: `2px solid ${theme.palette.text.primary}`,
  };

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const hasSidebarComponent =
    agentType === "Influencers Explorer" ||
    agentType === "Coinalysis" ||
    agentType === "Model Maker";

  const hasCustomChart =
    agentType === "Model Maker" || agentType === "Trade 360";

  const isSingleChat = agentType === "Chain Guardian";

  let isResponseTypingComplete = isModal
    ? agentState.modalIsResponseComplete
    : agentState.isResponseComplete;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isAuthValid) {
      if (!cryptosState.coinsStatistics) {
        //@ts-ignore
        dispatch(fetchCoinsStatistics(1));
      }
    }
  }, [isAuthValid]);

  function CustomTabPanel(props: ComponentTabProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && children}
      </Box>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const addNewQuestionHandler = (query: string) => {
    // setLoading(true);

    if (isModal) {
      dispatch(setIsModalResponseComplete(false));

      dispatch(
        updateModalAgentList([
          ...agentState.modalAgentList,
          {
            question: query,
            answer: null,
          },
        ])
      );
    } else {
      dispatch(setIsResponseComplete(false));

      dispatch(
        updateAgentList([
          ...agentState.agentList,
          {
            question: query,
            answer: null,
          },
        ])
      );
    }

    if (response_ref.current) {
      //@ts-ignore
      response_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const submitQueryHandler = (network: any, query: any) => {
    addNewQuestionHandler(query);
  };

  const responseTypingHandler = () => {
    //   if (isModal) {
    //     dispatch(setIsModalResponseComplete(true));
    //   } else {
    //     dispatch(setIsResponseComplete(true));
    //   }
  };

  const getUniqueTypes = (data: any) => {
    const allTypes = data?.flatMap((item: any) => item.answer?.type);
    //@ts-ignore
    const uniqueTypes = [...new Set(allTypes)];
    const hasValidType = ["top_influencers", "sps", "top_tweets", "price"].some(
      (type) => uniqueTypes.includes(type)
    );

    return hasValidType ? uniqueTypes : [];
  };

  const handleClose = () => setOpenErrorModal(false);

  const ErrorComponent = (errorText: string) => {
    return (
      <Box sx={{ my: 3 }}>
        <Typography color={theme.palette.text.primary} sx={{ fontWeight: 100 }}>
          {errorText}
        </Typography>
        {errorText.includes(
          "You've reached the maximum allowed requests for today"
        ) && (
          <>
            <Modal open={openErrorModal} onClose={handleClose}>
              <Box sx={style}>
                <Typography variant="h6" mb={2}>
                  Maximum allowed requests for today
                </Typography>
                <Typography variant="body1" component="h2">
                  {errorText}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button variant="contained" sx={{ p: 0, mt: 3 }} size="large">
                    <Link
                      to="/subscription"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: ".3rem 1rem",
                        color: theme.palette.text.primary,
                      }}
                    >
                      See our pricing plans
                      <MdOutlineChevronRight
                        size={18}
                        style={{ marginLeft: ".4rem" }}
                      />
                    </Link>
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Button variant="contained" sx={{ p: 0, mt: 1 }} size="large">
              <Link
                to="/subscription"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: ".3rem 1rem",
                  color: theme.palette.text.primary,
                }}
              >
                See our pricing plans
                <MdOutlineChevronRight
                  size={18}
                  style={{ marginLeft: ".4rem" }}
                />
              </Link>
            </Button>
          </>
        )}
      </Box>
    );
  };

  const TabComponent = (icon: any, props_id: number, tab_name: string) => {
    if (state[state.length - 1]?.answer.type?.includes(tab_name)) {
      return (
        <Tab
          className={styles.componentsTab}
          label={icon}
          {...a11yProps(props_id)}
        />
      );
    } else {
      return "";
    }
  };

  return (
    <Grid container className={styles.response_container}>
      <Grid
        item
        lg={6}
        xs={12}
        sx={{ minHeight: "500px" }}
        className={styles.response_content_container}
      >
        {state.map((chatItem: any, idx: number) => (
          <Box
            sx={{
              flex: idx === state.length - 1 ? 1 : 0,
              display: "flex",
              flexDirection: "column",
            }}
            key={idx}
            mt={idx !== 0 ? 7 : 0}
          >
            <Fade triggerOnce>
              <Typography
                variant="h6"
                mb={2}
                color={theme.palette.text.primary}
              >
                {chatItem.answer?.agent_type === "ChainGaurdian" &&
                  "Check this wallet address: "}
                {chatItem.question
                  ? chatItem.question
                  : chatItem.answer?.user_message}
              </Typography>
            </Fade>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                ref={response_ref}
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {!chatItem.answer ? (
                  <img
                    src={AgentLoading}
                    alt="Agent Loading"
                    width={300}
                    height={300}
                  />
                ) : typeof chatItem.answer === "string" ? (
                  ErrorComponent(chatItem.answer)
                ) : (
                  chatItem.answer && (
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex !important",
                        flexDirection: "column",
                        minHeight:
                          !downLg &&
                          state[state.length - 1]?.answer?.coin_name !== null &&
                          state[state.length - 1]?.answer?.type?.length > 0
                            ? "740px"
                            : "auto",
                      }}
                    >
                      <Box>
                        <TypeWriter
                          text={chatItem.answer.response_data}
                          delay={10}
                          isTyping={!isResponseTypingComplete}
                          responseTypingHandler={responseTypingHandler}
                        />

                        {chatItem.answer.sps_chart?.datetime?.length > 0 && (
                          <AgentCustomChart
                            type="SPS Chart"
                            data={chatItem.answer.sps_chart}
                          />
                        )}

                        {chatItem.answer.price_chart?.datetime?.length > 0 && (
                          <AgentCustomChart
                            type="Price Chart"
                            data={chatItem.answer.price_chart}
                          />
                        )}

                        {chatItem.answer.infs_chart?.datetime?.length > 0 && (
                          <AgentCustomChart
                            type="Infs Chart"
                            data={chatItem.answer.infs_chart}
                          />
                        )}

                        <Box my={3}>
                          {downLg &&
                            hasSidebarComponent &&
                            chatItem.answer?.coin_name &&
                            idx === agentState.agentList.length - 1 && (
                              <>
                                {loading ? (
                                  <Box
                                    sx={{
                                      borderRadius: "8px",
                                      background: (theme: any) =>
                                        theme.palette.mode === "dark"
                                          ? theme.palette.common.darkPrimary
                                          : theme.palette.common.agentModalBg,
                                    }}
                                  >
                                    <SkeletonLoading />
                                  </Box>
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="agent-components-tabs"
                                        className={styles.componentsTabsWrapper}
                                      >
                                        {TabComponent(
                                          <TbChartLine
                                            size={24}
                                            color={theme.palette.primary.main}
                                          />,
                                          0,
                                          "price"
                                        )}

                                        {TabComponent(
                                          <HiOutlineUserGroup
                                            size={24}
                                            color={theme.palette.primary.main}
                                          />,
                                          1,
                                          "top_influencers"
                                        )}

                                        {TabComponent(
                                          <TbMessage2
                                            size={24}
                                            color={theme.palette.primary.main}
                                          />,
                                          2,
                                          "top_tweets"
                                        )}

                                        {TabComponent(
                                          <PiSpeedometerLight
                                            size={24}
                                            color={theme.palette.primary.main}
                                          />,
                                          3,
                                          "sps"
                                        )}
                                      </Tabs>
                                    </Box>

                                    <Typography
                                      variant="body2"
                                      mt={2}
                                      align="left"
                                      color={theme.palette.text.primary}
                                    >
                                      These analyses will provide you with a
                                      better understanding of the overall market
                                      of the coin you mentioned.
                                    </Typography>
                                    {getUniqueTypes(state).map(
                                      (component: any, idx: number) => (
                                        <CustomTabPanel
                                          value={value}
                                          index={idx}
                                          key={idx}
                                        >
                                          <AgentComponent
                                            isModal={isModal}
                                            styles={styles}
                                            type={component}
                                            coin={chatItem.answer?.coin_name}
                                            start_date={
                                              chatItem.answer.start_date
                                            }
                                            end_date={chatItem.answer.end_date}
                                          />
                                        </CustomTabPanel>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            )}
                        </Box>
                        {idx === state.length - 1 && (
                          <Box className={styles.actions} ref={actions_ref}>
                            <CopyToClipboard
                              text={chatItem.answer.response_data}
                            >
                              <IconButton
                                size="small"
                                sx={{ padding: ".5rem" }}
                                onClick={() =>
                                  toast.success(
                                    "The response has been copied to your clipboard"
                                  )
                                }
                              >
                                <MdOutlineContentCopy />
                              </IconButton>
                            </CopyToClipboard>
                            {!isLiked && !isDisliked && (
                              <>
                                <IconButton
                                  size="small"
                                  sx={{ padding: ".5rem" }}
                                  onClick={() => setIsLiked(!isLiked)}
                                >
                                  <BiLike
                                    color={
                                      isLiked
                                        ? theme.palette.primary.main
                                        : theme.palette.text.primary
                                    }
                                  />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  sx={{ padding: ".5rem" }}
                                  onClick={() => setIsDisliked(!isDisliked)}
                                >
                                  <BiDislike
                                    color={
                                      isDisliked
                                        ? theme.palette.primary.main
                                        : theme.palette.text.primary
                                    }
                                  />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                      {!isHistory &&
                        idx === state.length - 1 &&
                        !isSingleChat && (
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex !important",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Fade triggerOnce>
                              <Box className={styles.agentResponseSuggestions}>
                                <Box className={styles.agentSuggestions}>
                                  {chatItem.answer?.related_queries?.map(
                                    (query: any) => (
                                      <Box
                                        key={query}
                                        onClick={() =>
                                          addNewQuestionHandler(query)
                                        }
                                      >
                                        <Typography
                                          align="left"
                                          variant="body2"
                                          color={theme.palette.text.primary}
                                        >
                                          {query}
                                        </Typography>
                                        {!downLg && (
                                          <FiExternalLink
                                            size={20}
                                            color={theme.palette.text.primary}
                                          />
                                        )}
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </Box>
                            </Fade>
                          </Box>
                        )}
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Box>
        ))}
        {!isHistory &&
          (isSingleChat ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                if (isModal) {
                  dispatch(updateModalAgentList([]));
                } else {
                  navigate(`/agents`, {
                    replace: true,
                  });
                }
              }}
            >
              Try With Another Wallet Address
            </Button>
          ) : (
            <Slide direction="down" triggerOnce>
              <Box
                className={`${styles.agentInputWrapper} ${styles.agentLargeInputWrapper}`}
              >
                <ChatInput
                  isActive={!loading ? true : false}
                  isAgent
                  isLight
                  placeholder="Ask Your Question"
                  selectedAgent=""
                  submitQueryHandler={submitQueryHandler}
                />
              </Box>
            </Slide>
          ))}
      </Grid>
      {!downLg &&
        state[state.length - 1]?.answer?.coin_name !== null &&
        state[state.length - 1]?.answer?.type?.length > 0 && (
          <Grid
            item
            lg={6}
            xs={12}
            mt={7}
            className={styles.response_component_container}
          >
            <Box
              className={`customScrollbar ${scrollbarClassName} ${styles.sidebar_component}`}
              sx={{
                height: "740px",
                overflowY: "auto",
                padding: "0 1rem",
                background: (theme: any) => theme.palette.common.agentBg,
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="body1"
                mt={2}
                align="center"
                color={theme.palette.text.primary}
              >
                These analyses will provide you with a better understanding of
                the overall market of the coin you mentioned.
              </Typography>
              {loading
                ? Array.from({
                    length: state[state.length - 1]?.answer.type.length,
                  }).map((_, index) => <SkeletonLoading key={index} />)
                : state[state.length - 1]?.answer.type.map(
                    (component: any, idx: number) => (
                      <AgentComponent
                        key={idx}
                        isModal={isModal}
                        styles={styles}
                        type={component}
                        coin={state[state.length - 1]?.answer?.coin_name}
                        start_date={state[state.length - 1]?.answer?.start_date}
                        end_date={state[state.length - 1]?.answer?.end_date}
                      />
                    )
                  )}
            </Box>
          </Grid>
        )}
    </Grid>
  );
};

export default AgentResponse;
