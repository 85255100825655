import React from "react";
import Select, { components, ControlProps } from "react-select";
import { Grid, Typography, useTheme } from "@mui/material";
import { SelectProps } from "./interfaces";

const SelectBox: React.FC<SelectProps> = ({
  selectItems,
  label,
  formik,
  name,
  helpText,
  hasInlineLabel = false,
  hasInnerLabel = true,
  disabled,
  icon,
  titleIcon,
  isEmpty,
  isMulti = false,
  hasBorder = false,
  radius = 0,
  inputColor,
}) => {
  const theme = useTheme();

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 1000,
      background: theme.palette.background.default,
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: isEmpty && "#e6f5ff",
      maxWidth: "100%",
      fontWeight: 100,
      fontSize: "12px",
      border: hasBorder ? `1px solid ${theme.palette.grey[600]}` : "none",
      borderBottom: hasBorder
        ? `1px solid ${theme.palette.grey[600]}`
        : `1px solid ${theme.palette.grey[800]} `,
      borderRadius: `${radius}px`,
    }),
    indicatorsContainer: (baseStyles: any) => ({
      ...baseStyles,
      display: "none",
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      color: theme.palette.text.primary,
      fontWeight: 100,
    }),
    option: (baseStyles: any) => ({
      ...baseStyles,
      background: "transparent",
      transition: "all .3s",
      cursor: "pointer",
      "&:hover": {
        background: (theme: any) =>
          theme.palette.mode === "dark"
            ? theme.palette.common.darkPrimary
            : theme.palette.primary.light,
      },
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
      color: inputColor ? inputColor : theme.palette.text.primary,
    }),
  };

  const Control = ({ children, ...props }: ControlProps<any, false>) => {
    // @ts-ignore
    const style = {
      cursor: "pointer",
      padding: ".5rem",
      paddingRight: ".1rem",
      zIndex: 10,
      display: "flex",
      alignItems: "center",
    };

    return (
      <components.Control {...props}>
        <span style={style}>{icon}</span>
        {children}
      </components.Control>
    );
  };

  return (
    <Grid container>
      {hasInlineLabel ? (
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: { xs: ".3rem !important" },
            display: "flex",
            alignItems: "center",
          }}
        >
          {titleIcon ? titleIcon : ""}
          <Typography variant="body2" color={theme.palette.text.primary}>
            {label}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={12} className="react-select-container">
        <Select
          // @ts-ignore
          components={{ Control }}
          value={formik.values[name]}
          options={selectItems}
          name={name}
          placeholder={hasInnerLabel ? label : ""}
          onChange={(selectValue: any) =>
            formik.setFieldValue([name], selectValue)
          }
          isDisabled={disabled === true ? true : false}
          styles={customStyles}
          isOptionDisabled={(option: any) => option.disabled}
          onBlur={() => {
            formik.setFieldTouched([name]);
          }}
          // @ts-ignore
          isMulti={isMulti}
          isClearable
        />
        <Typography variant="caption">{helpText}</Typography>
        {formik.touched[name] && formik.errors[name] && (
          <Typography className="formik-error" color={theme.palette.error.main}>
            {formik.errors[name]
              ? formik.errors[name]
              : formik.errors[name].value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SelectBox;
