import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useTheme,
  Popover,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { FiSearch } from "react-icons/fi";
import { BiChevronDown } from "react-icons/bi";
import Loading from "../../common/loading";
import Input from "../../form/Input";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { setAnalyticsSelectedCoin } from "../../../features/crypto/cryptoSlice";
import { getCoinImage } from "../../../utils/functions";
import SelectBox from "../../form/SelectBox";
import { CoinType, CryptoListState } from "../interfaces";
import LoginAlertModal from "../../common/loginAlertModal";
import styles from "./cryptoList.module.scss";

export default function CryptoList({
  coinsSelectorHandler,
  hasInput,
  type,
  isAnalytics,
}: any) {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const accountState: any = useSelector((state: RootState) => state.account);

  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filteredCoins, setFilteredCoins] = useState<any | null>(null);
  const [initialValues, setInitialValues] = useState<CryptoListState>({
    coin: "",
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const submitUserDataHandler = () => async () => {};

  useEffect(() => {
    if (cryptosState?.availableCoins) {
      let coinsList = [...cryptosState.availableCoins];
      if (initialValues.coin === "") {
        setFilteredCoins(cryptosState.availableCoins?.slice(0, 9));
      } else {
        const filteredCoins = coinsList.filter((coin: any) => {
          const titleLowercase = coin.name.toLowerCase();
          const labelLowercase = coin.code.toLowerCase();
          const inputValueLowercase = initialValues.coin.toLowerCase();

          return (
            titleLowercase.includes(inputValueLowercase) ||
            labelLowercase.includes(inputValueLowercase)
          );
        });
        setFilteredCoins(filteredCoins.slice(0, 9));
      }
    }
  }, [initialValues.coin, cryptosState.availableCoins]);

  const handleCoinClick = (coin: CoinType) => {
    if (accountState.data?.user?.email) {
      dispatch(setAnalyticsSelectedCoin(coin.id));
      coinsSelectorHandler(coin.id);
      handleClose();
    } else {
      const accessibleCoins = cryptosState.availableCoins
        ?.filter((coin: any, idx: number) => idx < 5)
        ?.map((coin: any) => coin.id);

      if (accessibleCoins?.includes(coin.id)) {
        dispatch(setAnalyticsSelectedCoin(coin.id));
        coinsSelectorHandler(coin.id);
        handleClose();
      } else {
        setLoginAlertModal(true);
      }
    }
  };

  return (
    <Box>
      {type !== "coins_list" &&
        (hasInput ? (
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submitUserDataHandler()}
          >
            {(formik) => (
              <Form id="coin_list_form">
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <SelectBox
                      selectItems={
                        cryptosState.availableCoins
                          ? cryptosState.availableCoins?.map((coin: any) => ({
                              value: coin.id,
                              label: (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                  onClick={() => coinsSelectorHandler(coin.id)}
                                >
                                  <img
                                    src={getCoinImage(coin.id)}
                                    width={15}
                                    height={15}
                                  />
                                  <Typography
                                    color={theme.palette.text.primary}
                                    sx={{ fontWeight: 300, fontSize: "11px" }}
                                    ml={1}
                                  >
                                    {coin.name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={theme.palette.text.primary}
                                    sx={{ fontWeight: 100, fontSize: "9px" }}
                                    ml={1}
                                  >
                                    {coin.code}
                                  </Typography>
                                </Box>
                              ),
                            }))
                          : []
                      }
                      disabled={cryptosState.availableCoinsLoading}
                      name="crypto"
                      formik={formik}
                      label="Search any asset to add to your watchlist"
                      hasInlineLabel={false}
                      hasInnerLabel={true}
                      icon={<FiSearch color={theme.palette.text.primary} />}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <Button
            className={styles.cryptoListButton}
            aria-describedby={id}
            variant="text"
            onClick={handleClick}
            size={isAnalytics ? "large" : "small"}
            sx={{
              padding: isAnalytics ? ".5rem 1rem" : 0,
              fontSize: isAnalytics ? "14px" : "11.5px",
              background: isAnalytics
                ? (theme: any) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.common.agentBg
                      : theme.palette.common.whiteToGreen
                : "transparent",
              color:
                type === "asset"
                  ? theme.palette.text.primary
                  : theme.palette.common.white,

              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main,
              },
            }}
          >
            <img
              src={getCoinImage(cryptoState.analyticsSelectedCoin)}
              style={{
                width: isAnalytics ? "23px" : "16px",
                height: isAnalytics ? "23px" : "16px",
                marginRight: isAnalytics ? "8px" : "5px",
              }}
            />
            {
              cryptosState.availableCoins?.find(
                (coin: any) => coin.id === cryptoState.analyticsSelectedCoin
              )?.code
            }
            <BiChevronDown
              size={isAnalytics ? 19 : 15}
              color={
                type === "asset"
                  ? theme.palette.text.primary
                  : theme.palette.common.white
              }
            />
          </Button>
        ))}

      <Popover
        id={id}
        open={type == "coins_list" ? true : open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: type == "coins_list" ? "center" : "bottom",
          horizontal: type == "coins_list" ? "center" : "right",
        }}
        transformOrigin={{
          vertical: type == "coins_list" ? "bottom" : "top",
          horizontal: type == "coins_list" ? "center" : "right",
        }}
      >
        <Box
          sx={{
            background: (theme: any) => theme.palette.common.darkPrimary,
            maxWidth: 500,
            minWidth: 360,
          }}
          p={2}
        >
          {cryptosState.availableCoinsLoading ? (
            <Loading />
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={submitUserDataHandler()}
              >
                {(formik) => (
                  <Form id="coin_list_form">
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <Input
                          name="coin"
                          formik={formik}
                          label="Search for a coin name"
                          hasInlineLabel={false}
                          hasInnerLabel={true}
                          icon={<FiSearch color={theme.palette.common.white} />}
                          variant="outlined"
                          initialValues={initialValues}
                          setInitialValues={setInitialValues}
                          inputTextColor={theme.palette.common.white}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Grid container mt={2} sx={{ maxWidth: 360 }}>
                {filteredCoins && filteredCoins.length > 0 ? (
                  filteredCoins.map((coin: CoinType) => (
                    <Grid
                      item
                      md={4}
                      xs={4}
                      key={coin.id}
                      sx={{
                        padding: "2px",
                      }}
                    >
                      <Box
                        className={styles.coin_wrapper}
                        onClick={() => handleCoinClick(coin)}
                        sx={{
                          background:
                            theme.palette.mode === "light"
                              ? theme.palette.grey[700]
                              : theme.palette.grey[900],
                        }}
                      >
                        <img src={getCoinImage(coin.id)} />
                        <Box>
                          <Typography variant="body2">{coin.name}</Typography>
                          <Typography variant="caption">{coin.code}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="caption"
                    align="center"
                    color={theme.palette.common.white}
                  >
                    No coin found with this name
                  </Typography>
                )}
              </Grid>
              <Typography
                variant="body2"
                mt={3}
                color={theme.palette.common.white}
              >
                Search for more coins
              </Typography>
            </>
          )}
        </Box>
      </Popover>

      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </Box>
  );
}
