import { useState } from "react";
import {
  Box,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Plans from "../../components/subscription/plans";
import styles from "./subscription.module.scss";

const Subscription = () => {
  const theme = useTheme();
  const [duration, setDuration] = useState("1 Month");
  const [selectedPlan, setSelectedPlan] = useState("Basic");

  const handleChange = (event: any, data: string) => {
    if (data !== null) {
      setDuration(data);
    }
  };

  return (
    <DashboardLayout title="Coinfident | Subscription">
      <Container maxWidth="lg">
        <Box className={styles.subscriptionWrapper} pt={3}>
          <Box className={styles.subscriptionContent}>
            <Typography
              align="center"
              variant="h4"
              color={theme.palette.text.primary}
            >
              Pricing
            </Typography>
            <Typography
              variant="body2"
              component="h2"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              Select the offer the best suits your need
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <ToggleButtonGroup
                color="primary"
                value={duration}
                exclusive
                onChange={handleChange}
                aria-label="duration"
              >
                <ToggleButton
                  value="1 Month"
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  1 Month
                </ToggleButton>
                <ToggleButton
                  value="3 Month"
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  3 Month
                </ToggleButton>
                <ToggleButton
                  value="6 Month"
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  6 Month
                </ToggleButton>
                <ToggleButton
                  value="1 Year"
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  1 Year
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Plans
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              duration={duration}
            />
          </Box>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Subscription;
