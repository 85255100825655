import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LuExternalLink } from "react-icons/lu";
import styles from "../agents.module.scss";

const AgentSuggestions = ({
  selectedAgentItem,
  navigatePageHandler,
}: {
  selectedAgentItem: any;
  navigatePageHandler: any;
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box className={styles.agentSuggestions}>
      {
        //@ts-ignore
        selectedAgentItem?.suggestions?.map((suggest) => (
          <Box key={suggest} onClick={() => navigatePageHandler("", suggest)}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 100 }}
              color={theme.palette.text.primary}
            >
              {suggest}
            </Typography>
            {!downLg && <LuExternalLink color={theme.palette.text.primary} />}
          </Box>
        ))
      }
    </Box>
  );
};

export default AgentSuggestions;
