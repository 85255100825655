import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { pricingItems } from "../../../data/pricingItems";
import styles from "./plans.module.scss";

const Plans = ({
  setSelectedPlan,
  selectedPlan,
  duration,
}: {
  setSelectedPlan: any;
  selectedPlan: any;
  duration: any;
}) => {
  const theme = useTheme();

  return (
    <Grid container mt={2}>
      {pricingItems.map((pricingItem, idx) => (
        <Grid
          item
          xs={12}
          md={6}
          xl={3}
          key={pricingItem.title}
          onClick={() => setSelectedPlan(pricingItem.title)}
        >
          <Slide duration={1500 / (idx + 1)} direction="up" triggerOnce>
            <Box
              className={styles.pricingItem}
              sx={{
                background:
                  pricingItem.title == selectedPlan
                    ? "#008963"
                    : (theme: any) => theme.palette.common.darkPrimary,
                border:
                  pricingItem.title == selectedPlan
                    ? `3px solid ${theme.palette.primary.main}`
                    : "3px solid transparent",
              }}
            >
              <Typography variant="h6">{pricingItem.title}</Typography>
              <Box>
                <Typography variant="caption">Social Prediction</Typography>
                {pricingItem.items[0] ? (
                  <FaRegCircleCheck style={{ color: "#2FDB7D" }} />
                ) : (
                  <CgCloseO size={18} style={{ color: "#FA637A" }} />
                )}
              </Box>

              {/* <Box>
                      <Typography variant="caption">
                        Alt-Coin Unusual Activities
                      </Typography>
                      {pricingItem.items[1] ? (
                        <FaRegCircleCheck style={{ color: "#2FDB7D" }} />
                      ) : (
                        <CgCloseO size={18} style={{ color: "#FA637A" }} />
                      )}
                    </Box> */}
              <Box>
                <Typography variant="caption">Portfolio Tracker</Typography>
                {pricingItem.items[1] ? (
                  <FaRegCircleCheck style={{ color: "#2FDB7D" }} />
                ) : (
                  <CgCloseO size={18} style={{ color: "#FA637A" }} />
                )}
              </Box>
              <Box>
                <Typography variant="caption">On-Chain Analytics</Typography>
                {pricingItem.items[2] ? (
                  <FaRegCircleCheck style={{ color: "#2FDB7D" }} />
                ) : (
                  <CgCloseO size={18} style={{ color: "#FA637A" }} />
                )}
              </Box>
              <Box>
                <Typography variant="caption">Targeted Alert Limit</Typography>
                <Typography variant="caption">
                  {pricingItem.items[3]}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Agent Limit</Typography>
                <Typography variant="caption">
                  {pricingItem.items[4]}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Wallet Analysis Agent</Typography>
                {pricingItem.items[5] ? (
                  <FaRegCircleCheck style={{ color: "#2FDB7D" }} />
                ) : (
                  <CgCloseO size={18} style={{ color: "#FA637A" }} />
                )}
              </Box>
              {/* <Box>
                      <Typography variant="caption">PDF Summarizer</Typography>
                      {pricingItem.items[7] ? (
                        <FaRegCircleCheck style={{ color: "#2FDB7D" }} />
                      ) : (
                        <CgCloseO size={18} style={{ color: "#FA637A" }} />
                      )}
                    </Box> */}
              <Box className={styles.price}>
                <Typography variant="h5">{duration}</Typography>
                <Box>
                  <Typography mr={2}>$0</Typography>
                  <Typography
                    sx={{
                      textDecoration: "line-through",
                      textDecorationColor: "#FA637A",
                      textDecorationThickness: "1.5px",
                    }}
                  >
                    $
                    {
                      pricingItem.price[
                        duration === "1 Month"
                          ? 0
                          : duration === "3 Month"
                          ? 1
                          : duration === "6 Month"
                          ? 2
                          : 3
                      ]
                    }
                  </Typography>
                </Box>
              </Box>
              <Typography component="h5" variant="caption" textAlign="center">
                Free for limited time
              </Typography>
            </Box>
          </Slide>
        </Grid>
      ))}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          my: 4,
        }}
      >
        <Button
          disabled
          size="large"
          variant="contained"
          sx={{ padding: "0 !important" }}
        >
          <Link
            to={`/subscription-purchase/?plan=${selectedPlan}&duration=${duration}`}
            style={{
              padding: ".8rem 3rem",
              width: "100%",
              textDecoration: "none",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              lineHeight: 1,
            }}
          >
            Get it now
            <MdOutlineKeyboardArrowRight
              size={20}
              style={{ marginLeft: "5px" }}
            />
          </Link>
        </Button>
      </Box>
    </Grid>
  );
};

export default Plans;
