import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { InfluencersState } from "./interfaces";

export const fetchInfluencersList = createAsyncThunk<
  { data: null },
  { ordering: string; page: number },
  { rejectValue: any }
>("influencers list", async (requestData, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/get-influencers-list/?ordering=${requestData.ordering}&user_type=influencer&page=${requestData.page}`
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInfluencerData = createAsyncThunk<
  { data: null },
  { id: string | undefined; page: number | string | undefined },
  { rejectValue: any }
>("influencer data", async (requestData, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-statistics/?page=${requestData.page}`,
      {
        influencer: requestData.id,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPinnedInfluencers = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("pinned influencers", async (_, thunkAPI) => {
  try {
    const response = await http.get(`/crypto/pinned-influencer-list/`);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTopInfluencers = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("top influencers", async (_, thunkAPI) => {
  try {
    const response = await http.get("/crypto/get-top-influencers/");
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSortInfluencers = createAsyncThunk<
  { data: null },
  { id: number; type: string; timePeriod: number },
  { rejectValue: any }
>("sort influencer", async (args, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/sort_influencers/?signal_type=${args.type}&time_frame=${args.timePeriod}`
    );
    const data = response.data;
    return { id: args.id, data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSortInfluencersSelectedCoin = createAsyncThunk<
  { data: null },
  { id: number; type: string; timePeriod: number; coin: number },
  { rejectValue: any }
>("sort influencer selected coin", async (args, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/sort_influencers/?signal_type=${args.type}&time_frame=${args.timePeriod}&coin_id=${args.coin}`
    );
    const data = response.data;
    return { id: args.id, data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInfluencersSpectrum = createAsyncThunk<
  [],
  void,
  { rejectValue: any }
>("influencers spectrum", async (_, thunkAPI) => {
  try {
    const response = await http.get("/crypto/get-influencers-spectrum/");
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInfluencersCoinGraph = createAsyncThunk<
  {},
  void,
  { rejectValue: any }
>("influencers coin graph", async (_, thunkAPI) => {
  try {
    const response = await http.get("/crypto/get-influencers-coin-graph/");
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTopInfluencersCoin = createAsyncThunk<
  [],
  number,
  { rejectValue: any }
>("top influencer coin", async (coin, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/get_top_influencers_coin/?coin_id=${coin}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: InfluencersState = {
  influencersFilter: null,
  influencersList: null,
  influencerData: null,
  topInfluencers: null,
  pinnedInfluencers: null,
  sortedInfluencers: {},
  sortedInfluencersSelectedCoin: {},
  influencersSpectrum: [],
  influencersCoinGraph: {},
  topInfluencersCoin: [],
  influencerDataLoading: true,
  influencersListLoading: true,
  topInfluencersLoading: true,
  pinnedInfluencersLoading: true,
  sortedInfluencersLoading: [true, true, true, true, true],
  sortedInfluencersSelectedCoinLoading: [true, true],
  influencersSpectrumLoading: true,
  influencersCoinGraphLoading: true,
  topInfluencersCoinLoading: true,
  error: null,
};

export const influencersSlice = createSlice({
  name: "influencers",
  initialState,
  reducers: {
    setInfluencersFilter: (state, action) => {
      state.influencersFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencersList.pending, (state) => {
        state.influencersListLoading = true;
        state.error = null;
      })
      .addCase(fetchInfluencersList.fulfilled, (state, action) => {
        state.influencersListLoading = false;
        //@ts-ignore
        state.influencersList = action.payload;
      })
      .addCase(fetchInfluencersList.rejected, (state, action) => {
        state.influencersListLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchInfluencerData.pending, (state) => {
        state.influencerDataLoading = true;
        state.error = null;
      })
      .addCase(fetchInfluencerData.fulfilled, (state, action) => {
        state.influencerDataLoading = false;
        //@ts-ignore
        state.influencerData = action.payload;
      })
      .addCase(fetchInfluencerData.rejected, (state, action) => {
        state.influencerDataLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchTopInfluencers.pending, (state) => {
        state.topInfluencersLoading = true;
        state.error = null;
      })
      .addCase(fetchTopInfluencers.fulfilled, (state, action) => {
        state.topInfluencersLoading = false;
        state.topInfluencers = action.payload;
      })
      .addCase(fetchTopInfluencers.rejected, (state, action) => {
        state.topInfluencersLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPinnedInfluencers.pending, (state) => {
        state.pinnedInfluencersLoading = true;
        state.error = null;
      })
      .addCase(fetchPinnedInfluencers.fulfilled, (state, action) => {
        state.pinnedInfluencersLoading = false;
        state.pinnedInfluencers = action.payload;
      })
      .addCase(fetchPinnedInfluencers.rejected, (state, action) => {
        state.pinnedInfluencersLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchSortInfluencers.pending, (state, action) => {
        state.sortedInfluencersLoading[action.meta.arg.id] = true;
      })
      .addCase(fetchSortInfluencers.fulfilled, (state, action) => {
        state.sortedInfluencers = {
          ...state.sortedInfluencers,
          [action.meta.arg.type]: action.payload,
        };
        state.sortedInfluencersLoading[action.meta.arg.id] = false;
      })
      .addCase(fetchSortInfluencers.rejected, (state, action) => {
        state.sortedInfluencersLoading[action.meta.arg.id] = true;
      });

    builder
      .addCase(fetchSortInfluencersSelectedCoin.pending, (state, action) => {
        state.sortedInfluencersSelectedCoinLoading[action.meta.arg.id] = true;
      })
      .addCase(fetchSortInfluencersSelectedCoin.fulfilled, (state, action) => {
        state.sortedInfluencersSelectedCoin = {
          ...state.sortedInfluencersSelectedCoin,
          [action.meta.arg.type]: action.payload,
        };
        state.sortedInfluencersSelectedCoinLoading[action.meta.arg.id] = false;
      })
      .addCase(fetchSortInfluencersSelectedCoin.rejected, (state, action) => {
        state.sortedInfluencersSelectedCoinLoading[action.meta.arg.id] = true;
      });

    builder
      .addCase(fetchInfluencersSpectrum.pending, (state) => {
        state.influencersSpectrumLoading = true;
      })
      .addCase(fetchInfluencersSpectrum.fulfilled, (state, action) => {
        state.influencersSpectrum = action.payload;
        state.influencersSpectrumLoading = false;
      })
      .addCase(fetchInfluencersSpectrum.rejected, (state) => {
        state.influencersSpectrumLoading = true;
      });

    builder
      .addCase(fetchInfluencersCoinGraph.pending, (state) => {
        state.influencersCoinGraphLoading = true;
      })
      .addCase(fetchInfluencersCoinGraph.fulfilled, (state, action) => {
        state.influencersCoinGraph = action.payload;
        state.influencersCoinGraphLoading = false;
      })
      .addCase(fetchInfluencersCoinGraph.rejected, (state) => {
        state.influencersCoinGraphLoading = true;
      });

    builder
      .addCase(fetchTopInfluencersCoin.pending, (state) => {
        state.topInfluencersCoinLoading = true;
      })
      .addCase(fetchTopInfluencersCoin.fulfilled, (state, action) => {
        state.topInfluencersCoin = action.payload;
        state.topInfluencersCoinLoading = false;
      })
      .addCase(fetchTopInfluencersCoin.rejected, (state) => {
        state.topInfluencersCoinLoading = true;
      });
  },
});

export default influencersSlice.reducer;

export const { setInfluencersFilter } = influencersSlice.actions;
