import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { BiFilterAlt } from "react-icons/bi";
import { RootState } from "../../../../features/store";
import { getCoinImage } from "../../../../utils/functions";
import { CoinType, CoinsListProps } from "../../interfaces";
import styles from "../newsHeader.module.scss";

const CoinsList: React.FC<CoinsListProps> = ({
  newsType,
  twitterFilters,
  setTwitterFilters,
}) => {
  const theme = useTheme();
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const [selectedCoins, setSelectedCoins] = useState<any>(
    twitterFilters.selectedCoins ? twitterFilters.selectedCoins : []
  );

  const selectCoinHandler = (coin: { code: string }) => {
    const selectedCoin = coin.code.toLowerCase();

    if (selectedCoins.includes(selectedCoin)) {
      setSelectedCoins((prevDataArray: []) =>
        prevDataArray.filter((code: string) => code !== selectedCoin)
      );
    } else {
      setSelectedCoins((prevDataArray: []) => [...prevDataArray, selectedCoin]);
    }

    setTwitterFilters({
      ...twitterFilters,
      selectedCoins: selectedCoins.includes(selectedCoin)
        ? selectedCoins.filter((code: string) => code !== selectedCoin)
        : [...selectedCoins, selectedCoin],
    });
  };

  return (
    <Box className={styles.coinsListWrapper}>
      <Typography variant="h6">
        <BiFilterAlt
          size={18}
          color={
            //@ts-ignore
            theme.palette.common.whiteToDarkGreen
          }
        />
      </Typography>
      <Box className={styles.coinsList}>
        {coinsList.availableCoins &&
          coinsList.availableCoins
            //@ts-ignore
            .filter((coin: CoinType) => coin.calc_model)
            .map((coin: CoinType) => (
              <Box
                className={styles.coinItem}
                key={coin.id}
                onClick={() => selectCoinHandler(coin)}
                sx={{
                  background: selectedCoins.includes(
                    coin.code.toLocaleLowerCase()
                  )
                    ? theme.palette.secondary.dark
                    : "transparent",
                }}
              >
                <img src={getCoinImage(coin.id)} />
                <Typography
                  variant="caption"
                  color={
                    //@ts-ignore
                    theme.palette.common.whiteToDarkGreen
                  }
                >
                  {coin.name}
                </Typography>
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default CoinsList;
