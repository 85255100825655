import { Box, Typography, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import CardTitle from "../../components/common/title/CardTitle";

const NotFound = () => {
  const theme = useTheme();

  return (
    <DashboardLayout title="Coinfident | 404">
      <Box mt={20} mb={25}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            //@ts-ignore
            color: theme.palette.common.whiteToGreen,
          }}
        >
          404
        </Typography>
        <CardTitle isCenter>Not Found</CardTitle>
      </Box>
    </DashboardLayout>
  );
};

export default NotFound;
