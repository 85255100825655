import { Fade } from "react-awesome-reveal";

const Stars = ({ width = 114, height = 121 }) => {
  return (
    <Fade triggerOnce>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 114 121"
        fill="none"
      >
        <g opacity="0.474074">
          <g>
            <path
              d="M31.7725 119.365C31.37 118.845 31.4726 118.099 31.9939 117.697C32.5152 117.295 33.2622 117.398 33.6648 117.918C34.0673 118.438 33.9647 119.184 33.4434 119.586C32.9221 119.988 32.1751 119.885 31.7725 119.365Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M32.2171 119.043C31.9956 118.754 32.0465 118.361 32.3362 118.14C32.6258 117.919 33.019 117.97 33.2404 118.259C33.4619 118.548 33.4109 118.941 33.1213 119.162C32.8317 119.383 32.4385 119.332 32.2171 119.043Z"
            fill="#45FFFF"
          />
          <path
            d="M2.40726 115.63C2.1858 115.341 2.23673 114.948 2.52637 114.727C2.81601 114.506 3.20914 114.557 3.4306 114.846C3.65207 115.135 3.60113 115.528 3.31149 115.749C3.02185 115.97 2.62873 115.919 2.40726 115.63Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M50.5553 75.4859C50.2935 75.139 50.3625 74.6679 50.6905 74.4069C51.0382 74.1456 51.5101 74.2143 51.7715 74.5418C52.0333 74.8888 51.9644 75.3598 51.6363 75.6208C51.3083 75.8818 50.8168 75.8134 50.5553 75.4859Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M50.8253 75.2653C50.6842 75.0724 50.7183 74.8173 50.9115 74.6765C51.1048 74.5356 51.3603 74.5696 51.5014 74.7625C51.6425 74.9553 51.6084 75.2104 51.4152 75.3513C51.222 75.4921 50.9665 75.4581 50.8253 75.2653Z"
            fill="#45FFFF"
          />
          <path
            d="M31.529 73.0697C31.3878 72.8769 31.4219 72.6218 31.6152 72.4809C31.8084 72.34 32.0639 72.374 32.205 72.5669C32.3461 72.7598 32.3121 73.0148 32.1188 73.1557C31.9256 73.2966 31.6701 73.2626 31.529 73.0697Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M85.3685 5.88449C84.9659 5.36421 85.0686 4.61853 85.5898 4.21668C86.1111 3.81483 86.8582 3.91724 87.2607 4.43752C87.6633 4.95779 87.5606 5.70348 87.0393 6.10532C86.5181 6.50717 85.771 6.40476 85.3685 5.88449Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M85.7928 5.54329C85.5713 5.2542 85.6222 4.8618 85.9119 4.64073C86.2015 4.41965 86.5946 4.47046 86.8161 4.75955C87.0376 5.04864 86.9866 5.44104 86.697 5.66211C86.4073 5.88319 86.0142 5.83238 85.7928 5.54329Z"
            fill="#45FFFF"
          />
          <path
            d="M56.0027 2.12996C55.7813 1.84087 55.8322 1.44847 56.1218 1.2274C56.4115 1.00632 56.8046 1.05713 57.0261 1.34622C57.2475 1.63531 57.1966 2.02771 56.907 2.24878C56.6173 2.46986 56.2242 2.41905 56.0027 2.12996Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M5.70448 12.3376C5.87422 12.9799 5.49484 13.6137 4.85127 13.7832C4.2077 13.9527 3.57273 13.5741 3.40299 12.9317C3.23324 12.2893 3.61262 11.6555 4.25619 11.486C4.89976 11.3166 5.53473 11.6952 5.70448 12.3376Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M5.17805 12.4851C5.26352 12.8356 5.05502 13.1921 4.72343 13.277C4.39185 13.3619 4.01518 13.1542 3.93011 12.8233C3.84465 12.4728 4.05315 12.1163 4.38473 12.0314C4.73591 11.9461 5.09299 12.1541 5.17805 12.4851Z"
            fill="#45FFFF"
          />
          <path
            d="M31.3883 27.0268C31.4737 27.3773 31.2652 27.7337 30.9336 27.8187C30.6021 27.9036 30.2254 27.6959 30.1403 27.365C30.0549 27.0144 30.2634 26.658 30.5949 26.5731C30.9265 26.4881 31.3032 26.6958 31.3883 27.0268Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M38.3629 81.4449C37.9603 80.9247 38.063 80.179 38.5842 79.7771C39.1055 79.3753 39.8526 79.4777 40.2551 79.998C40.6577 80.5183 40.555 81.2639 40.0337 81.6658C39.5125 82.0676 38.7654 81.9652 38.3629 81.4449Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M38.8069 81.1034C38.5855 80.8143 38.6364 80.4219 38.926 80.2008C39.2157 79.9797 39.6088 80.0305 39.8303 80.3196C40.0517 80.6087 40.0008 81.0011 39.7112 81.2222C39.4215 81.4433 39.0284 81.3924 38.8069 81.1034Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M7.02872 8.96467C7.35249 9.46696 7.20867 10.1156 6.70543 10.4388C6.20218 10.762 5.55233 10.6185 5.22856 10.1163C4.90478 9.61396 5.0486 8.9653 5.55184 8.64209C6.05509 8.31888 6.70494 8.46238 7.02872 8.96467Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M6.60272 9.22762C6.765 9.49833 6.69368 9.852 6.42286 10.0336C6.15164 10.1956 5.79731 10.1244 5.61544 9.85409C5.45316 9.58338 5.52448 9.22971 5.7953 9.04815C6.06652 8.88615 6.42085 8.95731 6.60272 9.22762Z"
            fill="#45FFFF"
          />
          <path
            d="M32.9011 14.4919C33.0633 14.7626 32.992 15.1162 32.7212 15.2978C32.4504 15.4794 32.0957 15.3886 31.9138 15.1183C31.7515 14.8476 31.8228 14.4939 32.0936 14.3124C32.3649 14.1504 32.7192 14.2215 32.9011 14.4919Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M62.8563 72.01C63.059 72.3386 62.9692 72.7514 62.64 72.9537C62.3108 73.156 61.8973 73.0664 61.6946 72.7378C61.492 72.4092 61.5817 71.9965 61.9109 71.7942C62.2402 71.5919 62.6537 71.6814 62.8563 72.01Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M62.5851 72.172C62.6866 72.3461 62.6522 72.5816 62.4777 72.683C62.3037 72.8039 62.0674 72.7499 61.9659 72.5758C61.8643 72.4017 61.8988 72.1662 62.0732 72.0649C62.2476 71.9635 62.4836 71.9979 62.5851 72.172Z"
            fill="#45FFFF"
          />
          <path
            d="M79.6317 75.5869C79.7333 75.761 79.6988 75.9965 79.5244 76.0978C79.35 76.1992 79.114 76.1648 79.0125 75.9907C78.911 75.8166 78.9454 75.5811 79.1199 75.4797C79.2943 75.3784 79.5302 75.4128 79.6317 75.5869Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M11.4062 77.5638C11.7299 78.0661 11.5861 78.7147 11.0829 79.0379C10.5796 79.3611 9.92977 79.2176 9.606 78.7153C9.28223 78.2131 9.42604 77.5644 9.92928 77.2412C10.4325 76.918 11.0824 77.0615 11.4062 77.5638Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M10.9997 77.8263C11.162 78.097 11.0907 78.4507 10.8198 78.6323C10.5486 78.7943 10.1943 78.7231 10.0124 78.4528C9.85013 78.1821 9.92145 77.8284 10.1923 77.6468C10.4635 77.4848 10.8178 77.556 10.9997 77.8263Z"
            fill="#45FFFF"
          />
          <path
            d="M37.298 83.0906C37.4603 83.3613 37.389 83.7149 37.1182 83.8965C36.8469 84.0585 36.4926 83.9873 36.3107 83.717C36.1485 83.4463 36.2198 83.0926 36.4906 82.9111C36.7618 82.7491 37.1162 82.8202 37.298 83.0906Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M26.6319 98.6664C26.5221 98.0815 26.923 97.5451 27.4894 97.4359C28.0754 97.3263 28.6127 97.7264 28.7221 98.2917C28.8319 98.8766 28.431 99.413 27.8646 99.5222C27.2786 99.6319 26.7413 99.2317 26.6319 98.6664Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M27.1201 98.5783C27.055 98.2665 27.2647 97.9688 27.5967 97.9034C27.9287 97.838 28.2077 98.0672 28.2728 98.379C28.3379 98.6908 28.1282 98.9886 27.7962 99.054C27.4839 99.1189 27.1852 98.8901 27.1201 98.5783Z"
            fill="#45FFFF"
          />
          <path
            d="M4.84277 83.6445C4.77769 83.3328 4.98737 83.035 5.31935 82.9696C5.65132 82.9043 5.9304 83.1335 5.99548 83.4453C6.06056 83.7571 5.85088 84.0548 5.5189 84.1202C5.20652 84.1852 4.90785 83.9563 4.84277 83.6445Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M74.1588 67.6148C74.4826 68.1171 74.3388 68.7658 73.8356 69.089C73.3323 69.4122 72.6825 69.2687 72.3587 68.7664C72.0349 68.2641 72.1787 67.6155 72.682 67.2922C73.1852 66.969 73.8351 67.1125 74.1588 67.6148Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M73.7524 67.8774C73.9147 68.1481 73.8433 68.5018 73.5725 68.6833C73.3013 68.8453 72.947 68.7742 72.7651 68.5038C72.6028 68.2331 72.6741 67.8795 72.945 67.6979C73.2162 67.5359 73.5705 67.6071 73.7524 67.8774Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M111.397 55.3212C110.894 55.6445 110.244 55.4814 109.92 54.9987C109.597 54.5159 109.76 53.8473 110.243 53.5245C110.727 53.2017 111.397 53.3644 111.72 53.8471C112.044 54.3494 111.881 54.9984 111.397 55.3212Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M111.134 54.9156C110.863 55.0776 110.508 55.0064 110.327 54.7361C110.145 54.4658 110.236 54.1117 110.506 53.9302C110.778 53.7682 111.132 53.8393 111.314 54.1096C111.496 54.38 111.405 54.734 111.134 54.9156Z"
            fill="#45FFFF"
          />
          <path
            d="M105.799 81.1668C105.528 81.3288 105.174 81.2577 104.992 80.9874C104.81 80.7171 104.901 80.363 105.172 80.1814C105.443 80.0194 105.797 80.0906 105.979 80.3609C106.161 80.6312 106.07 80.9853 105.799 81.1668Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M19.661 50.295C19.3318 50.4973 18.9183 50.4077 18.7156 50.0791C18.513 49.7505 18.6027 49.3378 18.9319 49.1355C19.2612 48.9332 19.6747 49.0227 19.8773 49.3513C20.08 49.6799 19.9902 50.0927 19.661 50.295Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M19.4797 50.0442C19.3053 50.1456 19.0693 50.1112 18.9678 49.9371C18.8663 49.763 18.9008 49.5275 19.0752 49.4261C19.2496 49.3248 19.4855 49.3592 19.5871 49.5333C19.6886 49.7074 19.6541 49.9429 19.4797 50.0442Z"
            fill="#45FFFF"
          />
          <path
            d="M16.0175 67.0409C15.8431 67.1422 15.6072 67.1078 15.5057 66.9338C15.4041 66.7597 15.4386 66.5242 15.613 66.4228C15.7874 66.3215 16.0234 66.3559 16.1249 66.5299C16.2264 66.704 16.192 66.9395 16.0175 67.0409Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M36.1766 57.5169C35.6733 57.8401 35.0231 57.677 34.6997 57.1943C34.3763 56.7116 34.5394 56.043 35.023 55.7201C35.5067 55.3973 36.1765 55.56 36.4999 56.0427C36.8233 56.5255 36.6602 57.1941 36.1766 57.5169Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M35.9134 57.1112C35.6422 57.2732 35.2879 57.2021 35.106 56.9317C34.9241 56.6614 35.015 56.3074 35.2859 56.1258C35.5567 55.9442 35.9114 56.035 36.0933 56.3053C36.2556 56.576 36.1843 56.9297 35.9134 57.1112Z"
            fill="#45FFFF"
          />
          <path
            d="M31.1456 84.2513C30.3908 84.7361 29.3862 84.5019 28.9005 83.7484C28.4149 82.995 28.6496 81.9923 29.4045 81.5074C30.1594 81.0226 31.1639 81.2568 31.6496 82.0103C32.1352 82.7637 31.9005 83.7664 31.1456 84.2513Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M14.994 72.6973C15.58 72.5877 16.1174 72.9879 16.2268 73.5532C16.3361 74.1185 15.9356 74.6744 15.3692 74.7837C14.7833 74.8933 14.2459 74.4931 14.1365 73.9278C14.0268 73.3429 14.4277 72.8065 14.994 72.6973Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M15.082 73.1652C15.3948 73.1198 15.6931 73.3291 15.7582 73.6408C15.8036 73.953 15.594 74.2508 15.2816 74.3158C14.9688 74.3612 14.6705 74.1519 14.6054 73.8401C14.56 73.5279 14.7696 73.2302 15.082 73.1652Z"
            fill="#45FFFF"
          />
          <path
            d="M30.085 50.9662C30.3978 50.9208 30.6961 51.1301 30.7611 51.4419C30.8066 51.754 30.5969 52.0518 30.2846 52.1168C29.9718 52.1622 29.6735 51.9529 29.6084 51.6411C29.5434 51.3293 29.7726 51.0312 30.085 50.9662Z"
            fill="#45FFFF"
          />
          <g>
            <path
              d="M24.025 61.5965C23.5217 61.9197 22.8715 61.7566 22.5481 61.2739C22.2243 60.7716 22.3877 60.1225 22.8714 59.7997C23.355 59.4769 24.0249 59.6396 24.3483 60.1223C24.6716 60.605 24.5086 61.2736 24.025 61.5965Z"
              fill="#45FFFF"
            />
          </g>
          <path
            d="M23.762 61.1908C23.4907 61.3528 23.1364 61.2816 22.9545 61.0113C22.7923 60.7406 22.8636 60.3869 23.1344 60.2054C23.4056 60.0434 23.76 60.1145 23.9418 60.3849C24.1041 60.6556 24.0328 61.0092 23.762 61.1908Z"
            fill="#45FFFF"
          />
        </g>
      </svg>
    </Fade>
  );
};

export default Stars;
