import { Box, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router";
import Wave_1 from "../../../assets/images/shapes/wave_1.png";
import Wave_2 from "../../../assets/images/shapes/wave_2.png";
import Wave_3 from "../../../assets/images/shapes/wave_3.png";
import Wave_4 from "../../../assets/images/shapes/wave_4.png";
import styles from "../footer.module.scss";

const FooterSvg = () => {
  const theme = useTheme();
  const location = useLocation();
  const year = new Date().getFullYear();

  return (
    <Box className={styles.footerSvgWrapper}>
      <img src={Wave_1} alt="icon" className={styles.wave_1} />
      <img src={Wave_2} alt="icon" className={styles.wave_2} />
      <img
        src={Wave_3}
        alt="icon"
        className={styles.wave_3}
        style={{
          left: location.pathname.includes("/agents") ? "0" : "-16px",
        }}
      />
      <img
        src={Wave_4}
        alt="icon"
        className={styles.wave_4}
        style={{
          left: location.pathname.includes("/agents") ? "0" : "-16px",
        }}
      />
      <Typography variant="caption" color={theme.palette.text.primary}>
        ©{year} CoinfidentInc.All rights reserved
      </Typography>
    </Box>
  );
};

export default FooterSvg;
