import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Form, Formik } from "formik";
import { Box, Button, Typography, Modal, useTheme } from "@mui/material";
import CryptoList from "../../../crypto/cryptoList";
import { AlertModalProps } from "../interfaces";
import Input from "../../../form/Input";
import { createEngagementAlertHandler } from "../../../../api/alerts";
import { fetchEngagementAlerts } from "../../../../features/alerts/alertsSlice";
import { RootState } from "../../../../features/store";
import {
  fetchAvailableCoins,
  fetchCoinsStatistics,
} from "../../../../features/crypto/cryptosSlice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  background: (theme: any) => theme.palette.common.solidCard,
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 3,
};

export default function EngagementAlert({ handleClose }: AlertModalProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [loading, setLoading] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(3);
  const [initialValues, setInitialValues] = useState<any>({
    target_engagement_percentage_change: 0,
  });

  useEffect(() => {
    if (!cryptosState.availableCoins) {
      //@ts-ignore
      dispatch(fetchAvailableCoins());
    }
  }, [cryptosState.availableCoins]);

  const coinsSelectorHandler = (selectedCoinItem: number) => {
    setSelectedCoin(selectedCoinItem);
  };

  const submitDataHandler =
    () =>
    (values: { target_engagement_percentage_change: string | number }) => {
      setLoading(true);
      const alertData = {
        coin: selectedCoin,
        target_engagement_percentage_change:
          values.target_engagement_percentage_change,
      };

      createEngagementAlertHandler(alertData).then((response) => {
        //@ts-ignore
        dispatch(fetchEngagementAlerts()).then((res) => {
          setLoading(false);
          toast.success("Alert successfully added");
          handleClose();
        });
      });
    };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="body1"
          component="h2"
          align="center"
        >
          Set Alert for crypto engagement
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submitDataHandler()}
          >
            {(formik) => (
              <Form
                id="manual asset form"
                style={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 100,
                      color: theme.palette.text.primary,
                    }}
                  >
                    Select Coin:
                  </Typography>
                  <CryptoList
                    coinsSelectorHandler={coinsSelectorHandler}
                    type="asset"
                  />
                </Box>
                <br />
                <Input
                  name="target_engagement_percentage_change"
                  formik={formik}
                  label="Content growth percent:"
                  type="number"
                  hasInlineLabel={true}
                  hasInnerLabel={false}
                  variant="outlined"
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                />
                <Button
                  fullWidth
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={loading}
                  sx={{
                    marginTop: "1.5rem",
                    "&:hover": {
                      background: theme.palette.secondary.main,
                    },
                  }}
                >
                  {loading ? "Adding..." : "ADD"}
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
}
