import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Loading from "../../components/common/loading";
import CardTitle from "../../components/common/title/CardTitle";
import ComparisonChart from "../../components/shareOfVoice/comparisonChart";
import AssetsTable from "../../components/common/assetsTable";
import { fetchCryptoTweets } from "../../api/crypto-tweets";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { setTweetsCountResult } from "../../features/crypto/cryptoSlice";
import {
  fetchCoinsStatistics,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import { ShareOfVoiceType } from "./interfaces";
import useAuth from "../../utils/useAuth";

const ShareOfVoice = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const isAuthValid = useAuth();
  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [loading, setLoading] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState<any>(
    cryptoState.tweetsCountResult
      ? cryptoState.tweetsCountResult
          .slice(0, 3)
          .map((item: ShareOfVoiceType) => item.id)
      : [3, 6]
  );

  const fetchCryptoTweetsFunction = async (coinId: number) => {
    const cryptoTweets = await fetchCryptoTweets(coinId);
    return cryptoTweets;
  };

  useEffect(() => {
    if (isAuthValid) {
      if (!cryptosState.coinsStatistics) {
        dispatch(fetchCoinsStatistics(1)).then((res) =>
          dispatch(
            setCoinStatistics({
              page: 1,
              result: cryptosState.coinStatisticsState.result.concat(
                res.payload
              ),
            })
          )
        );
      }
    }
  }, [cryptosState.coinsStatistics, isAuthValid]);

  useEffect(() => {
    if (isAuthValid) {
      if (cryptosState.coinsStatistics) {
        if (cryptoState.tweetsCountResult) {
          const coinsToFetch = selectedCoins.filter((coinId: number) => {
            return !cryptoState.tweetsCountResult.find(
              (item: ShareOfVoiceType) => item.id === coinId
            );
          });

          if (coinsToFetch.length > 0) {
            setLoading(true);
            const promises = coinsToFetch.map((coinId: number) =>
              fetchCryptoTweetsFunction(coinId)
            );
            Promise.all(promises)
              .then((results) => {
                setLoading(false);

                const newTweets = results.map((response, index) => ({
                  id: coinsToFetch[index],
                  data: response.data?.response.data,
                }));
                dispatch(
                  setTweetsCountResult([
                    ...cryptoState.tweetsCountResult,
                    ...newTweets,
                  ])
                );
              })
              .catch((error) => {});
          }
        } else {
          const promises = selectedCoins.map((number: number) =>
            fetchCryptoTweetsFunction(number)
          );
          Promise.all(promises).then((results) => {
            const tweets = results.map((response, index) => ({
              id: selectedCoins[index],
              data: response?.data?.response?.data,
            }));
            dispatch(setTweetsCountResult(tweets));
          });
        }
      }
    }
  }, [
    cryptosState.coinsStatistics,
    selectedCoins,
    cryptoState.tweetsCountResult,
  ]);

  return (
    <DashboardLayout title="Coinfident | Social Media Activity">
      <Container>
        <Box mt={3}>
          <CardTitle isCenter isHtml>
            Social Media Activity
          </CardTitle>

          <Box>
            {!cryptosState.coinStatisticsState.result[0]?.data ||
            cryptosState.coinStatisticsState.result[0]?.data.length < 2 ? (
              <Loading hasBackground />
            ) : (
              <>
                {!cryptoState.tweetsCountResult || loading ? (
                  <Loading hasBackground />
                ) : (
                  <ComparisonChart
                    name="Tweets"
                    selectedCoins={selectedCoins}
                    data={cryptoState.tweetsCountResult}
                  />
                )}
                <Box mt={2}>
                  {
                    //@ts-ignore
                    <AssetsTable
                      type="shareOfVoice"
                      loading={loading}
                      data={cryptosState.coinStatisticsState.result[0].data}
                      selectedCoins={selectedCoins}
                      setSelectedCoins={setSelectedCoins}
                    />
                  }
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default ShareOfVoice;
