import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import styles from "../../assetsManagement.module.scss";

const NetworthHeader: React.FC<any> = ({ netWorth, assets }) => {
  const theme = useTheme();

  function findMaxValue(historyArray: any) {
    if (historyArray.length === 0) {
      return null;
    }

    let max = historyArray[0].total_wallets_balance;

    for (let i = 1; i < historyArray.length; i++) {
      const balance = historyArray[i].total_wallets_balance;
      if (balance > max) {
        max = balance;
      }
    }

    return max;
  }

  function findMinValue(historyArray: any) {
    if (historyArray.length === 0) {
      return null;
    }

    let min = historyArray[0].total_wallets_balance;

    for (let i = 1; i < historyArray.length; i++) {
      const balance = historyArray[i].total_wallets_balance;
      if (balance < min) {
        min = balance;
      }
    }

    return min;
  }

  function calculateAverage(historyArray: any) {
    if (historyArray.length === 0) {
      return null;
    }

    let totalBalance = 0;

    for (let i = 0; i < historyArray.length; i++) {
      totalBalance += historyArray[i].total_wallets_balance;
    }

    return totalBalance / historyArray.length;
  }

  const maxValue = findMaxValue(assets.networth_history);
  const minValue = findMinValue(assets.networth_history);
  const averageValue = calculateAverage(assets.networth_history);

  const assetsConclusionData = [
    {
      id: 1,
      title: "Net Worth",
      container_className: "net_worth",
      value: netWorth,
      //@ts-ignore
      Changesvalue: averageValue ? netWorth - averageValue : 0,
      //@ts-ignore
      ChangesPercent: averageValue ? (netWorth - averageValue) / 100 : 0,
    },
    {
      id: 2,
      title: "Average Worth",
      container_className: "average_worth",
      value: averageValue ?? netWorth,
      //@ts-ignore
      Changesvalue: averageValue ? averageValue - netWorth : 0,
      //@ts-ignore
      ChangesPercent: averageValue ? (averageValue - netWorth) / 100 : 0,
    },
    {
      id: 3,
      title: "Best Performance",
      container_className: "best_performance",
      value: maxValue ?? netWorth,
      //@ts-ignore
      Changesvalue: maxValue ? netWorth - maxValue : 0,
      //@ts-ignore
      ChangesPercent: maxValue ? (netWorth - maxValue) / 100 : 0,
    },
    {
      id: 4,
      title: "Worst Performance",
      container_className: "worst_performance",
      value: minValue ?? netWorth,
      //@ts-ignore
      Changesvalue: minValue ? netWorth - minValue : 0,
      //@ts-ignore
      ChangesPercent: minValue ? (netWorth - minValue) / 100 : 0,
    },
  ];

  return (
    <Fade triggerOnce>
      <Grid
        container
        columnGap={0.5}
        mt={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {assetsConclusionData.map((assetCard: any, idx: number) => (
          <Grid
            key={assetCard.id}
            item
            xs={12}
            md={idx == 0 ? 3.7 : 2.7}
            className={`${styles.assetHeaderCard} ${assetCard.container_className}`}
            sx={{
              background: (theme: any) =>
                theme.palette.mode === "dark"
                  ? theme.palette.common.darkPrimary
                  : theme.palette.primary.light,
            }}
          >
            <Typography
              variant="body2"
              component="h2"
              color={theme.palette.text.primary}
            >
              {assetCard.title}
            </Typography>
            <Box>
              {idx == 0 ? (
                <Typography variant="h4" color={theme.palette.text.primary}>
                  ${assetCard.value.toFixed(2)}
                </Typography>
              ) : (
                <Typography variant="h5" color={theme.palette.text.primary}>
                  ${assetCard.value?.toFixed(2)}
                </Typography>
              )}
              <Typography
                variant="h6"
                color={
                  assetCard.Changesvalue < 0
                    ? theme.palette.error.main
                    : theme.palette.success.main
                }
              >
                {assetCard.Changesvalue < 0 ? "-" : "+"}$
                {Math.abs(assetCard.Changesvalue.toFixed(2))}{" "}
                {`(${assetCard.ChangesPercent.toFixed(1)}%)`}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Fade>
  );
};

export default NetworthHeader;
