import ChartsIcon from "../assets/images/icons/Charts.svg";
import InfluencersIcon from "../assets/images/icons/Influencers.svg";
import BotDetectionIcon from "../assets/images/icons/Bot Detection.svg";
import DeFiIcon from "../assets/images/icons/DeFi.svg";
import coinalysisIcon from "../assets/images/icons/coinalysisIcon.svg";
import trade360Icon from "../assets/images/icons/trade360Icon.svg";
import tradeAgentIcon from "../assets/images/icons/tradeAgentIcon.svg";
import chainGuardianImage from "../assets/images/agents/cainGuardian.svg";
import coinalysisImage from "../assets/images/agents/coinalysis.svg";
import cryptoQAImage from "../assets/images/agents/cryptoQ&A.svg";
import influencersExplorerImage from "../assets/images/agents/influencersExplorer.svg";
import modelMakerImage from "../assets/images/agents/modelMaker.svg";
import tradeAgentImage from "../assets/images/agents/teadeAgent.svg";
import trade360Image from "../assets/images/agents/trade360.svg";

export const agentsList = [
  {
    id: 1,
    type: "Free",
    agent_name: "Influencers Explorer",
    title: "Influencers Explorer",
    short_description:
      "Access tailored insights on crypto influencers directly from the Coinfident database.",
    icon: InfluencersIcon,
    image: influencersExplorerImage,
    placeholder: "Ask your question",
    suggestions: [
      // "Top Influencers who talked about bitcoin during the last week",
      // "Top Influencers who Gave the most buy signals during the last week",
      // "Top Influencers who Gave the most buy signals for the Bitcoin during the last week",
      // "Top Influencers who Gave the most not buy signals during the last week",
      "Tell me which influencers were the most active over the past 10 days?",
      "Display the tweets of the most engaged influencers over the past week",
      "Provide a list of the top 10 Bitcoin influencers ranked by their follower count.",
    ],
    description: `<p>Welcome to the Influencers Explorer agent. I can help you explore Twitter crypto influencers’ data and provide you simplified and actionable information about crypto leading voices.</p>
    <p>I monitor around 3,700 Twitter influencers and 75 news outlets. In addition to basic analytics of influencers, I use proprietary AI models to detect buy/not-buy signals (SPS) and measure emotional sentiment from influencers' tweets. Currently, SPS is available for over 30 coins.</p>
    <div>
      <p>You can ask questions like:</p>
      <ul style='padding-left: 40px'>
        <li>Tell me which influencers were the most active over the past 10 days?</li>
        <li>Display the tweets of the most engaged influencers over the past week</li>
        <li>Provide a list of the top 10 Bitcoin influencers ranked by their follower count.</li>
        <li>Which influencers have recommended purchasing XRP over the past month?</li>
        <li>Plot the SPS (Social Prediction Signal) values for Bitcoin (BTC) over the last 14 days?</li>
        <li>Give me some details about @influencer_user_name</li>
      </ul>
      <p style='margin-top: 20px'><strong>Let’s get started! How can I help you today?</strong></p>
    </div>
`,
  },
  {
    id: 2,
    type: "Free",
    agent_name: "Coinalysis",
    title: "Coinalysis",
    short_description:
      "Get the most updated Price and Technical Indicators abouts coins",
    icon: coinalysisIcon,
    image: coinalysisImage,
    placeholder: "Ask your question",
    suggestions: [
      // "What is the bitcoin price today?",
      // "Give me the Price and Technical summery about bitcoin during the last week",
      // "Give me the summery about bitcoin indicators based on confident dashboard",
      // "Give me the Bircoin Status between 3 July to 10 July.",
      "Plot bitcoin's price and SPS for the past month",
      "Provide me with up-to-date information on XRP",
      "Provide a market analysis for Dogecoin",
    ],
    description: `<p>Welcome to the Coinalysis agent. I can help you explore crypto market data and provide you with simplified information about cryptocurrency prices, market metrics, technical indicators, and our proprietary Social Prediction Signals (SPS). I also can help you analyze and visualize crypto market trends and give you detailed analysis of charts showing price movements, SPS, technical scores and tweet volume over time.</p>
    <div>
      <p>You can ask questions like:</p>
      <ul style='padding-left: 40px'>
        <li>What cryptocurrencies are currently showing buy signals according to technical indicators?</li>
        <li>What coins are showing buy signals based on their technical and SPS indicators?</li>
        <li>Plot bitcoin's price and SPS for the past month</li>
        <li>Provide me with up-to-date information on XRP</li>
        <li>Provide a market analysis for Dogecoin</li>
      </ul>
      <p style='margin-top: 20px'><strong>Let’s get started! How can I help you today?</strong></p>
    </div>
`,
  },
  {
    id: 3,
    type: "Free",
    agent_name: "Crypto Q&A",
    title: "Crypto Q&A",
    short_description:
      "Find answers for all of your questions about Defi and Security in Crypto market",
    icon: DeFiIcon,
    image: cryptoQAImage,
    placeholder: "Ask your question",
    suggestions: [
      // "Which protocols have gained the most value in the last week?",
      // "What’s the current total value locked in Uniswap?",
      // "What are the fees earned by Uniswap in the last month?",
      // "What’s the historical total value locked in Uniswap?",
      "Which protocols have gained the most value in the last week?",
      "What are the most common security threats in the cryptocurrency space?",
      "What is a hardware wallet, and why is it considered more secure than a software wallet?",
    ],
    description: `<p>I am a Crypto Q&A agent, ready to answer your general questions about cryptocurrency, including topics on security and DeFi. I offer real-time updates on DeFi and expert guidance on protecting your assets, setting up secure wallets, and ensuring safe transactions. I also provide the latest information on DeFi strategies like yield farming and new protocols, helping you stay informed and secure in the rapidly evolving crypto space.</p>
    <div>
      <p>You can ask questions like:</p>
      <ul style='padding-left: 40px'>
        <li>Which protocols have gained the most value in the last week?</li>
        <li>How do I choose which cryptocurrency to invest in?</li>
        <li>What is a hardware wallet, and why is it considered more secure than a software wallet?</li>
        <li>What are the most common security threats in the cryptocurrency space?</li>
        <li>What are the risks of using public Wi-Fi for cryptocurrency transactions, and how can I mitigate them?</li>
      </ul>
      <p style='margin-top: 20px'><strong>Let’s get started! How can I help you today?</strong></p>
    </div>
`,
  },
  {
    id: 4,
    type: "Free",
    agent_name: "Chain Guardian",
    title: "Chain Guardian",
    short_description:
      "Validate wallet addresses and avoid bots and wallet poisoning",
    icon: BotDetectionIcon,
    image: chainGuardianImage,
    placeholder:
      "Enter wallet address: (Exp: 0xa83114A443dA1CecEFC50368531cACE9F37fCCcb)",
    suggestions: [],
    description: `<p>I'm the Chain Guardian agent. I assist in spotting potential bot wallets by giving each one a score that shows how likely it is to be a bot. I also help you verify crypto wallet addresses by checking their balances, improving the security and trustworthiness of transactions.</p>
    <p>Just enter a wallet address to get detailed, real-time information to protect your crypto transactions.</p>
`,
  },
  {
    id: 5,
    type: "Premium",
    agent_name: "Trade 360",
    title: "Trade 360",
    short_description:
      "Find all-in-one solution for mastering the crypto market",
    icon: trade360Icon,
    image: trade360Image,
    placeholder: "Ask your question",
    suggestions: [],
    description: `<p>I am Trade360, an agent that combines the strengths of Influencers Explorer, Coinanalysis, and ModelMaker agents to give you a comprehensive view of the crypto market.</p>
    <p>I help you explore data from Twitter influencers and news outlets, providing simplified, actionable insights from leading voices in crypto. I use AI models to detect buy/not-buy signals (SPS) and analyze emotional sentiment from influencer tweets. I also simplify cryptocurrency market data, including prices, market metrics, technical indicators, and SPS. Additionally, I can visualize these metrics and analyze market trends.</p>
    <div>
      <p>You can ask questions like:</p>
      <ul style='padding-left: 40px'>
        <li>Show me the top 10 bitcoin influencers based on number of followers</li>
        <li>Which influencers have suggested buying Shiba since last month?</li>
        <li>Which coins have a buy signal based on technical indicators?</li>
        <li>Plot bitcoin's price and SPS for the past month</li>
        <li>Plot price and SPS of Bitcoin over the last month.</li>
        <li>Give me a market analysis about Dogecoin.</li>
      </ul>
      <p style='margin-top: 20px'><strong>To become an early user, <a href='mailto:support@Coinfident.ai' style='color: #75D6A1 !important'>email</a> us to request access while we finalize our payment gateway and subscription plans.</strong></p>
    </div>
`,
  },
  {
    id: 6,
    type: "Coming Soon",
    agent_name: "Model Maker",
    title: "Model Maker",
    short_description:
      "Do market analysis in easy way and create custom charts based on technical, social and macroeconomic factors",
    icon: ChartsIcon,
    image: modelMakerImage,
    placeholder: "Ask your question",
    suggestions: [
      "Plot price and SPS of Bitcoin over the last month.",
      "Give me a market analysis about Dogecoin.",
      "How do you interpret the crypto market since last week?",
    ],
    description: `<p>Welcome to the ModelMaker agent. I can help you analyze and visualize crypto market trends and give you detailed analysis of charts showing price movements, Social Prediction Signals (SPS), and technical scores over time.</p>
    <div>
      <p>You can ask questions like:</p>
      <ul style='padding-left: 40px'>
        <li>Plot price and SPS of Bitcoin over the last month.</li>
        <li>Give me a market analysis about Dogecoin.</li>
        <li>How do you interpret the crypto market since last week?</li>
        <li>Plot SPS and price of Cardano over the last 3 weeks.</li>
      </ul>
    </div>
`,
  },
  {
    id: 7,
    type: "Coming Soon",
    agent_name: "TradeAgent",
    title: "TradeAgent",
    short_description: "Automate your trading ",
    icon: tradeAgentIcon,
    image: tradeAgentImage,
    placeholder: "Ask your question",
    suggestions: [],
    description: `<p>I am TradeAgent. an upcoming auto-trading agent that will be available soon. It can connect to wallets, DEXs, and CEXs, allowing you to trade automatically using Coinfident indicators like SPS (Social Prediction Signal), technical indicators, price movements, etc.</p>
`,
  },
];
