import { Box, useTheme } from "@mui/material";
import AgentCustomLine from "../../../charts/AgentCustomLine";
import AnalyticsTitle from "../../../common/title/AnalyticsTitle";

const AgentCustomChart = ({ type, data }: { type: string; data: any }) => {
  const theme = useTheme();

  const colors = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
  ];

  const chartNameConverter = (name: string) => {
    switch (name) {
      case "num_tweets":
        return "Number of tweets";
      case "num_retweets":
        return "Number of retweets";
      case "avg_sps":
        return "Average SPS";
      case "num_likes":
        return "Number of likes";
      case "tweet_count":
        return "Number of tweets";
      default:
        return name;
    }
  };

  const fixNumber = (numbers: any) => {
    return numbers.map((num: number) => Number(num).toFixed(3));
  };

  const filterNonEmptyKeys = (data: any) => {
    const isEmpty = (value: any) => {
      return (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0) ||
        value === ""
      );
    };

    const result = [];

    for (const key in data) {
      if (key !== "datetime" && !isEmpty(data[key])) {
        result.push(chartNameConverter(key));
      }
    }

    return result;
  };

  const extractDataHandler = (data: any) => {
    const isEmpty = (value: any) => {
      return (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0) ||
        value === ""
      );
    };

    if (type === "SPS Chart") {
      const chart_data = [
        { name: "Price", data: fixNumber(data.price), color: "#1f77b4" },
        { name: "SPS", data: fixNumber(data.sps), color: "#ff7f0e" },
      ].filter((item) => !isEmpty(item.data));

      return chart_data;
    } else if (type === "Price Chart") {
      const chartData = [];
      let colorIndex = 0;

      for (const key in data) {
        if (key !== "datetime" && !isEmpty(data[key])) {
          chartData.push({
            name: key,
            data: fixNumber(data[key]),
            color: colors[colorIndex++ % colors.length],
          });
        }
      }

      return chartData;
    } else {
      const chartData = [];
      let colorIndex = 0;

      for (const key in data) {
        if (key !== "datetime" && !isEmpty(data[key])) {
          chartData.push({
            name: key,
            data: fixNumber(data[key]),
            color: colors[colorIndex++ % colors.length],
          });
        }
      }

      return chartData;
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        mb: 2,
        borderRadius: "8px",
        overflow: "hidden",
        background: (theme: any) => theme.palette.common.popup,
      }}
    >
      <AnalyticsTitle
        title={
          type === "Infs Chart"
            ? `${filterNonEmptyKeys(data).join(", ")} Chart`
            : type.replace(/\bChart\b/gi, "").trim()
        }
        isLarge
      />
      <Box p={2}>
        <AgentCustomLine
          data={extractDataHandler(data)}
          labels={data.datetime}
          name={type.replace(/\bChart\b/gi, "").trim()}
          colors={extractDataHandler(data)?.map((item: any) => item.color)}
        />
      </Box>
    </Box>
  );
};

export default AgentCustomChart;
