export const pricingItems = [
  {
    title: "Basic",
    items: [true, true, false, 5, 5, false],
    price: [5, 10, 15, 20],
  },
  {
    title: "Silver",
    items: [true, true, true, 10, 20, true],
    price: [10, 15, 20, 25],
  },
  {
    title: "Gold",
    items: [true, true, true, 20, 50, true],
    price: [15, 20, 25, 30],
  },
  {
    title: "Platinum",
    items: [true, true, true, 50, 100, true],
    price: [20, 25, 30, 35],
  },
];
