import { useState, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { chartType, itemType } from "./interfaces";
import styles from "./charts.module.scss";

const SocialMediaActivityChart: React.FC<{
  data: [];
  name: string;
}> = ({ data, name }) => {
  const theme = useTheme();
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries | undefined
  >([]);

  function sortItemsByX(items: any) {
    items.sort((a: itemType, b: itemType) => {
      //@ts-ignore
      return new Date(a.x) - new Date(b.x);
    });
    return items;
  }

  const options = useMemo(
    () => ({
      colors: [theme.palette.primary.main],
      chart: {
        parentHeightOffset: 30,
        type: "area",
        width: "100%",
        fontFamily: "Poppins",
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      tooltip: {
        //@ts-ignore
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div class='${styles.linechart_tooltip}' style="background: ${
            //@ts-ignore
            theme.palette.common.solidCard
          }">
              <h6 style="color: ${theme.palette.common.white} ">${
            w.globals.categoryLabels[dataPointIndex]
          }</h6>
              <div>
                <h4 style="color: ${theme.palette.common.white} ">${
            w.globals.seriesNames[seriesIndex]
          }:</h4>
                <h5 style="color: ${theme.palette.common.white} ">${
            series[seriesIndex][dataPointIndex]
          }</h5>
              </div>
              </div>`;
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: { show: false },
      legend: {
        show: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0],
        },
      },
      xaxis: {
        tickAmount: 2,
        tickPlacement: "on",
        labels: {
          show: true,
          rotate: 0,
          offsetY: 0,
          offsetX: 0,
          style: {
            fontSize: "9px",
            //@ts-ignore
            colors: theme.palette.grey[500],
          },
        },
        axisBorder: { show: true },
        axisTicks: { show: true },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "9px",
            //@ts-ignore
            colors: theme.palette.grey[500],
          },
        },
        axisBorder: { show: true },
        axisTicks: { show: true },
      },
    }),
    [theme]
  );

  useEffect(() => {
    setSeries([
      {
        name,
        data:
          data &&
          sortItemsByX(
            [...data]
              ?.sort((a: any, b: any) => {
                //@ts-ignore
                return new Date(a.x) - new Date(b.x);
              })
              .reverse()
              .map((dataItem: chartType) => ({
                x: dataItem.date,
                y: dataItem.tweet_cnt,
              }))
          ),
      },
    ]);
  }, [data]);

  return (
    <Box id="socialMediaActivity">
      <ReactApexChart
        //@ts-ignore
        options={options}
        series={series}
        type="area"
        height="240px"
        width="100%"
      />
    </Box>
  );
};

export default SocialMediaActivityChart;
