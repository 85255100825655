import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DefaultModal from "../modal";
import { LoginAlertModalProps } from "./interfaces";
import loginLogo from "../../../assets/images/icons/login_icon.svg";

const LoginAlertModal: React.FC<LoginAlertModalProps> = ({
  open,
  setOpen,
  isLock,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <DefaultModal
      open={open}
      setOpen={setOpen}
      title="You are not logged in*"
      maxWidth={600}
      isLock={isLock}
      content={
        <Box display="flex" justifyContent="space-between" py={4}>
          <Box
            flexBasis="45%"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Typography>
              Please login to your account for full access
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexBasis="45%"
          >
            <img
              src={loginLogo}
              alt="login-picture"
              style={{ maxWidth: downLg ? "150px" : "auto" }}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default LoginAlertModal;
