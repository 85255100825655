import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialProfileState } from "./interfaces";
import http from "../../services/http";

export const generateCode = createAsyncThunk<any, any, { rejectValue: any }>(
  "generate code",
  async (_, thunkAPI) => {
    try {
      const url = `/users/early-access-token/register/ `;
      const response = await http.post(url);
      return response.data.response.data.token;
    } catch (error: any) {
      if (error.response.status === 400) {
        const url = `/users/early-access-token/get/ `;
        const response = await http.get(url);
        return response.data.response.data.token;
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getCode = createAsyncThunk<any, any, { rejectValue: any }>(
  "get code",
  async (_, thunkAPI) => {
    try {
      const url = `/users/early-access-token/get/ `;
      const response = await http.get(url);
      return response.data.response.data.token;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: InitialProfileState = {
  connectedWallet: null,
  connectionWalletLoading: false,
  pinedCoins: null,
  pinCoinsLoading: false,
  generatedCode: null,
  generatedCodeLoading: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateCode.pending, (state) => {
        state.generatedCodeLoading = true;
      })
      .addCase(generateCode.fulfilled, (state, action) => {
        state.generatedCodeLoading = false;
        state.generatedCode = action.payload;
      })
      .addCase(generateCode.rejected, (state) => {
        state.generatedCodeLoading = false;
      });
  },
});
export default profileSlice.reducer;

export const {} = profileSlice.actions;
