import { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import { getUserProfile } from "../../../utils/userPhoto";
import { AvatarProps } from "../interfaces";
import styles from "./avatar.module.scss";

const Avatar: React.FC<AvatarProps> = ({ screenName, width, height }) => {
  const theme = useTheme();

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Box
      className={styles.influencerImage}
      sx={{ width: `${width}px`, height: `${height}px` }}
    >
      {!imageError ? (
        <img src={getUserProfile(screenName)} onError={handleImageError} />
      ) : (
        <Box>
          <FaUserCircle
            //@ts-ignore
            color={theme.palette.common.whiteToGreen}
            //@ts-ignore
            style={{ background: theme.palette.common.blackToWhite }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Avatar;
