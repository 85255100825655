import { useState } from "react";
import { useNavigate } from "react-router";
import { Fade } from "react-awesome-reveal";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MdOutlineChevronRight } from "react-icons/md";
import { IoMdMore } from "react-icons/io";
import { AssetsListProps, AssetType } from "../../interfaces";
import AssetCashModal from "../assetModal";
import AssetsSkeleton from "../../skeleton/AssetsSkeleton";
import styles from "../../assetsManagement.module.scss";

const AssetsList: React.FC<AssetsListProps> = ({
  wallets,
  cash,
  totalAssetsValue,
  isReadonly,
  assetsManagementState,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<any>(null);

  const removeDuplicates = (array: any) => {
    const uniqueArray = array.filter(
      (item: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.symbol === item.symbol)
    );
    return uniqueArray;
  };

  const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement="left"
      enterTouchDelay={0}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.text.primary,
      background: theme.palette.background.default,
    },
  }));

  const CardComponent = (
    type: string,
    image: string,
    logo: string,
    symbol: string,
    value: string | number,
    asset: AssetType | null
  ) => {
    return (
      <TooltipComponent
        title={type == "ConnectedWallet" ? "Connected Wallet" : "Manual Cash"}
      >
        <Box
          className={styles.asset_card}
          sx={{
            background: (theme: any) =>
              theme.palette.mode === "light"
                ? theme.palette.common.darkPrimary
                : theme.palette.grey[800],
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box className={styles.asset_image}>
              <img
                className={styles.asset_image_image}
                alt={type}
                src={image}
              />
              <img className={styles.asset_image_logo} src={logo} alt="icon" />
            </Box>
          </Box>
          <Typography variant="h6">{symbol}</Typography>

          <Typography
            variant="h6"
            style={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.common.white,
            }}
            align="right"
          >
            ${value}
          </Typography>
          {!isReadonly && (
            <IconButton
              size="small"
              onClick={() => {
                if (type === "ConnectedWallet") {
                  navigate("/asset-tracking/connected-wallets");
                } else {
                  setOpen(true);
                  setSelectedAsset({ symbol, value });
                }
              }}
            >
              {type === "ConnectedWallet" ? (
                <MdOutlineChevronRight
                  color={theme.palette.common.white}
                  size={25}
                />
              ) : (
                <IoMdMore color={theme.palette.common.white} />
              )}
            </IconButton>
          )}
        </Box>
      </TooltipComponent>
    );
  };

  return (
    <>
      <Box
        className={`default-card-style ${styles.assets_list}`}
        sx={{
          background: (theme: any) =>
            theme.palette.mode === "dark"
              ? theme.palette.common.darkPrimary
              : theme.palette.primary.light,
        }}
      >
        <Box className={styles.assets_header} mb={3}>
          <Typography variant="h3" color={theme.palette.text.primary}>
            Assets
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: (theme: any) =>
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.common.darkPrimary,
            }}
          >
            ${totalAssetsValue.toFixed(2)}
          </Typography>
        </Box>

        <Fade triggerOnce>
          {assetsManagementState.walletsLoading && <AssetsSkeleton />}

          {!assetsManagementState.walletsLoading && cash.length > 0
            ? cash.map((cash: any) => (
                <Box key={cash.symbol}>
                  {CardComponent(
                    cash.type,
                    cash.image,
                    cash.logo,
                    cash.symbol,
                    cash.value,
                    cash.asset
                  )}
                </Box>
              ))
            : null}

          {!assetsManagementState.walletsLoading &&
          removeDuplicates(wallets).length > 0
            ? removeDuplicates(wallets).map((wallet: any, idx: number) => (
                <Box key={idx}>
                  {CardComponent(
                    wallet.type,
                    wallet.image,
                    wallet.logo,
                    wallet.symbol,
                    wallet.value,
                    wallet.asset
                  )}
                </Box>
              ))
            : null}
        </Fade>
      </Box>

      {open && (
        <AssetCashModal
          open={open}
          setOpen={setOpen}
          selectedAsset={selectedAsset}
          styles={styles}
        />
      )}
    </>
  );
};

export default AssetsList;
