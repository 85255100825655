import { Box, Typography, useTheme } from "@mui/material";
import { ProgressbarProps } from "../interfaces";
import styles from "./progressbar.module.scss";

const Progressbar: React.FC<ProgressbarProps> = ({
  positive,
  negative,
  neutral,
}) => {
  const theme = useTheme();
  const total = positive + negative + neutral;

  return (
    <Box className={styles.progressbarWrapper}>
      <Box className={styles.progressbar}>
        <Box
          className={styles.bar_1}
          sx={{
            width: `${(positive * 100) / total}%`,
            background: "#259659",
          }}
        >
          {positive > 0 && (
            <Typography variant="caption">
              {((positive * 100) / total).toFixed(0)}%
            </Typography>
          )}
        </Box>
        <Box
          className={styles.bar_2}
          sx={{
            width: `${(negative * 100) / total}%`,
            background: "#FA637A",
          }}
        >
          {negative > 0 && (
            <Typography variant="caption">
              {((negative * 100) / total).toFixed(0)}%
            </Typography>
          )}
        </Box>
        <Box
          className={styles.bar_3}
          sx={{
            width: `${(neutral * 100) / total}%`,
            background: "#CED633",
          }}
        >
          {neutral > 0 && (
            <Typography variant="caption">
              {((neutral * 100) / total).toFixed(0)}%
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={styles.progressbarInfo}>
        <Box>
          <Typography
            variant="caption"
            sx={{ background: "#259659" }}
          ></Typography>
          <Typography
            variant="caption"
            component="h6"
            sx={{
              color:
                //@ts-ignore
                theme.palette.common.whiteToDarkGreen,
            }}
          >
            Positive
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            sx={{ background: "#FA637A" }}
          ></Typography>
          <Typography
            variant="caption"
            component="h6"
            sx={{
              color:
                //@ts-ignore
                theme.palette.common.whiteToDarkGreen,
            }}
          >
            Negative
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            sx={{ background: "#CED633" }}
          ></Typography>
          <Typography
            variant="caption"
            component="h6"
            sx={{
              color:
                //@ts-ignore
                theme.palette.common.whiteToDarkGreen,
            }}
          >
            Neutral
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Progressbar;
