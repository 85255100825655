import moment from "moment";
import { Box, Typography, useTheme } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import NewsRssCard from "../newsRssCard";
import Loading from "../../common/loading";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import {
  fetchRssNewsList,
  setNewsPageRssResult,
} from "../../../features/news/newsSlice";
import { RssItemType, RssNewsProps } from "../interfaces";

const RssNews: React.FC<RssNewsProps> = ({ setRssPage, newsList }) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const fetchNextPage = () => {
    const requestData = {
      page: Number(
        newsList.rssDisplayResult.next.split("page=")[1].split("&")[0]
      ),
      search: newsList.newsPageFilter?.rssFilters
        ? newsList.newsPageFilter.rssFilters.search
        : "",
    };

    setRssPage(
      Number(newsList.rssDisplayResult.next.split("page=")[1].split("&")[0])
    );
    dispatch(fetchRssNewsList(requestData)).then((response) => {
      dispatch(
        setNewsPageRssResult({
          count: response.payload.count,
          next: response.payload.next,
          results: [...newsList.rssDisplayResult.results].concat(
            response.payload.results
          ),
        })
      );
    });
  };

  return (
    <Box mt={3}>
      {!newsList.rssDisplayResult ? (
        <Loading />
      ) : (
        newsList.rssDisplayResult &&
        (newsList.rssDisplayResult.count > 0 ? (
          <InfiniteScroll
            dataLength={
              //@ts-ignore
              newsList.rssDisplayResult.results.length
            }
            next={fetchNextPage}
            hasMore={newsList.rssDisplayResult.next ? true : false}
            loader={<Loading />}
          >
            {newsList.rssDisplayResult.results.map((newsItem: RssItemType) => (
              <NewsRssCard
                key={`${newsItem.title}-${newsItem.published}`}
                url={newsItem.url ?? ""}
                thumbnail_url={newsItem.thumbnail_url}
                title={newsItem.title}
                base_link={newsItem.base_link}
                published={moment
                  .parseZone(newsItem.published)
                  .format("YYYY-MM-DD")}
                tags={newsItem.tags}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <Box
            className={`default-card-style `}
            sx={{ background: theme.palette.background.paper }}
          >
            <Typography align="center" py={3}>
              No data
            </Typography>
          </Box>
        ))
      )}
    </Box>
  );
};

export default RssNews;
