import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { SignalState } from "./interfaces";

export const fetchInfluencerSignal = createAsyncThunk<
  { data: null },
  { coin: number; page: number; category: string },
  { rejectValue: any }
>("influencer signal", async (requestProps, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-signal/?page=${requestProps.page}${
        requestProps.category ? `&category=${requestProps.category}` : ""
      }`,
      {
        coin_id: requestProps.coin,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCryptoPageInfluencerSignal = createAsyncThunk<
  { data: null },
  { coin: number; page: number; category: string },
  { rejectValue: any }
>("crypto page influencer signal", async (requestProps, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-signal/?page=${requestProps.page}${
        requestProps.category ? `&category=${requestProps.category}` : ""
      }`,
      {
        coin_id: requestProps.coin,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinSignalCount = createAsyncThunk<
  { data: null },
  string | undefined,
  { rejectValue: any }
>("crypto signal count", async (coinId, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/get-coin-sig-count/?coin_id=${coinId}`
    );
    const data = response.data;

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: SignalState = {
  newsPageData: null,
  influencerSignal: null,
  cyptoPageInfluencerSignal: null,
  cryptoSignalCount: null,
  influencerSignalLoading: false,
  cyptoPageInfluencerSignalLoading: false,
  cryptoSignalCountLoading: false,
  error: null,
};

export const signalSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    setNewsPageData: (state, action) => {
      state.newsPageData = {
        count: action.payload.count,
        next: action.payload.next,
        results: action.payload.results,
        tab: action.payload.tab,
        coin: action.payload.coin,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerSignal.pending, (state) => {
        state.influencerSignalLoading = true;
        state.influencerSignal = null;
        state.error = null;
      })
      .addCase(fetchInfluencerSignal.fulfilled, (state, action) => {
        state.influencerSignalLoading = false;
        state.influencerSignal = action.payload;
      })
      .addCase(fetchInfluencerSignal.rejected, (state, action) => {
        state.influencerSignalLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchCryptoPageInfluencerSignal.pending, (state) => {
        state.cyptoPageInfluencerSignalLoading = true;
        state.error = null;
      })
      .addCase(fetchCryptoPageInfluencerSignal.fulfilled, (state, action) => {
        state.cyptoPageInfluencerSignalLoading = false;
        state.cyptoPageInfluencerSignal = action.payload;
      })
      .addCase(fetchCryptoPageInfluencerSignal.rejected, (state, action) => {
        state.cyptoPageInfluencerSignalLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchCoinSignalCount.pending, (state) => {
        state.cryptoSignalCountLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinSignalCount.fulfilled, (state, action) => {
        state.cryptoSignalCountLoading = false;
        state.cryptoSignalCount = action.payload;
      })
      .addCase(fetchCoinSignalCount.rejected, (state, action) => {
        state.cryptoSignalCountLoading = true;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default signalSlice.reducer;

export const { setNewsPageData } = signalSlice.actions;
