import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Container, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Loading from "../../components/common/loading";
import AllInfluencers from "../../components/influencers/influencersList";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchInfluencersList,
  fetchPinnedInfluencers,
  fetchSortInfluencers,
  fetchSortInfluencersSelectedCoin,
  fetchTopInfluencersCoin,
} from "../../features/influencers/influencersSlice";
import { scrollToTop } from "../../utils/functions";
import useAuth from "../../utils/useAuth";
import styles from "./influencers.module.scss";

const Influencers = () => {
  const theme = useTheme();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useAppDispatch();
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const account: any = useSelector((state: RootState) => state.account);
  const influencersList: any = useSelector(
    (state: RootState) => state.influencers
  );

  const [page, setPage] = useState<number>(
    influencersList?.influencersList && influencersList.influencersList.previous
      ? Number(
          influencersList.influencersList.previous?.match(/page=(\d+)/)
            ? influencersList.influencersList.previous?.match(/page=(\d+)/)[1]
            : 1
        ) + 1
      : 1
  );
  const [selectedFilter, setSelectedFilter] = useState(
    influencersList.influencersFilter
      ? influencersList.influencersFilter
      : "-followers_count"
  );
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedCoin, setSelectedCoin] = useState<number>(3);
  const [timePeriod, setTimePeriod] = useState<1 | 3 | 7 | 30>(7);

  useEffect(() => {
    if (account.isAuthenticated) {
      if (coinsList.availableCoins && !influencersList.pinnedInfluencers) {
        dispatch(fetchPinnedInfluencers());
      }
    }
  }, [
    coinsList.availableCoins,
    influencersList.pinnedInfluencers,
    account.isAuthenticated,
  ]);

  useEffect(() => {
    if (isAuthValid)
      if (coinsList.availableCoins && !influencersList.influencersList) {
        fetchInfluencersData(1);
      }
  }, [coinsList.availableCoins, influencersList.influencersList, isAuthValid]);

  useEffect(() => {
    if (isAuthValid) {
      dispatch(
        fetchSortInfluencers({ id: 0, type: "relevant_tweets", timePeriod })
      );
      dispatch(fetchSortInfluencers({ id: 1, type: "buy_signal", timePeriod }));
      dispatch(
        fetchSortInfluencers({ id: 2, type: "sell_signal", timePeriod })
      );
      dispatch(
        fetchSortInfluencers({
          id: 3,
          type: "positive_sentiment",
          timePeriod,
        })
      );
      dispatch(
        fetchSortInfluencers({
          id: 4,
          type: "negative_sentiment",
          timePeriod,
        })
      );
    }
  }, [isAuthValid, timePeriod]);

  useEffect(() => {
    if (isAuthValid) {
      dispatch(
        fetchSortInfluencersSelectedCoin({
          id: 0,
          type: "relevant_tweets",
          coin: selectedCoin,
          timePeriod,
        })
      );
      dispatch(
        fetchSortInfluencersSelectedCoin({
          id: 1,
          type: "buy_signal",
          coin: selectedCoin,
          timePeriod,
        })
      );
      dispatch(
        fetchSortInfluencersSelectedCoin({
          id: 2,
          type: "sell_signal",
          coin: selectedCoin,
          timePeriod,
        })
      );
    }
  }, [isAuthValid, timePeriod, selectedCoin]);

  useEffect(() => {
    dispatch(fetchTopInfluencersCoin(selectedCoin));
  }, [isAuthValid, selectedCoin]);

  const fetchInfluencersData = (pageNumber: number) => {
    const requestData = {
      ordering: selectedFilter,
      page: pageNumber,
    };
    if (coinsList.availableCoins) {
      scrollToTop();
      dispatch(fetchInfluencersList(requestData));
    }
  };

  const filterInfluencers = () => {
    setPage(1);
    fetchInfluencersData(1);
  };

  const filterHandler = () => {
    setIsFilterOpen(!isFilterOpen);
    scrollToTop();
  };

  const influencersPaginationHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    fetchInfluencersData(value);
  };

  return (
    <DashboardLayout title="Coinfident | Influencers">
      <Container maxWidth="xl" sx={{ padding: 0 }}>
        {influencersList.influencersListLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "1rem auto",
            }}
          >
            <Loading />
          </Box>
        ) : (
          <AllInfluencers
            styles={styles}
            influencersList={influencersList}
            setSelectedCoin={setSelectedCoin}
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filterInfluencers={filterInfluencers}
            filterHandler={filterHandler}
            page={page}
            influencersPaginationHandler={influencersPaginationHandler}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default Influencers;
