import { createSlice } from "@reduxjs/toolkit";
import { AssetsResponseState } from "./interfaces";

const initialState: AssetsResponseState = {
  openDrawer: false,
  hasNetworthStatistics: false,
  assetsData: null,
  error: null,
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    toggleAssetsDrawer: (state) => {
      state.openDrawer = !state.openDrawer;
    },
    openAssetsDrawer: (state) => {
      state.openDrawer = true;
    },
    closeAssetsDrawer: (state) => {
      state.openDrawer = false;
    },
    fetchConnectedAssetsData: (state, action) => {
      state.assetsData = action.payload;
    },
    hasNetworthStatisticsSet: (state) => {
      state.hasNetworthStatistics = true;
    },
  },
  extraReducers: (builder) => {},
});
export default assetsSlice.reducer;

export const {
  toggleAssetsDrawer,
  openAssetsDrawer,
  closeAssetsDrawer,
  fetchConnectedAssetsData,
  hasNetworthStatisticsSet,
} = assetsSlice.actions;
