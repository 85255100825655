//@ts-nocheck
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";
import { AgentCustomLineProps } from "./interfaces";

const AgentCustomLine: React.FC<AgentCustomLineProps> = ({ data, labels }) => {
  const theme = useTheme();
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries | undefined
  >([]);
  const [originalData, setOriginalData] = useState<any[]>([]);

  function convertTime(datetime: string) {
    const [date, time] = datetime.split(" ");
    return date;
  }

  console.log(data?.map((item) => item.name).includes("avg_sps"));

  const options = {
    chart: {
      type: "line",
      fontWeight: 100,
      fontFamily: "Poppins",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: data?.map((item) => item.color),
    grid: { show: false },
    dataLabels: { enabled: false },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontWeight: 100,
      markers: {
        width: 4,
        height: 4,
      },
      labels: {
        colors: theme.palette.text.primary,
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    yaxis: {
      title: {
        text: data?.map((item) => item.name).includes("avg_sps")
          ? data.length > 1
            ? "Count"
            : "Change Percent"
          : "Count",
        offsetX: 5,
        style: {
          fontWeight: 100,
          fontSize: "11px",
          color: theme.palette.text.primary,
        },
      },
      axisBorder: { show: true },
      labels: { show: false },
      axisTicks: { show: false },
      min: 0,
    },
    xaxis: {
      tickAmount: 2,
      tickPlacement: "on",
      categories: labels?.map((label: string) => convertTime(label)),
      labels: {
        rotate: 0,
        style: {
          fontWeight: 100,
          fontSize: "10px",
          colors: theme.palette.text.primary,
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: { show: false },
    },
    tooltip: {
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex }) {
          const seriesName = originalData[seriesIndex]?.name;
          const originalValue =
            originalData[seriesIndex]?.data.original[dataPointIndex];
          if (seriesName === "avg_sps") {
            return `${Number(originalValue).toFixed(2)}%`;
          }
          return Number(originalValue).toFixed(0);
        },
      },
    },
  };

  const normalizeData = (data) => {
    const seriesMaxValues = data.map((series) => Math.max(...series.data));

    return data.map((series, index) => ({
      name: series.name,
      data: {
        normalized: series.data.map(
          (value) => (value / seriesMaxValues[index]) * 100
        ),
        original: series.data,
      },
    }));
  };

  useEffect(() => {
    const dataWithNormalization = normalizeData(data);

    setSeries(
      dataWithNormalization.map((item) => ({
        name: item.name,
        data: item.data.normalized,
      }))
    );

    setOriginalData(dataWithNormalization);
  }, [data]);

  return (
    <ReactApexChart
      //@ts-ignore
      options={options}
      series={series}
      type="line"
      height="190px"
    />
  );
};

export default AgentCustomLine;
