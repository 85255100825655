import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../utils/functions";

export const RoutesWrapper: React.FC<any> = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    scrollToTop();
  }, [location.pathname]);
  return children;
};
