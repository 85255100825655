import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { agentsList } from "../../../data/agentsList";

const AgentResponsiveList = ({
  selectedAgent,
  setSelectedAgent,
}: {
  selectedAgent: any;
  setSelectedAgent: any;
}) => {
  const theme = useTheme();

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const AgentTypography = ({ title }: { title: string }) => {
    return (
      <Typography
        onClick={() => setSelectedAgent(title)}
        sx={{
          fontWeight: 100,
          fontSize: ".78rem",
          borderRadius: "6px",
          whiteSpace: "nowrap",
          padding: ".4rem .6rem",
          color:
            selectedAgent === title
              ? theme.palette.common.white
              : theme.palette.text.primary,
          border: (theme: any) => `1px solid ${theme.palette.common.mute}`,
          background: (theme: any) =>
            selectedAgent === title
              ? theme.palette.common.darkPrimary
              : "transparent",
        }}
      >
        {title}
      </Typography>
    );
  };

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.text.primary,
          mb: 1,
          fontSize: "1.1rem",
        }}
      >
        Agents:
      </Typography>
      <Box
        className={`customScrollbar ${scrollbarClassName}`}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          overflowX: "auto",
          gap: 0.8,
          cursor: "pointer",
        }}
      >
        <AgentTypography title={selectedAgent} />
        {agentsList
          .filter((item: any) => item.title !== selectedAgent)
          .map((agentItem: any) => (
            <React.Fragment key={agentItem.id}>
              <AgentTypography title={agentItem.title} />
            </React.Fragment>
          ))}
      </Box>
    </Box>
  );
};

export default AgentResponsiveList;
