import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Guage from "../../guage";
import SignalTable from "./signalTable";
import Loading from "../../common/loading";
import { CryptoTechnicalSignalsProps } from "../interfaces";
import styles from "../crypto.module.scss";

const CryptoTechnicalSignals: React.FC<CryptoTechnicalSignalsProps> = ({
  data,
}) => {
  const theme = useTheme();

  const [cryptoSignals, setCryptoSignals] = useState<any>(null);

  let oscillators_data = data?.oscillators;
  let moving_averages_data = data?.moving_averages;

  const calculateGuageValue = (guageData: any) => {
    if (guageData) {
      let sell = 0;
      let buy = 0;
      let neutral = 0;

      for (let i = 0; i < guageData.length; i++) {
        const action = guageData[i].action;
        if (action === "Sell") {
          sell++;
        } else if (action === "Buy") {
          buy++;
        } else if (action === "Neutral") {
          neutral++;
        }
      }
      const total = ((buy / (sell + buy + neutral)) * 100).toFixed(0);

      return {
        sell,
        buy,
        neutral,
        total,
      };
    }
  };

  useEffect(() => {
    if (data) {
      setCryptoSignals([
        {
          title: "Oscillators",
          value: calculateGuageValue(oscillators_data)?.total,
          sell: calculateGuageValue(oscillators_data)?.sell,
          neutral: calculateGuageValue(oscillators_data)?.neutral,
          buy: calculateGuageValue(oscillators_data)?.buy,
        },
        {
          title: "Summary",
          value:
            Number(calculateGuageValue(oscillators_data)?.total ?? 0) +
            Number(calculateGuageValue(moving_averages_data)?.total ?? 0) / 2,
          sell:
            Number(calculateGuageValue(oscillators_data)?.sell ?? 0) +
            Number(calculateGuageValue(moving_averages_data)?.sell ?? 0),
          neutral:
            (calculateGuageValue(oscillators_data)?.neutral ?? 0) +
            (calculateGuageValue(moving_averages_data)?.neutral ?? 0),
          buy:
            (calculateGuageValue(oscillators_data)?.buy ?? 0) +
            (calculateGuageValue(moving_averages_data)?.buy ?? 0),
        },
        {
          title: "Moving Averages",
          value: calculateGuageValue(moving_averages_data)?.total,
          sell: calculateGuageValue(moving_averages_data)?.sell,
          neutral: calculateGuageValue(moving_averages_data)?.neutral,
          buy: calculateGuageValue(moving_averages_data)?.buy,
        },
      ]);
    }
  }, [data]);

  return (
    <Box>
      <Box className={styles.signals_container}>
        {cryptoSignals?.map((signal: any) => (
          <Box
            className={styles.signal_container}
            key={signal.title}
            sx={{
              marginTop: signal.title === "Summary" ? "0rem" : "3rem",
            }}
          >
            <Typography
              variant={signal.title === "Summary" ? "body1" : "body2"}
              component="h4"
              align="center"
              mt={1}
              sx={{ color: theme.palette.text.primary }}
            >
              {signal.title}
            </Typography>
            <Guage
              name="technical"
              value={signal.value}
              type={
                signal.title === "Summary"
                  ? "summaryTechnicalSignal"
                  : "technicalSignal"
              }
            />
            <Box className={styles.guage_footer}>
              <Box>
                <Typography
                  variant="body1"
                  component="h5"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Sell
                </Typography>
                <Typography
                  variant="body2"
                  component="h6"
                  align="center"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {signal.sell}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="h5"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Neutral
                </Typography>
                <Typography
                  variant="body2"
                  component="h6"
                  align="center"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {signal.neutral}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="h5"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Buy
                </Typography>
                <Typography
                  variant="body2"
                  component="h6"
                  align="center"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {signal.buy}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box>
        <Box className={styles.signals_table}>
          <Typography
            variant="body1"
            component="h5"
            sx={{
              color: theme.palette.text.primary,
              borderColor: `${theme.palette.grey[700]} !important`,
            }}
          >
            Moving Avarages
          </Typography>
          {data == undefined ? (
            <Loading />
          ) : (
            <SignalTable data={data.moving_averages} isDollarSign />
          )}
        </Box>
        <Box className={styles.signals_table}>
          <Typography
            variant="body1"
            component="h5"
            sx={{
              color: theme.palette.text.primary,
              borderColor: `${theme.palette.grey[700]} !important`,
            }}
          >
            Oscillators
          </Typography>
          {data == undefined ? (
            <Loading />
          ) : (
            <SignalTable data={data.oscillators} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CryptoTechnicalSignals;
