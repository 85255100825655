import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import styles from "./guage.module.scss";

const FullGuage: React.FC<{
  value: number;
  isEmpty?: boolean;
}> = ({ value, isEmpty }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box className={styles.fullGaugeWrapper}>
      <Typography
        className={styles.fullGaugeValue}
        sx={{
          color: theme.palette.text.primary,
          bottom: downLg ? "-60px !important" : "10px",
        }}
      >
        {isEmpty ? "N/A" : `${value}%`}
      </Typography>
      <GaugeComponent
        type="radial"
        value={value ?? 0}
        style={{
          width: downLg ? 200 : 300,
          height: downLg ? 100 : 200,
        }}
        arc={{
          colorArray: isEmpty
            ? [theme.palette.grey["700"]]
            : ["#FA637A", "#CDD931", "#259659"],
          padding: 0,
          width: 0.2,
          gradient: true,
        }}
        pointer={{
          elastic: true,
          length: isEmpty ? 0 : 0.65,
          width: isEmpty ? 0 : 10,
          color: theme.palette.text.primary,
        }}
        labels={{
          valueLabel: {
            hide: true,
          },
          tickLabels: {
            hideMinMax: true,
          },
        }}
      />
    </Box>
  );
};

export default FullGuage;
