import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Select from "react-select";
import Loading from "../../common/loading";
import { CryptoExchangesProps } from "../interfaces";
import { formatNumber, formatPrice } from "../../../utils/functions";
import styles from "../crypto.module.scss";

const CryptoExchanges: React.FC<CryptoExchangesProps> = ({ data }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [selectOptions, setSelectOptions] = useState([
    { value: 10, label: 10 },
  ]);

  const tableHeaders = [
    "Exchange",
    "Pair",
    "Price",
    "Volume (24Hr)",
    "Volume (%)",
  ];

  useEffect(() => {
    if (data.coinMarkets?.data) {
      const newOptions = [
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
      ].filter((option) => option.value <= data.coinMarkets?.data.length);
      setSelectOptions(newOptions);
      if (!newOptions.find((option) => option.value === rows)) {
        setRows(newOptions[0]?.value);
      }
    }
  }, [data.coinMarkets?.data, rows]);

  const handleChange = (event: any, value: number) => {
    setPage(value);
  };

  const handleChangeRows = (selectedOption: any) => {
    const newRows = selectedOption.value;
    setRows(newRows);
    setPage(1); // Reset page to 1 when changing the number of rows
  };

  const itemsPerPage = rows ? rows : 10; // Dynamic items per page based on selected rows
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data.coinMarkets?.data.slice(startIndex, endIndex);

  const getImagePath = (exchangeId: string) => {
    try {
      return require(`../../../assets/images/exchanges/${exchangeId}.png`);
    } catch (error) {
      return require(`../../../assets/images/exchanges/exchange-default.png`);
    }
  };

  return (
    <Grid container className={styles.signals_table}>
      {data.coinMarketsLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Loading />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            marginTop: "1rem",
            borderRadius: "8px",
            background: (theme: any) => theme.palette.common.solidCard,
          }}
        >
          <TableContainer
            className="customScrollbar"
            sx={{
              padding: "1rem",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((headerItem: string, idx: number) => (
                    <TableCell
                      align={idx == 0 ? "left" : "center"}
                      key={headerItem}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          width: downLg ? "130px !important" : "auto",
                          fontWeight: 500,
                          color:
                            theme.palette.mode === "light"
                              ? theme.palette.secondary.main
                              : //@ts-ignore
                                theme.palette.common.mute,
                        }}
                      >
                        {headerItem}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems?.map((exchange: any, idx: number) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={getImagePath(exchange.exchangeId)}
                        style={{
                          width: "22px",
                          height: "22px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          whiteSpace: "nowrap !important",
                          color: theme.palette.common.white,
                        }}
                      >
                        {exchange.exchangeId}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          color: theme.palette.common.white,
                        }}
                      >
                        {data.coin?.data.symbol}/{exchange.quoteSymbol}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          color: theme.palette.common.white,
                        }}
                      >
                        ${formatPrice(Number(exchange.priceUsd))}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          color: theme.palette.common.white,
                        }}
                      >
                        {formatNumber(Number(exchange.volumeUsd24Hr))}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          color: theme.palette.common.white,
                        }}
                      >
                        {Number(exchange.volumePercent).toFixed(2)}%
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {data.coinMarkets?.data.length > rows && (
            <Box
              sx={{
                py: 1.5,
                display: "flex",
                flexDirection: downLg ? "column" : "row",
                justifyContent: "center",
                position: "relative",
                borderTop: `1px solid ${theme.palette.grey[700]}`,
              }}
            >
              <Pagination
                count={Math.ceil(data.coinMarkets?.data.length / rows)}
                page={page}
                onChange={handleChange}
                size="small"
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  "> ul": {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  },
                }}
              />
              {data.coinMarkets?.data.length > 10 && (
                <Box
                  sx={{
                    position: downLg ? "relative" : "absolute",
                    marginTop: downLg ? "10px" : 0,
                    right: "2%",
                    bottom: "20%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1000,
                  }}
                >
                  <Typography variant="caption" sx={{ marginRight: "10px" }}>
                    Rows:
                  </Typography>
                  <Select
                    defaultValue={{ value: rows, label: rows }}
                    options={selectOptions}
                    onChange={handleChangeRows}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: "12px",
                        padding: 0,
                        minHeight: "none",
                        background: "transparent",
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        padding: 0,
                        minWidth: "30px",
                        fontWeight: 500,
                        color: theme.palette.text.primary,
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        fontWeight: 500,
                        color: theme.palette.text.primary,
                      }),
                      indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        display: "none",
                      }),
                      option: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: "13px",
                      }),
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default CryptoExchanges;
