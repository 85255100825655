import { Box, Typography, useTheme } from "@mui/material";
import FullGuage from "../../components/coinfidentWidgets/signals/guages/fullGuage";
import HalfGuage from "../../components/coinfidentWidgets/signals/guages/halfGuage";
import styles from "./signalsWidget.module.scss";

const CoinfidentSignalsWidget = () => {
  const theme = useTheme();

  return (
    <Box className="default-card-style">
      <Typography
        align="center"
        variant="h5"
        mb={8}
        color={theme.palette.text.primary}
      >
        Signals
      </Typography>

      <Box className={styles.signals_titles}>
        <Typography variant="body1" mb={-3} color={theme.palette.text.primary}>
          On-chain
        </Typography>
        <Typography variant="h6" color={theme.palette.text.primary}>
          SPS
        </Typography>
        <Typography variant="body1" mb={-3} color={theme.palette.text.primary}>
          Technical
        </Typography>
      </Box>

      <Box className={styles.signals_wrapper}>
        <Box className={styles.left_guage}>
          <HalfGuage value={39} name="on-chain" />
        </Box>
        <Box className={styles.right_guage}>
          <HalfGuage value={39} name="technical" />
        </Box>
        <Box className={styles.center_guage}>
          <FullGuage value={39} />
        </Box>
      </Box>

      <Box className={styles.signals_footer}>
        <Typography variant="caption" color={theme.palette.text.primary}>
          Not Buy
        </Typography>
        <Typography variant="caption" color={theme.palette.text.primary}>
          Buy
        </Typography>
      </Box>
    </Box>
  );
};

export default CoinfidentSignalsWidget;
